<template>
  <head-panel>
    <template v-slot:body>
      Менеджери
      <a @click="managerAddModalShow=true" class="btn btn-primary btn-sm btn-create ms-2">Додати менеджера</a>
    </template>
  </head-panel>

  <div class="wrapper">

    <div class="filter">
      <div class="item">
        <SearchFilter
            label="Швидкий пошук"
            name="search"
            path="manager"
        />
      </div>
      <div class="item">

      </div>
    </div>

    <table class="table">
      <thead>
      <tr>
        <th scope="col">Email</th>
        <th scope="col">Власник</th>
        <th scope="col">Статус</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in items" :key="item">
        <td>{{ item.email }}</td>
        <td>{{ item.owner ? 'Так' : '' }}</td>
        <td>{{ item.status }}</td>
        <td class="actions">
          <router-link :to="'/manager/'+item.uuid+'/permission'" v-if="!item.owner">Дозволи</router-link>
          <a @click="remove(item)" v-if="!item.owner">Видалити</a>
        </td>
      </tr>
      </tbody>
    </table>

    <Pagination
        :current-page="pagination.page"
        :total-pages="pagination.total"/>

  </div>
  <manager-add-modal @close="closeManagerAddModal" v-if="managerAddModalShow"/>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import Pagination from "../../components/Pagination";
import SearchFilter from "../../components/SearchFilter";
import ManagerAddModal from "./ManagerAddModal";
import ConfirmDialogue from "@/components/ConfirmDialog.vue";

export default {
  name: "Index",
  components: {
    HeadPanel, Pagination, ConfirmDialogue, SearchFilter, ManagerAddModal
  },
  created() {
    this.fetch();
  },
  watch: {
    $route(n, o) {
      if (n.path === o.path)
        this.fetch();
    }
  },
  data() {
    return {
      managerAddModalShow: false,
      items: [],
      pagination: {
        page: 0,
        total: 0
      },
    }
  },
  methods: {
    fetch: function () {
      Http
          .get(process.env.VUE_APP_CRM_SERVICE + this.$route.fullPath)
          .then((res) => {
            this.items = res.content;
            this.pagination.page = res.origin.headers.get("X-Current-Page") * 1;
            this.pagination.total = res.origin.headers.get("X-Page-Count") * 1;
          });
    },
    remove: function (item) {
      this.$refs.confirmDialogue.show({
        title: 'Видалення',
        message: 'Ви дійсно хочете видалити менеджера?',
        okButton: 'Так',
        cancelButton: 'Ні',
      }).then((ok) => {
        if (ok)
          Http
              .del(process.env.VUE_APP_CRM_SERVICE + "/manager/" + item.uuid)
              .then(() => this.fetch());
      })
    },
    closeManagerAddModal: function () {
      this.managerAddModalShow = false;
      this.fetch();
    }
  }
};
</script>

<style scoped>


</style>