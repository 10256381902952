<template>

  <div class="card">
    <div class="card-body">
      <h5 class="card-title">{{ item.name }}</h5>
      <p class="card-text">{{ item.description }}</p>

      <div class="form-check form-switch">
        <input class="form-check-input" :checked="item.status === 'ACTIVE'" type="checkbox" id="importStatus"
               @change="statusSwitch">
        <label class="form-check-label" for="importStatus">Активність</label>
      </div>

      <div class="mb-2" v-if="project && project.mosquito">
        <label class="mt-2">Subject</label>
        <input type="text" disabled class="form-control form-control-sm" :value="project.mosquito.project">
        <label class="mt-2">Mosquito</label>
        <input type="text" disabled class="form-control form-control-sm" :value="project.mosquito.project">
        <label class="mt-2">Channel</label>
        <input type="text" disabled class="form-control form-control-sm" :value="project.mosquito.channel">
        <label class="mt-2">Message</label>
        <input type="text" disabled class="form-control form-control-sm" :value="project.mosquito.reviewNewMessage">
      </div>

    </div>
  </div>


</template>

<script>
import Http from "../../lib/Http";

export default {
  name: "Integrations",
  components: {},
  created() {
    this.fetch();
  },
  computed: {
    item() {
      const market = Http.session.market;
      return {
        uuid: market.uuid,
        status: this.status,
        name: 'Review',
        description: 'Дозволяє користувачам залишати відгуки про товари',
      };
    },
  },
  data() {
    return {
      status: 'DISABLED',
      project: null,
    }
  },
  methods: {
    fetch: function () {
      const market = Http.session.market;
      const alias = "market:" + market.uuid + ":project";
      Http
          .get(process.env.VUE_APP_REVIEW_SERVICE + "/integration/" + alias)
          .then(res => {
            this.project = res.content;
            this.status = res.content.status === 'ACTIVE' ? 'ACTIVE' : 'DISABLED';
          });
    },

    statusSwitch: async function () {
      const market = Http.session.market;
      const alias = "market:" + market.uuid + ":project";
      Http
          .get(process.env.VUE_APP_REVIEW_SERVICE + "/integration/" + alias)
          .then(res => {
            this.project = res.content;
            const action = res.content.status === 'ACTIVE' ? 'disable' : 'enable';
            return Http
                .put(process.env.VUE_APP_REVIEW_SERVICE + '/integration/' + res.content.uuid + '/' + action)
                .then(e => {
                  if (e.content.status === 'ACTIVE')
                    this.subject(e.content);
                  return e;
                })
                .then(e => {
                  if (e.content.status === 'ACTIVE')
                    this.updateMosquito(e.content);
                  return e;
                })
                .then(res => this.status = res.content.status);
          })
          .catch(() => {
            Http
                .post(process.env.VUE_APP_REVIEW_SERVICE + '/realm', {
                  "name": "Marketplace " + market.name,
                  "alias": alias,
                })
                .then(e => {
                  this.subject(e.content);
                  return e;
                })
                .then(e => {
                  this.updateMosquito(e.content);
                  return e;
                })
                .then(res => this.status = res.content.status);
          });
    },

    subject: function (realm) {
      const market = Http.session.market;
      const alias = "market:" + market.uuid + ":product:subject";
      return Http
          .get(process.env.VUE_APP_REVIEW_SERVICE + "/integration/subject/" + alias)
          .then(e => e.content)
          .catch(() => {
            return Http
                .post(process.env.VUE_APP_REVIEW_SERVICE + '/subject?realm=' + realm.uuid, {
                  "name": market.name + " product",
                  "alias": alias,
                })
                .then(e => e.content)
          })
    },

    updateMosquito: async function (realm) {
      const market = Http.session.market;
      const alias = "market:" + market.uuid + ":project";

      const mosquito = await this._project();

      return Http
          .put(process.env.VUE_APP_REVIEW_SERVICE + '/realm/' + realm.uuid, {
            "name": "Marketplace " + market.name,
            "alias": alias,
            "mosquito": {
              "project": mosquito.uuid,
              "channel": await this._channel(realm, mosquito),
              "reviewNewMessage": await this._reviewNewMessage(realm, mosquito),
            }
          })
          .then(e => this.project = e.content);
    },

    _project: function () {
      const market = Http.session.market;
      const alias = "market:" + market.uuid + ":project";

      return Http
          .get(process.env.VUE_APP_MOSQUITO_SERVICE + "/integration/" + alias)
          .then(res => res.content)
          .catch(() => {
            return Http
                .post(process.env.VUE_APP_MOSQUITO_SERVICE + '/project', {
                  "name": "Marketplace " + market.name,
                  "alias": alias,
                })
                .then(res => res.content);
          });
    },

    _channel: function (helpdesk, mosquito) {
      const market = Http.session.market;
      const alias = "market:" + market.uuid + ":review:channel";

      return Http
          .get(process.env.VUE_APP_MOSQUITO_SERVICE + "/integration/channel/" + alias)
          .then(res => res.content.uuid)
          .catch(() => {
            return Http
                .post(process.env.VUE_APP_MOSQUITO_SERVICE + '/integration/channel/' + mosquito.uuid, {
                  "name": "Marketplace " + market.name + " reviews",
                  "alias": alias,
                })
                .then(res => res.content.uuid);
          });
    },

    _reviewNewMessage: function (helpdesk, mosquito) {
      const market = Http.session.market;
      const alias = "market:" + market.uuid + ":review:review:new:message";

      return Http
          .get(process.env.VUE_APP_MOSQUITO_SERVICE + "/integration/message/" + alias)
          .then(res => res.content.uuid)
          .catch(() => {
            return Http
                .post(process.env.VUE_APP_MOSQUITO_SERVICE + '/integration/message/' + mosquito.uuid, {
                  "name": "Marketplace " + market.name + " new review",
                  "type": "TELEGRAM",
                  "alias": alias,
                  "text": "Є наявні відгуки які потребують модерації\nhttps://marketplace.creelayer.com/#/review/manage"
                })
                .then(res => res.content.uuid);
          });
    },

  }
};
</script>

<style scoped>

</style>