import Sidebar from '../components/Sidebar.vue'
import Index from "../views/banner/Index"
import Edit from "../views/banner/Edit"

export default [
    {
        path: "/banner",
        name: "Banner",
        components: {
            default: Index,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/banner/create",
        name: "BannerCreate",
        components: {
            default: Edit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/banner/:id",
        name: "BannerEdit",
        components: {
            default: Edit,
            LeftSidebar: Sidebar
        }
    }
];