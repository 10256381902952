<template>
  <Alert ref="alert"/>

  <a class="btn btn-primary btn-sm add-short-cut ms-3 mb-2" @click="edit(null)">Додати</a>
  <table class="table short-cuts" v-if="category && category.shortCuts">
    <thead>
    <tr>
      <th scope="col">Словник</th>
      <th scope="col">Назва</th>
      <th scope="col">Вага</th>
      <th scope="col">Тайтл</th>
      <th scope="col"></th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="filter in category.filters" :key="filter">
      <td>{{ filter.vocabulary }}</td>
      <td>{{ $filters.locale(filter.locales) }}</td>
      <td>{{ filter.weight }}</td>
      <td>{{ filter.isTitleUse }}</td>
      <td class="actions">
        <a class="" @click="edit(filter)">Редагувати</a>
        <a @click="remove(filter)">Видалити</a>
      </td>
    </tr>
    </tbody>
  </table>

  <filter-modal :category="category" v-model="filterModel" @close="close" @submit.prevent="submit"
                v-if="filterFormShow"/>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import ConfirmDialogue from '../../components/ConfirmDialog.vue'
import FilterModal from "./FilterModal";
import Alert from "../../components/Alert.vue";

export default {
  name: "Edit",
  components: {
    ConfirmDialogue, FilterModal, Alert
  },
  created() {
    if (this.$route.params.id)
      this.fetch(this.$route.params.id);
  },
  data() {
    return {
      category: null,
      filterModel: null,
      filterFormShow: false
    }
  },
  methods: {

    fetch: function (id) {
      Http
          .get(process.env.VUE_APP_CATALOG_SERVICE + "/manage/category/" + id)
          .then((res) => {
            this.category = res.content;
            this.category.filters = this.category.filters.sort((a, b) => a.weight < b.weight ? -1 : 1);
          });
    },

    edit: function (filter) {
      this.filterModel = filter;
      this.filterFormShow = true;
    },

    close: function () {
      this.filterFormShow = false;
    },

    remove: function (filter) {
      this.$refs.confirmDialogue.show({
        title: 'Видалення',
        message: 'Ви дійсно хочете видалити фільтр?',
        okButton: 'Так',
        cancelButton: 'Ні',
      }).then((ok) => {
        if (ok) {
          this.category.filters = this.category.filters.filter(item => item.vocabulary !== filter.vocabulary);
          this.save();
        }

      })
    },

    submit: async function () {
      let filters = this.category.filters;
      filters = filters.filter(item => item.vocabulary !== this.filterModel.vocabulary);
      filters.push(this.filterModel);
      this.category.filters = filters;
      this.save();
      this.close();
    },

    save: function () {

      Http
          .put(process.env.VUE_APP_CATALOG_SERVICE + "/manage/category", this.category)
          .then(() => {
            this.fetch(this.$route.params.id);
            this.$refs.alert.success('Успішно збережено');
          })
          .catch((e) => {
            this.$refs.alert.danger(e.message);
          });
    },
  }
};
</script>
<style scoped>
</style>