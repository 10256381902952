<template>
  <head-panel>
    <template v-slot:body>
      Продукт <span v-if="product"> {{ product.sku }}</span>


      <a @click="remove" class="btn btn-danger btn-sm btn-remove float-end" v-if="product">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash"
             viewBox="0 0 16 16">
          <path
              d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
          <path fill-rule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>
        Видалити продукт
      </a>

    </template>
    <template v-slot:top>&nbsp;</template>
  </head-panel>

  <div class="wrapper">

    <tab :product="product" v-model="tab"/>

    <hr/>

    <Alert ref="alert"/>

    <form @submit.prevent="submit">

      <div class="tab" v-show="tab==='main'">


        <div class="row ms-2 mb-4">
          <div class="col-xl-3">SKU</div>
          <div class="col-lg-8">
            <input type="text" class="form-control" v-model="form.sku" :class="{ 'is-invalid' : v$.form.sku.$error}"
                   :disabled="product && product.sku">
            <div class="invalid-feedback" v-for="error of v$.form.sku.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
        </div>

        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Назва</div>
          <div class="col-lg-8">
            <input type="text" class="form-control" v-model="form.name" :class="{ 'is-invalid' : v$.form.name.$error}">
            <div class="invalid-feedback" v-for="error of v$.form.name.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
        </div>

        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Гарнтаія</div>
          <div class="col-lg-1">

            <div class="input-group">
              <input type="text" class="form-control" v-model="form.warranty"
                     :class="{ 'is-invalid' : v$.form.warranty.$error}">
              <span class="input-group-text" id="basic-addon2">міс</span>
            </div>

            <div class="invalid-feedback" v-for="error of v$.form.warranty.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
        </div>


        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Категорія</div>
          <div class="col-lg-8">
            <Multiselect
                v-model="form.category"
                :options="categoryOptions"
                :class="{ 'is-invalid' : v$.form.category.name.$error}"
                :multiple="false"
                :searchable="true"
                @search-change="findCategories"
                placeholder="Введіть назву категоріі"
                selectLabel=""
                label="label"
                track-by="name"
            >
              <template v-slot:option="{ option }">
                <span class="option__name">{{ option.label }}</span>
                <span class="option__path">{{ option.path }}</span>
              </template>
              <template #noOptions>
                Пошук категорій...
              </template>
              <template #noResult>
                Категорій не знайдено!
              </template>
            </Multiselect>
            <div class="invalid-feedback" v-for="error of v$.form.category.name.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
        </div>


        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Бренд</div>
          <div class="col-lg-8">
            <Multiselect
                v-model="form.brand"
                :options="brandOptions"
                :class="{ 'is-invalid' : v$.form.brand.name.$error}"
                :multiple="false"
                :searchable="true"
                @search-change="findBrands"
                placeholder="Введіть назву бренду"
                selectLabel=""
                label="label"
                track-by="name"
            >
              <template #noOptions>
                Пошук брендів...
              </template>
              <template #noResult>
                Бренд не знайдено!
              </template>
            </Multiselect>
            <div class="invalid-feedback" v-for="error of v$.form.brand.name.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
        </div>

        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Теги</div>
          <div class="col-lg-8">
            <multiselect v-model="form.tags"
                         tag-placeholder="Додати тег"
                         placeholder="Додати тег"
                         label="label"
                         track-by="name"
                         :options="tagOptions"
                         :multiple="true"
                         :taggable="true"
                         :searchable="true"
                         @search-change="findTags">
              <template #noOptions>
                Теги використовуються для пошуку банера(ів). Доволено використовувати символи для імені a-z, 0-9
              </template>
            </multiselect>
          </div>
        </div>


        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Url</div>
          <div class="col-lg-8">
            <input type="text" class="form-control" :value="form.url" disabled>
          </div>
        </div>

        <div class="row ms-2 mb-4">
          <div class="col-xl-3">GTIN</div>
          <div class="col-md-4 col-lg-3">
            <input type="text" class="form-control" v-model="form.gtin" :class="{ 'is-invalid' : v$.form.gtin.$error}">
            <div class="invalid-feedback" v-for="error of v$.form.gtin.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
        </div>


        <div class="row ms-2 mb-4">
          <div class="col-xl-3">MPN</div>
          <div class="col-md-4 col-lg-3">
            <input type="text" class="form-control" v-model="form.mpn" :class="{ 'is-invalid' : v$.form.mpn.$error}">
            <div class="invalid-feedback" v-for="error of v$.form.mpn.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
        </div>

        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Опис</div>
          <div class="col-lg-8">
            <QuillEditor theme="snow" toolbar="essential" v-model:content="form.description"
                         contentType="html"/>
          </div>
        </div>

        <div class="row ms-2 mb-4">
          <div class="col-xl-6">
            <button type="submit" class="btn btn-primary">Зберегти</button>
          </div>
        </div>

      </div>

      <div class="tab" v-show="tab==='properties'">


        <div class="ms-3 mb-4">

          <property-form v-model="form.properties"/>

        </div>

        <div class=" ms-2 mb-4">

          <button type="submit" class="btn btn-primary">Зберегти</button>

        </div>


      </div>

      <div class="tab" v-show="tab==='meta'">

        <meta-form v-model="form.meta" :with-label="true"/>
        <div class="row ms-2 mb-4">
          <div class="col-xl-6">
            <button type="submit" class="btn btn-primary">Зберегти</button>
          </div>
        </div>

      </div>

      <div class="tab" v-show="tab==='photo'">
        <form id="upload">
          <div class="file-input">
            <input
                id="upload_file"
                class="file"
                ref="file"
                name="file"
                type="file"
                v-on:change="e => fileUpload(e, 'small')"
            />
            <label for="upload_file">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-cloud-upload" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/>
                <path fill-rule="evenodd"
                      d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z"/>
              </svg>
              Виберіть файл</label>
          </div>
        </form>

        <table class="table">
          <thead>
          <tr>
            <th>Name</th>
            <th>Image</th>
            <th>Weight</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="image in form.images" :key="image">
            <td>{{ image.name }}</td>
            <td>
              <picture class="img-fluid">
                <source v-bind:srcset="$filters.image(image.name, '180x180', true)" type="image/webp" height="70">
                <img v-bind:src="$filters.image(image.name, '180x180')" height="70">
              </picture>
            </td>
            <td>{{ image.weight }}</td>
            <td class="actions">
              <a @click="editImage(image)">Edit</a>
              <a @click="removeImage(image.name)">Delete</a>
            </td>
          </tr>
          </tbody>
        </table>


      </div>

    </form>

    <div class="tab" v-if="tab==='price'">
      <edit-price-tab :product="product"/>
    </div>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
  <edit-image-modal v-model="imageModel" @close="closeImageModal" @submit.prevent="submitImageModal" v-if="imageModel"/>
</template>

<script>
import Http from "../../lib/Http";
import Tab from "./Tab.vue";
import EditPriceTab from "./EditPriceTab.vue";
import HeadPanel from "../../components/HeadPanel";
import ConfirmDialogue from '../../components/ConfirmDialog.vue'
import Alert from "../../components/Alert";
import MetaForm from "../../components/MetaForm";
import PropertyForm from "../../components/PropertyForm";
import EditImageModal from "./EditImageModal";
import Multiselect from "vue-multiselect";
import {debounce} from "@/lib/Util";
import {useVuelidate} from '@vuelidate/core'
import {required, maxLength, helpers} from '@vuelidate/validators'
import {QuillEditor} from '@vueup/vue-quill'

export default {
  name: "Edit",
  components: {
    Tab,
    EditPriceTab,
    HeadPanel,
    ConfirmDialogue,
    Alert,
    MetaForm,
    PropertyForm,
    Multiselect,
    QuillEditor,
    EditImageModal
  },
  created() {
    if (this.$route.params.id)
      this.fetch(this.$route.params.id);

    this.fetchBrands();
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        sku: {
          required,
          maxLength: maxLength(50),
          pattern: helpers.withMessage('Invalid sku format', (v) => v.match("^[a-z0-9\\-.]+$"))
        },
        gtin: {
          maxLength: maxLength(15)
        },
        mpn: {
          maxLength: maxLength(50)
        },
        name: {
          required,
          maxLength: maxLength(255)
        },
        warranty: {
          required
        },
        category: {
          name: {
            required,
            maxLength: maxLength(255),
            pattern: helpers.withMessage('Invalid category format', (v) => v.match("^[a-z0-9\\-]+$"))
          }
        },
        brand: {
          name: {
            required,
            maxLength: maxLength(255),
            pattern: helpers.withMessage('Invalid brand format', (v) => v.match("^[a-z0-9\\-]+$"))
          }
        },
        description: {
          maxLength: maxLength(20000)
        }
      }
    };
  },
  data() {
    return {
      tab: 'main',
      product: null,
      rootCategoryOptions: [],
      categoryOptions: [],
      brandOptions: [],
      tagOptions: [],
      imageModel: null,
      form: {
        sku: null,
        gtin: null,
        mpn: null,
        category: null,
        brand: null,
        tags: null,
        name: null,
        warranty: null,
        description: null,
        url: null,
        properties: null,
        meta: null,
        images: []
      }
    }
  },
  methods: {
    fetch: function (id) {
      Http
          .get(process.env.VUE_APP_CATALOG_SERVICE + "/manage/product/" + id)
          .then((res) => {
            this.product = res.content;
            this.form = this.product;
            this.form.category = {
              label: this.$filters.locale(this.product.category.locales),
              path: this.product.category.path.replaceAll(":", " --> "),
              name: this.product.category.name
            }
            this.form.brand = {
              label: this.$filters.locale(this.product.brand.locales),
              name: this.product.brand.name
            }
          })
          .then(() => this.fetchProductTags());
    },
    fetchBrands: function () {
      Http
          .get(process.env.VUE_APP_CATALOG_SERVICE + "/manage/product/brands")
          .then((res) => {
            this.brandOptions = res.content.map(brand => {
              return {
                label: this.$filters.locale(brand.locales),
                name: brand.name
              }
            });
          });
    },
    fetchProductTags() {
      Http
          .post(process.env.VUE_APP_CATALOG_SERVICE + "/manage/product/tags", this.product.tags)
          .then((res) => {

            this.form.tags = res.content.map(tag => {
              return {
                label: this.$filters.locale(tag.locales),
                name: tag.id
              };
            });
          });
    },
    findCategories(query) {
      debounce(() => {
        Http
            .get(process.env.VUE_APP_CATALOG_SERVICE + "/manage/category?search=" + query.toLowerCase())
            .then((res) => {
              this.categoryOptions = res.content.map(cat => {
                return {
                  label: this.$filters.locale(cat.locales),
                  path: cat.path.replaceAll(":", " --> "),
                  name: cat.name
                }
              });
            });
      }, 300)();
    },
    findBrands(query) {
      debounce(() => {
        Http
            .get(process.env.VUE_APP_CATALOG_SERVICE + "/manage/brand?search=" + query.toLowerCase())
            .then((res) => {
              this.brandOptions = res.content.map(brand => {
                return {
                  label: this.$filters.locale(brand.locales),
                  name: brand.name
                }
              });
            });
      }, 300)();
    },
    findTags(query) {
      debounce(() => {
        Http
            .get(process.env.VUE_APP_CATALOG_SERVICE + "/manage/tag?search=" + query.toLowerCase())
            .then((res) => {
              this.tagOptions = res.content.map(tag => {
                return {
                  label: this.$filters.locale(tag.locales),
                  name: tag.vocabulary + "." + tag.name
                }
              });
            });
      }, 300)();
    },

    submit: async function () {


      const result = await this.v$.$validate()

      if (!result) return

      let data = {
        sku: this.form.sku,
        gtin: this.form.gtin ? this.form.gtin : null,
        mpn: this.form.mpn ? this.form.mpn : null,
        name: this.form.name,
        warranty: this.form.warranty,
        category: [this.form.category.name],
        brand: this.form.brand.name,
        tags: !this.form.tags ? [] : this.form.tags.map(tag => tag.name),
        description: this._description(this.form.description),
        properties: this.form.properties,
        meta: this.form.meta,
        images: this.form.images
      };

      if (!this.product)
        Http
            .post(process.env.VUE_APP_CATALOG_SERVICE + "/manage/product", data)
            .then((res) => {
              this.$router.replace("/product/" + res.content.sku);
              this.fetch(res.content.sku);
              this.$refs.alert.success('Успішно збережено');
            })
            .catch((e) => {
              this.$refs.alert.danger(e.message);
            });
      else
        Http
            .put(process.env.VUE_APP_CATALOG_SERVICE + "/manage/product/" + this.product.sku, data)
            .then((res) => {
              this.fetch(res.content.sku);
              this.$refs.alert.success('Успішно збережено');
              return true;
            })
            .catch((e) => {
              this.$refs.alert.danger(e.message);
            });
    },
    _description: function (description) {
      return description && description.replace(/<\/?[^>]+(>|$)/g, "") ? description : null;
    },

    submitImageModal: async function () {
      this.closeImageModal();
      return this.flushImages();
    },

    fileUpload: function (e, type) {
      const file = e.target.files[0];
      const fd = new FormData();
      fd.append('file', file, file.name);
      fd.append('type', type)

      Http.post(process.env.VUE_APP_PIO_SERVICE + "/v1/upload/" + process.env.VUE_APP_PIO_BUCKET + "/multipart", fd)
          .then((res) => {

            const mediaType = res.content.mediaType;
            const filename = res.content.filename + '.' + mediaType.split("/").pop();

            if (!this.form.images)
              this.form.images = [];

            this.form.images.push({
              "name": filename,
              "weight": 0
            });

            document.getElementById("upload").reset();
          })
          .then(() => this.flushImages());
    },

    flushImages: function () {

      this.form.images.sort((a, b) => a.weight - b.weight);

      return Http
          .post(process.env.VUE_APP_CATALOG_SERVICE + "/manage/product/" + this.product.sku + "/images", this.form.images)
          .then(() => {
          });
    },

    editImage: function (image) {
      this.imageModel = image;
    },

    closeImageModal: function () {
      this.imageModel = null;
    },

    removeImage: function (image) {

      this.$refs.confirmDialogue.show({
        title: 'Видалення',
        message: 'Ви дійсно хочете видалити пункт меню?',
        okButton: 'Так',
        cancelButton: 'Ні',
      }).then((ok) => {
        if (ok) {
          this.form.images = this.form.images.filter(e => e.name !== image);
          this.flushImages();
        }
      });
    },

    remove: function (item) {
      this.$refs.confirmDialogue.show({
        title: 'Видалення',
        message: 'Ви дійсно хочете видалити продукт?',
        okButton: 'Так',
        cancelButton: 'Ні',
      }).then((ok) => {
        if (ok)
          Http
              .del(process.env.VUE_APP_CATALOG_SERVICE + "/manage/product/" + item.sku)
              .then(() => {
                this.$router.replace("/product");
              });
      })
    },

  }
};
</script>

<style scoped>


textarea.form-control {
  height: 200px;
}

.option__path {
  float: right;
}

.file-input {
  margin-left: 20px;
  margin-bottom: 10px;
  overflow: hidden;
}

.file {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.file-input label {
  position: relative;
  width: 140px;
  height: 30px;
  border-radius: 6px;
  background: linear-gradient(40deg, #519df8, #248afd);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  transition: transform .2s ease-out;
  float: left;
}

.file-input label svg {
  margin-right: 10px;
}

</style>
