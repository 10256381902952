<template>

      <template v-for="group in items" :key="group">
        <hr/>
        <div class="row ms-2 mb-4">
          <div class="col-xl-3">{{ group.name }}</div>
          <div class="col-lg-8">

            <div class="form-check form-check-inline" v-for="item in group.items" :key="item">
              <input class="form-check-input" type="checkbox" v-bind:id="item.value"
                     v-model="form.permission[item.value]" :name="item.value">
              <label class="form-check-label" v-bind:for="item.value">{{ item.label }}</label>
            </div>

          </div>
        </div>
      </template>

</template>

<script>
import Http from "../../lib/Http";

export default {
  name: "marketPermissions",
  props: {
    manager: Object
  },
  created() {
    this.form.permission = this.manager.permissions.reduce(function (result, key) {
      result[key] = true;
      return result
    }, {});
  },
  computed: {
    items: function () {
      return [
        {
          name: 'Запити клієнтів',
          items: [
            {label: 'Опрацювання', value: `ticket_manage`},
          ]
        },
        {
          name: 'Замовлення',
          items: [
            {label: 'Перегляд', value: `order_read`},
            {label: 'Опрацювання', value: `order_manage`},
          ]
        },
        {
          name: 'Клієнти',
          items: [
            {label: 'Перегляд', value: `client_read`},
            {label: 'Редагування', value: `client_manage`},
          ]
        },
        {
          name: 'Імпорт',
          items: [
            {label: 'Налаштування', value: `import_manage`},
            {label: 'Ручні завантаження', value: `import_upload`},
            {label: 'Редагування джерел', value: `import_source_manage`},
            {label: 'Редагування курсів', value: `currency_manage`},
          ]
        },
        {
          name: 'Продукти',
          items: [
            {label: 'Перегляд', value: `product_read`},
            {label: 'Редагування', value: `product_manage`},
          ]
        },
        {
          name: 'Промокоди',
          items: [
            {label: 'Перегляд', value: `promo_code_read`},
            {label: 'Редагування', value: `promo_code_manage`},
          ]
        },
        {
          name: 'Програми лояльності',
          items: [
            {label: 'Налаштування', value: `loyalty_manage`},
          ]
        },
        {
          name: 'Меню',
          items: [
            {label: 'Редагування', value: 'menu_manage'},
          ]
        },
        {
          name: 'Каталог',
          items: [
            {label: 'Редагування', value: 'catalog_manage'},
          ]
        },
        {
          name: 'Категорії',
          items: [
            {label: 'Редагування', value: 'category_manage'},
          ]
        },
        {
          name: 'Бренди',
          items: [
            {label: 'Редагування', value: 'brand_manage'},
          ]
        },
        {
          name: 'Теги',
          items: [
            {label: 'Редагування', value: 'tag_manage'},
          ]
        },
        {
          name: 'Словники',
          items: [
            {label: 'Редагування', value: 'vocabulary_manage'},
          ]
        },
        {
          name: 'Банери',
          items: [
            {label: 'Редагування', value: 'banner_manage'},
          ]
        },
        {
          name: 'SEO',
          items: [
            {label: 'Редагування', value: 'seo_manage'},
          ]
        },
        {
          name: 'Налаштування',
          items: [
            {label: 'Редагування', value: 'setting_manage'},
          ]
        }
      ];
    }
  },
  data() {
    return {
      form: {
        permission: []
      }
    }
  },
  methods: {
    submit: async  function () {
      const permissions = [];

      Object.entries(this.form.permission).forEach(item => {
        const [k, v] = item;
        if (v)
          permissions.push(k);
      });

      Http
          .put(process.env.VUE_APP_CRM_SERVICE + "/manager/" + this.manager.uuid + "/permission", permissions)
          .then(() => {
            this.$refs.alert.success('Успішно збережено');
          });
    }
  }
};
</script>

<style scoped>
.preview {
  float: left;
  margin-right: 10px;
  position: relative;
}

.preview svg {
  color: red;
  cursor: pointer;
  margin-left: 20px;
}
</style>
