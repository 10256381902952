import Sidebar from '../components/Sidebar.vue'
import CatalogList from "@/views/catalog/Index.vue";
import CatalogEdit from "@/views/catalog/Edit.vue";

import CatalogGroupList from "@/views/catalog/IndexGroup.vue";
import CatalogGroupEdit from "@/views/catalog/EditGroup.vue";

import EditShortCut from "@/views/catalog/EditShortCut.vue";

export default [
    {
        path: "/catalog",
        name: "Catalog",
        components: {
            default: CatalogList,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/catalog/create",
        name: "CatalogCreate",
        components: {
            default: CatalogEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/catalog/:id",
        name: "CatalogEdit",
        components: {
            default: CatalogEdit,
            LeftSidebar: Sidebar
        }
    },


    {
        path: "/catalog/:catalog/list",
        name: "CatalogGroup",
        components: {
            default: CatalogGroupList,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/catalog/:catalog/create",
        name: "CatalogGroupCreate",
        components: {
            default: CatalogGroupEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/catalog/:catalog/group/:group",
        name: "CatalogGroupEdit",
        components: {
            default: CatalogGroupEdit,
            LeftSidebar: Sidebar
        }
    },

    {
        path: "/catalog/:catalog/group/:group/create",
        name: "EditShortCutCreate",
        components: {
            default: EditShortCut,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/catalog/:catalog/group/:group/shortcut/:shortcut",
        name: "EditShortCutEdit",
        components: {
            default: EditShortCut,
            LeftSidebar: Sidebar
        }
    },


];