<template>

  <table class="table table-borderless" v-if="form">
    <tbody>
    <tr v-for="item in form" :key="item">
      <td>
        <input  type="text" class="form-control" v-model="item.sku" :class="{ 'is-invalid' : false}" style="width: 200px;" placeholder="SKU">
        <div class="invalid-feedback"></div>
      </td>
      <td style="width: 1%">
      </td>
      <td style="width: 150px">
      </td>

    </tr>
    </tbody>
  </table>


</template>

<script>
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: "RelatedFeatures",
  components: {},
  props: {
    modelValue: Object,
  },
  watch: {
    modelValue(value){
      if (value && Array.isArray(value) && value[0].sku)
        this.form = value;
    },
    form: {
      handler(val) {
        this.$emit('update:modelValue', val);
      },
      deep: true
    }
  },
  data() {
    return {
      form: [
        {sku: null},
        {sku: null},
        {sku: null},
      ]
    }
  },
  methods: {}
};
</script>

<style scoped>
table{
  background-color: aliceblue;
}
</style>