<template>
  <head-panel>
    <template v-slot:body>Конфігурація завантажень
      <router-link to="/import/config/create" class="btn btn-primary btn-sm btn-create ms-2">Створити нову карту
      </router-link>
    </template>
  </head-panel>

  <div class="wrapper">

    <tab :full="currentConfigUuid !== null"/>

    <hr/>

    <Alert ref="alert"/>

    <div class="filter" v-if="currentConfigUuid">
      <div class="item">
        <div>Карта</div>
        <select class="form-select mapping-config" v-model="this.currentConfigUuid" @change="changeMap">
          <option :value="config.uuid" v-for="config in configs" :key="config">{{ config.name }}</option>
        </select>
      </div>
      <div class="item item-config" v-if="currentConfigUuid">
        <router-link :to="'/import/config/'+this.currentConfigUuid" class="btn btn-secondary">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear"
               viewBox="0 0 16 16">
            <path
                d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"/>
            <path
                d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"/>
          </svg>
          Налаштувати
        </router-link>
      </div>
    </div>

    <div v-if="!currentConfigUuid" class="ms-4">
      Для початку завантаження цін створіть нову конфігурацію.
      <router-link to="/import/config/create" class="ms-2">Створити конфігурацію</router-link>
    </div>


    <div class="form-text ms-4 me-4 mb-2">Розмітка відношень sku завантаженного файлу до sku продукціі на сайті. Не
      найдені sku відношення будуть проігноровані при імпорті.
    </div>


    <table class="table" v-if="currentConfigUuid">
      <thead>
      <tr>
        <th scope="col">SKU джерела</th>
        <th scope="col">Назва джерела(не обов'язково)</th>
        <th scope="col">SKU внутрішній</th>
        <th scope="col">Назва внутрішня</th>
        <th scope="col"></th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>


      <tr class="table-light">
        <td>
          <input type="text" class="form-control" v-model="filter.sku" :class="{ 'is-invalid' : v$.filter.sku.$error}"
                 placeholder="Фільтр по sku">
          <div class="invalid-feedback" v-for="error of v$.filter.sku.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </td>
        <td>
          <input type="text" class="form-control" v-model="filter.name" :class="{ 'is-invalid' : v$.filter.name.$error}"
                 placeholder="Фільтр по назві">
          <div class="invalid-feedback" v-for="error of v$.filter.name.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </td>
        <td>
          <input type="text" class="form-control" v-model="filter.internalSku"
                 :class="{ 'is-invalid' : v$.filter.internalSku.$error}"
                 placeholder="Фільтр по вніутрішньому sku">
          <div class="invalid-feedback" v-for="error of v$.filter.internalSku.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </td>
        <td>
          <input type="text" class="form-control" v-model="filter.internalName"
                 :class="{ 'is-invalid' : v$.filter.internalName.$error}"
                 placeholder="Фільтр по внутрішній назві">
          <div class="invalid-feedback" v-for="error of v$.filter.internalName.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </td>
        <td></td>
        <td class="actions">
          <div class="form-check form-switch mt-1">
            <input class="form-check-input" type="checkbox" role="switch" id="ems" v-model="filter.empty">
            <label class="form-check-label" for="ems">Незаповнені</label>
          </div>
        </td>
      </tr>


      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>

      <tr @click="beginEdit(item)" v-for="(item, index) in form" :key="item">
        <td>
          <input type="text" class="form-control" v-model="item.sku" :disabled="item.id"
                 :class="{ 'is-invalid' : v$.form.$each.$response.$errors[index].sku.length}" @change="change(item)">
          <div class="invalid-feedback" v-for="error of v$.form.$each.$response.$errors[index].sku"
               :key="error.$uid">
            {{ error.$message }}
          </div>
        </td>
        <td>
          <input type="text" class="form-control" v-model="item.name"
                 :class="{ 'is-invalid' : v$.form.$each.$response.$errors[index].name.length}" @change="change(item)">
          <div class="invalid-feedback" v-for="error of v$.form.$each.$response.$errors[index].name"
               :key="error.$uid">
            {{ error.$message }}
          </div>
        </td>
        <td>
          <input type="text" class="form-control" v-model="item.internalSku"
                 :class="{ 'is-invalid' : v$.form.$each.$response.$errors[index].internalSku.length}"
                 @change="changeInternalSku(item)">
          <div class="invalid-feedback" v-for="error of v$.form.$each.$response.$errors[index].internalSku"
               :key="error.$uid">
            {{ error.$message }}
          </div>
        </td>
        <td>
          <input type="text" class="form-control" v-model="item.internalName" disabled
                 :class="{ 'is-invalid' : v$.form.$each.$response.$errors[index].internalName.length}"
                 @change="change(item)">
          <div class="invalid-feedback" v-for="error of v$.form.$each.$response.$errors[index].internalName"
               :key="error.$uid">
            {{ error.$message }}
          </div>
        </td>
        <td class="state">
          <svg v-if="item.state === 'ok'" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
               fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
            <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
          </svg>
          <svg v-if="item.state === 'error'" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
               fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
            <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
          </svg>
        </td>
        <td class="actions">
          <a @click="remove(item)" class="text-danger" v-if="item.id">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3"
                 viewBox="0 0 16 16">
              <path
                  d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
            </svg>
          </a>
          <div v-else class="form-text mt-2">Додання ного запису</div>
        </td>
      </tr>

      <tr v-if="!this.items.length">
        <td colspan="6">
          Не знайдено
        </td>
      </tr>

      </tbody>
    </table>

    <Pagination
        :current-page="pagination.page"
        :total-pages="pagination.total"/>

  </div>

  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../../lib/Http";
import HeadPanel from "../../../components/HeadPanel";
import ConfirmDialogue from "../../../components/ConfirmDialog";
import Tab from "../Tab";
import {useVuelidate} from "@vuelidate/core";
import {helpers, maxLength} from "@vuelidate/validators";
import Alert from "../../../components/Alert";
import Pagination from "../../../components/Pagination.vue";
import {debounce} from "../../../lib/Util";

export default {
  name: "MappingConfig",
  components: {
    Pagination, HeadPanel, ConfirmDialogue, Tab, Alert
  },
  created() {
    this.fetchConfigs();
    this.initFilter();
  },
  watch: {
    $route(n, o) {
      if (n.path === o.path)
        this.fetch(this.currentConfigUuid);
    },
    filter: {
      handler(val) {
        if (!this.currentConfigUuid)
          return;

        debounce(() => this.updateRoute(val), 700)();
      },
      deep: true
    }
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      filter: {
        sku: {
          maxLength: maxLength(40),
          pattern: helpers.withMessage('Invalid sku format', (v) => /^[a-z0-9\\-]+$/.test(v))
        },
        name: {
          maxLength: maxLength(255),
        },
        internalSku: {
          maxLength: maxLength(20),
          pattern: helpers.withMessage('Invalid sku format', (v) => /^[a-z0-9\\-]+$/.test(v))
        },
        internalName: {
          maxLength: maxLength(255),
        }
      },
      form: {
        $each: helpers.forEach({
          sku: {
            maxLength: maxLength(40),
          },
          name: {
            maxLength: maxLength(255),
          },
          internalSku: {
            maxLength: maxLength(20),
            pattern: helpers.withMessage('Invalid sku format', (v) => !v || /^[a-z0-9\\-]+$/.test(v))
          },
          internalName: {
            maxLength: maxLength(255),
          }
        })
      }
    };
  },
  data() {
    return {
      currentConfigUuid: null,
      configs: [],
      filter: {
        sku: null,
        name: null,
        internalSku: null,
        internalName: null,
        empty: null,
      },
      form: [],
      items: [],
      pagination: {
        page: 0,
        total: 0
      },
    }
  },
  methods: {
    changeMap: function (e) {
      this.$router.replace("/import/mapping/" + e.target.value)
          .then(() => this.fetch(e.target.value));
    },
    fetch: function (id) {

      Http
          .get(process.env.VUE_APP_IMPORT_SERVICE + "/mapping/" + id + '?' + new URLSearchParams(this.$route.query).toString())
          .then((res) => {

            this.items = res.content;
            this.form = this
                .items
                .map(item => {
                  return {
                    id: item.sku,
                    sku: item.sku,
                    name: item.name,
                    internalSku: item.internalSku,
                    internalName: item.internalName,
                    state: null
                  }
                })

            this.form.unshift({
              id: null,
              sku: null,
              name: null,
              internalSku: null,
              internalName: null,
              state: null
            })

            this.pagination.page = res.origin.headers.get("X-Current-Page") * 1;
            this.pagination.total = res.origin.headers.get("X-Page-Count") * 1;
          });
    },
    fetchConfigs: function () {
      Http
          .get(process.env.VUE_APP_IMPORT_SERVICE + "/mapping/config/list")
          .then((res) => {
            this.configs = res.content;

            if (!this.configs.length)
              return;

            this.currentConfigUuid = this.$route.params.id ?? this.configs[0].uuid;

            if (this.currentConfigUuid)
              return this.fetch(this.currentConfigUuid);

          });
    },
    initFilter: function () {
      this.filter.empty = this.$route.query.empty ?? null;
      this.filter.sku = this.$route.query.sku ?? null;
      this.filter.name = this.$route.query.name ?? null;
      this.filter.internalSku = this.$route.query.internalSku ?? null;
      this.filter.internalName = this.$route.query.internalName ?? null;
    },
    remove: function (item) {

      this.$refs.confirmDialogue.show({
        title: 'Видалення',
        message: "Ви дійсно хочете видалити запис?",
        okButton: 'Так',
        cancelButton: 'Ні',
      }).then((ok) => {
        if (ok)
          Http
              .del(process.env.VUE_APP_IMPORT_SERVICE + "/mapping/" + this.currentConfigUuid + "?sku=" + item.sku)
              .then(() => this.fetch(this.currentConfigUuid));
      })
    },
    beginEdit: function (data) {
      data.state = null;
    },
    changeInternalSku: function (data) {

      if (!data.internalSku)
        return this.change(data);

      Http
          .get(process.env.VUE_APP_CATALOG_SERVICE + "/manage/product/" + data.internalSku, data)
          .then((res) => {
            data.internalName = res.content.name;
            this.change(data);
          })
          .catch(() => {
            data.state = 'error';
            this.$refs.alert.danger("Внутрішній sku " + data.internalSku + " не найдений");
          });

    },
    change: function (data) {

      if (!data.sku)
        return;

      data.internalSku = data.internalSku.length > 0 ? data.internalSku : null;

      if (!data.id)
        Http
            .post(process.env.VUE_APP_IMPORT_SERVICE + "/mapping/" + this.currentConfigUuid, data)
            .then(() => {
              data.state = 'ok';
              this.fetch(this.currentConfigUuid);
            })
            .catch((res) => {
              data.state = 'error';
              this.$refs.alert.danger(res.message);
            });
      else
        Http
            .put(process.env.VUE_APP_IMPORT_SERVICE + "/mapping/" + this.currentConfigUuid, data)
            .then(() => {
              data.state = 'ok';
            })
            .catch((res) => {
              data.state = 'error';
              this.$refs.alert.danger(res.message);
            });

    },
    updateRoute: function (params) {
      let query = {};

      if (params.sku)
        query.sku = params.sku;

      if (params.name)
        query.name = params.name;

      if (params.internalSku)
        query.internalSku = params.internalSku;

      if (params.internalName)
        query.internalSku = params.internalSku;

      if (params.empty)
        query.empty = params.empty;

      return this.$router
          .replace("/import/mapping/" + this.currentConfigUuid + "?" + new URLSearchParams(query).toString());
    }
  }
};
</script>

<style scoped>

.filter .item-config {
  margin-top: 19px;
}

.item-config .btn-secondary{
  margin-top: 12px;
}

.filter-mapping .form-check > * {
  cursor: pointer;
}

.mapping-config {
  width: 300px;
  margin-top: 10px;
}

.state {
  width: 1%;
}

.state .bi-check {
  margin-top: 9px;
  /*color: green;*/
}

.state .bi-x {
  margin-top: 9px;
  color: red;
}

</style>