<template>

  <Alert ref="alert"/>

  <a class="btn btn-primary btn-sm add-short-cut ms-3 mb-2" @click="edit(null)">Додати</a>

  <table class="table short-cuts" v-if="category && category.shortCuts">
    <thead>
    <tr>
      <th scope="col">URL</th>
      <th scope="col">Назва</th>
      <th scope="col">Превью</th>
      <th scope="col">Вага</th>
      <th scope="col"></th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="shortCut in category.shortCuts" :key="shortCut">
      <td>{{ shortCut.url }}</td>
      <td>{{ Object.values(shortCut.locales).pop() }}</td>
      <td>
        <img v-bind:src="$filters.image(shortCut.preview, '180x180')" width="40" class="img-thumbnail"
             v-if="shortCut.preview">
      </td>
      <td>{{ shortCut.weight }}</td>
      <td class="actions">
        <a class="" @click="edit(shortCut)">Редагувати</a>
        <a @click="remove(shortCut)">Видалити</a>
      </td>
    </tr>
    </tbody>
  </table>


  <short-cut-modal v-model="shortCutModel" @close="close" @submit.prevent="submit" v-if="shortCutFormShow"/>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import ConfirmDialogue from '../../components/ConfirmDialog.vue'
import ShortCutModal from "./ShortCutModal";
import Alert from "../../components/Alert.vue";

export default {
  name: "Edit",
  components: {
    ConfirmDialogue, ShortCutModal, Alert
  },
  created() {
    if (this.$route.params.id)
      this.fetch(this.$route.params.id);
  },
  data() {
    return {
      category: null,
      shortCut: null,
      shortCutModel: null,
      shortCutFormShow: false
    }
  },
  methods: {

    fetch: function (id) {
      Http
          .get(process.env.VUE_APP_CATALOG_SERVICE + "/manage/category/" + id)
          .then((res) => {
            this.category = res.content;
            this.category.shortCuts = res.content ? res.content.shortCuts.sort((a, b) => a.weight < b.weight ? -1 : 1) : [];
          });
    },

    edit: function (short) {
      this.shortCut = short;
      this.shortCutModel = short;
      this.shortCutFormShow = true;
    },

    close: function () {
      this.shortCutFormShow = false;
    },

    remove: function (shortCut) {
      this.$refs.confirmDialogue.show({
        title: 'Видалення',
        message: 'Ви дійсно хочете видалити пункт?',
        okButton: 'Так',
        cancelButton: 'Ні',
      }).then((ok) => {
        if (ok) {
          this.category.shortCuts = this.category.shortCuts.filter(e => e.url !== shortCut.url);
          this.save();
        }

      })
    },

    submit: async function () {
      let shortCuts = this.category.shortCuts;
      shortCuts = shortCuts.filter(e => e.url !== this.shortCutModel.url);
      shortCuts.push({
        url: this.shortCutModel.url,
        preview: this.shortCutModel.preview,
        locales: this.shortCutModel.locales,
        weight: this.shortCutModel.weight
      });
      shortCuts.sort((a, b) => a.weight < b.weight ? -1 : 1);
      this.category.shortCuts = shortCuts;
      this.save();
      this.close();
    },

    save: function () {

      Http
          .put(process.env.VUE_APP_CATALOG_SERVICE + "/manage/category", this.category)
          .then(() => {
            this.fetch(this.$route.params.id);
            this.$refs.alert.success('Успішно збережено');
          })
          .catch((e) => {
            this.$refs.alert.danger(e.message);
          });
    },
  }
};
</script>
<style scoped>

table.short-cuts td {
  vertical-align: baseline;
}

</style>