Date.prototype.toMonthString = function () {
    switch (this.getMonth()) {
        case 0:
            return 'січеня';
        case 1:
            return 'лютого';
        case 2:
            return 'березня';
        case 3:
            return 'квітня';
        case 4:
            return 'травня';
        case 5:
            return 'червня';
        case 6:
            return 'липня';
        case 7:
            return 'серпня';
        case 8:
            return 'вересня';
        case 9:
            return 'жовтня';
        case 10:
            return 'листопада';
        case 11:
            return 'грудня';
    }
}


Object.defineProperty(Array.prototype, 'upsert', {
    value: function (obj, key, val) {
        let data = [].concat(this);
        if (key)
            data = data.filter(e => e[key] !== val);
        data.push(obj);
        data = data.sort((a, b) => a.weight < b.weight ? -1 : 1);

        this.length = 0;

        data.forEach(e => this.push(e))

        console.log(this)

    }
});


export default function config() {

}