<template>

    <template v-for="group in items" :key="group">
      <hr/>
      <div class="row ms-2 mb-4">
        <div class="col-xl-3">{{ group.name }}</div>
        <div class="col-lg-8">

          <div class="form-check form-check-inline" v-for="item in group.items" :key="item">
            <input class="form-check-input" type="checkbox" v-bind:id="item.value"
                   v-model="form.permission[item.value]" :name="item.value">
            <label class="form-check-label" v-bind:for="item.value">{{ item.label }}</label>
          </div>

        </div>
      </div>
    </template>

</template>

<script>
import Http from "../../lib/Http";

export default {
  name: "reviewPermissions",
  props: {
    manager: Object
  },
  created() {
    this.fetch();
  },
  computed: {
    items: function () {
      return [
        {
          name: 'Відгуки',
          items: [
            {label: 'Опрацювання', value: `review_manage`},
          ]
        }
      ];
    }
  },
  data() {
    return {
      form: {
        permission: []
      }
    }
  },
  methods: {
    fetch: async function () {
      const integration = Http.session.integrations.review;
      Http
          .get(process.env.VUE_APP_REVIEW_SERVICE + "/manager/" + integration.uuid + "?user=" + this.manager.account)
          .then(res => {
            this.form.permission = res.content.scopes.reduce(function (result, key) {
              result[key] = true;
              return result
            }, {});
          });
    },
    submit: async function () {
      const integration = Http.session.integrations.review;
      const permissions = [];

      Object.entries(this.form.permission).forEach(item => {
        const [k, v] = item;
        if (v)
          permissions.push(k);
      });

      Http
          .put(process.env.VUE_APP_REVIEW_SERVICE + "/manager/" + integration.uuid + "/permission", {
            user: this.manager.account,
            scopes: permissions
          })
          .then(() => {
            this.$refs.alert.success('Успішно збережено');
          });
    }
  }
};
</script>

<style scoped>
.preview {
  float: left;
  margin-right: 10px;
  position: relative;
}

.preview svg {
  color: red;
  cursor: pointer;
  margin-left: 20px;
}
</style>