<template>
  <head-panel>
    <template v-slot:body>
      Конфігурація <span v-if="processing"> {{ processing.uuid }}</span>


      <router-link :to=" '/wallet/processing/'">
        <button class="btn btn-secondary btn-sm btn-create-top ms-3" role="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-arrow-left-short" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
          </svg>
          Повернутись до списку
        </button>
      </router-link>


      <a @click="remove(processing)" v-if="processing"
         class="btn btn-danger btn-sm btn-remove float-end">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash"
             viewBox="0 0 16 16">
          <path
              d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
          <path fill-rule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>
        Видалити конфігурацію
      </a>

    </template>
    <template v-slot:top>&nbsp;</template>
  </head-panel>

  <div class="wrapper edit">

    <Alert ref="alert"/>

    <form @submit.prevent="submit">

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Назва</div>
        <div class="col-lg-8">
          <input type="text" class="form-control" v-model="form.name" :class="{ 'is-invalid' : v$.form.name.$error}">
          <div class="invalid-feedback" v-for="error of v$.form.name.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>


      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Статус</div>
        <div class="col-lg-8">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"  v-model="form.status" >
          </div>
        </div>
      </div>



      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Тип</div>
        <div class="col-lg-8">
          <select class="form-select" v-model="form.type" :class="{ 'is-invalid' : v$.form.type.$error}">
            <option selected value="null">-- вибрати --</option>
            <option v-for="item in types" :key="item" v-bind:value="item.value">{{ item.label }}</option>
          </select>
          <div class="invalid-feedback" v-for="error of v$.form.type.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>


      <liqpay v-if="form.type === 'liqpay'" v-model="data.liqpay" :processing="processing"/>


      <div class="row ms-2 mb-4">
        <div class="col-xl-6">
          <button type="submit" class="btn btn-primary">Зберегти</button>
        </div>
      </div>

    </form>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../../lib/Http";
import HeadPanel from "../../../components/HeadPanel.vue";
import ConfirmDialogue from '../../../components/ConfirmDialog.vue'
import Alert from "../../../components/Alert.vue";
import {useVuelidate} from "@vuelidate/core";
import {maxLength, required} from "@vuelidate/validators";

import Liqpay from "./_liqpay.vue";

export default {
  name: "Edit",
  components: {
    HeadPanel, ConfirmDialogue, Alert, Liqpay
  },
  created() {
    if (this.$route.params.id)
      this.fetch(this.$route.params.id);
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        name: {
          required,
          maxLength: maxLength(255)
        },
        type: {
          required,
        }
      }
    };
  },
  computed: {
    types: function () {
      return [
        {'label': 'LIQPAY', 'value': 'liqpay'},
        {'label': 'PRIVATE', 'value': 'private'},
      ];
    }
  },
  data() {
    return {
      processing: null,
      form: {
        name: null,
        status: null,
        type: null
      },
      data: {
        liqpay: {
          clientId: null,
          secret: null
        }
      },
    }
  },
  methods: {
    fetch: function (uuid) {
      Http
          .get(process.env.VUE_APP_WALLET_SERVICE + "/processing/" + uuid)
          .then((res) => {
            this._data(res.content);
          });
    },

    submit: async function () {

      const result = await this.v$.$validate()

      if (!result) return

      let base = {
        name: this.form.name,
        status: this.form.status ? 'ACTIVE' : 'DISABLED',
        type: this.form.type.toUpperCase()
      };

      const data = {...base, ...this.data[this.form.type]};

      if (!this.processing)
        Http
            .post(process.env.VUE_APP_WALLET_SERVICE + "/processing", data)
            .then((res) => {
              this.$router.replace("/wallet/processing/" + res.content.uuid);
              this._data(res.content);
              this.$refs.alert.success('Успішно збережено');
            });
      else
        Http
            .put(process.env.VUE_APP_WALLET_SERVICE + "/processing/" + this.processing.uuid, data)
            .then((res) => {
              this._data(res.content);
              this.$refs.alert.success('Успішно збережено');
            });
    },

    remove: function (item) {
      this.$refs.confirmDialogue.show({
        title: 'Видалення',
        message: 'Ви дійсно хочете видалити налаштування?',
        okButton: 'Так',
        cancelButton: 'Ні',
      }).then((ok) => {
        if (ok)
          Http
              .del(process.env.VUE_APP_WALLET_SERVICE + "/processing/" + item.uuid)
              .then(() => {
                this.$router.replace("/wallet/processing");
              });
      })
    },
    _data: function (content) {
      const type = content.type.toLowerCase();
      this.processing = content;

      this.form = {
        name: this.processing.name,
        status: this.processing.status === 'ACTIVE',
        type: type,
      };

      this.data[type] = {
        clientId: this.processing.clientId ?? null,
        secret: this.processing.secret ?? null,
        secret1: this.processing.secret1 ?? null,
        secret2: this.processing.secret2 ?? null,
        properties: this.processing.properties ?? null,
      }
    }
  }
};
</script>

<style scoped>
.form-body {
  height: 400px;
}
</style>