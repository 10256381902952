<template>
  <head-panel>
    <template v-slot:body>Джерела імпорту
      <router-link to="/import/source/create" class="btn btn-primary btn-sm btn-create ms-2">Створити</router-link>
    </template>
  </head-panel>

  <div class="wrapper">

    <tab :full="true"/>

    <hr/>

    <div class="filter">
      <div class="item">
        <SearchFilter
            label="Швидкий пошук"
            name="search"
            path="source"
            v-model="search"
        />
      </div>
    </div>


    <table class="table">
      <thead>
      <tr>
        <th scope="col">Назва</th>
        <th scope="col">Тип</th>
        <th scope="col">Статус</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <template v-for="source in sources" :key="source">
        <tr class="menu">
          <td>{{ source.name }}</td>
          <td>{{ source.type }}</td>
          <td>{{ source.status }}</td>
          <td class="actions">
            <router-link :to="'/import/source/'+source.uuid">Редагувати</router-link>
            <router-link :to="'/import/source/log/'+source.uuid">Лог</router-link>
            <a @click="run(source)" v-if="canRun(source)">Запуск</a>
          </td>
        </tr>
      </template>

      </tbody>
    </table>

    <Pagination
        :current-page="pagination.page"
        :total-pages="pagination.total"/>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../../lib/Http";
import HeadPanel from "../../../components/HeadPanel";
import SearchFilter from "../../../components/SearchFilter";
import ConfirmDialogue from "../../../components/ConfirmDialog";
import Pagination from "../../../components/Pagination";
import Tab from "../Tab";

export default {
  name: "Sources",
  components: {
    HeadPanel, SearchFilter, ConfirmDialogue, Pagination, Tab
  },
  created() {
    this.fetch();
  },
  watch: {
    $route(n, o) {
      if (n.path === o.path)
        this.fetch();
    },
  },
  data() {
    return {
      search: null,
      meta: null,
      maxManualRunCount: 50,
      sources: [],
      pagination: {
        page: 0,
        total: 0
      }
    }
  },
  methods: {
    fetch: function () {
      Http
          .get(process.env.VUE_APP_IMPORT_SERVICE + "/source?" + new URLSearchParams(this.$route.query).toString())
          .then((res) => {
            this.sources = res.content;
            this.pagination.page = res.origin.headers.get("X-Current-Page") * 1;
            this.pagination.total = res.origin.headers.get("X-Page-Count") * 1;
          });
    },
    canRun(source) {

      if (!source.lastSchedule)
        return true;

      if (source.lastSchedule.runType !== 'manual')
        return true;

      return ['COMPLETE', 'FAIL'].includes(source.lastSchedule.status);
    },
    run: function (source) {

      Http.get(process.env.VUE_APP_IMPORT_SERVICE + '/source/schedule/meta')
          .then(res => {
            this.meta = res.content;


            const message = 'Данний функцілнал дозваляє негайно виконати імпорт джерела. ' +
                'Кількість запусків обмежена місячною квотою. ' +
                'Ви дійсно хочете запустити імпорт? <br/><br/> Поточна квота ' + this.meta.manualRunCount + '/' + this.maxManualRunCount;

            this.$refs.confirmDialogue.show({
              title: 'Ручний запуск імпорту',
              message: message,
              okButton: 'Так',
              cancelButton: 'Ні',
            }).then((ok) => {
              if (ok)
                Http.post(process.env.VUE_APP_IMPORT_SERVICE + '/source/schedule/run/' + source.uuid)
                    .then(() => this.fetch());
            })
          })
    }
  }
};
</script>

<style scoped>

</style>