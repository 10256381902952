<template>
  <head-panel>
    <template v-slot:body>Замовлення
    </template>
  </head-panel>

  <div class="wrapper">

    <div class="filter">
      <div class="item">
        <SearchFilter
            label="Швидкий пошук"
            name="search"
            path="order"
        />
      </div>
      <div class="item">
        <DropdownFilter
            label="Статус"
            name="status"
            path="order"
            :items="statuses"
        />
      </div>

      <div class="item">
        <DropdownFilter
            label="Виплати"
            name="payout"
            path="order"
            :items="payouts"
        />
      </div>

      <div class="item date-rande">
        <DateRangeFilter path="order" :update-route="true"/>
      </div>

    </div>


    <table class="table">
      <thead>
      <tr>
        <th scope="col">№</th>
        <th scope="col">Статус</th>
        <th scope="col">Імя</th>
        <th scope="col">Телефон</th>
        <th scope="col">Створений</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <template v-for="order in items" :key="order">
        <tr>
          <td>{{ order.code }}</td>
          <td><span v-bind:class="order.status ? 'status' : ''">{{ order.status }}</span></td>
          <td>{{ order.contact ? order.contact.name : '' }}</td>
          <td>{{ order.contact ? order.contact.phone : '' }}</td>
          <td>{{ $filters.dateTime(order.createdAt) }}</td>
          <td class="actions">
            <a @click="show(order)">Деталі</a>
            <router-link :to="'/order/'+order.uuid">Ред.</router-link>
          </td>
        </tr>
        <tr v-if="detail && detail.uuid === order.uuid">
          <td colspan="7" class="detail">


            <table class="table">

              <thead>
              <tr>
                <th scope="col">Товар</th>
                <th scope="col">sku</th>
                <th scope="col">Кількість</th>
                <th scope="col">Сума</th>
              </tr>
              </thead>

              <tbody>
              <tr v-for="item in detail.items" :key="item">
                <td>{{ item.name }}</td>
                <td>{{ item.sku }}</td>
                <td>{{ item.count }} шт.</td>
                <td>{{ $filters.price(item.total / 100) }} ₴</td>
              </tr>
              </tbody>

            </table>


          </td>
        </tr>
      </template>

      </tbody>
    </table>

    <Pagination
        :current-page="pagination.page"
        :total-pages="pagination.total"/>

  </div>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import DateRangeFilter from "../../components/DateRangeFilter";
import DropdownFilter from "../../components/DropdownFilter";
import SearchFilter from "../../components/SearchFilter";
import Pagination from "../../components/Pagination";


export default {
  name: "Index",
  components: {
    HeadPanel, DateRangeFilter, DropdownFilter, SearchFilter, Pagination
  },
  created() {
    this.fetch();
    this.fetchStatuses();
  },
  watch: {
    $route(n, o) {
      if (n.path === o.path)
        this.fetch();
    },
  },
  computed: {
    payouts() {
      return [
        {value: 'NONE', label: 'NONE'},
        {value: 'REQUIRED', label: 'REQUIRED'},
        {value: 'COMPLETE', label: 'COMPLETE'},
      ];
    },
  },
  data() {
    return {
      statuses: [],
      detail: null,
      items: [],
      pagination: {
        page: 0,
        total: 0
      },
    }
  },
  methods: {
    fetch: function () {
      Http
          .get(process.env.VUE_APP_CRM_SERVICE + this.$route.fullPath)
          .then((res) => {
            this.items = res.content;
            this.pagination.page = res.origin.headers.get("X-Current-Page") * 1;
            this.pagination.total = res.origin.headers.get("X-Page-Count") * 1;
          });
    },
    fetchStatuses: function () {
      Http
          .get(process.env.VUE_APP_CRM_SERVICE + '/vocabulary/alias:order:status')
          .then(res => {
            this.statuses = res.content.items.map(item => {
              return {'value': item.alias, 'label': item.name};
            });
          })
    },
    show: function (item) {
      Http
          .get(process.env.VUE_APP_CRM_SERVICE + "/order/" + item.uuid + "/short")
          .then((res) => {
            this.detail = res.content;
          });
    }
  }
};
</script>

<style scoped>
.date-range {
  float: right;
}


table td {
  font-size: 13px;
}

.wrapper {
  height: 100%;
}

.detail {
  background-color: #eee;
  padding: 20px 20px;
}

.detail .table {
  background-color: #fff;
}

.date-rande {
  margin-left: auto;
  order: 2;
}

</style>