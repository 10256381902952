<template>
  <head-panel>
    <template v-slot:body> Дозволи</template>
  </head-panel>

  <div class="wrapper">
    <Alert ref="alert"/>
    <form @submit.prevent="submit">
      <review-permission v-if="manager" :manager="manager" ref="reviewPermission"/>
      <content-permission v-if="manager" :manager="manager" ref="contentPermission"/>
      <market-permission v-if="manager" :manager="manager" ref="marketPermission"/>
      <div class="row ms-2 mb-4">
        <div class="col-xl-6">
          <button type="submit" class="btn btn-primary">Зберегти</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import HeadPanel from "../../components/HeadPanel";
import Alert from "../../components/Alert";
import MarketPermission from "./_marketPermissions";
import ReviewPermission from "./_reviewPermissions";
import ContentPermission from "./_contentPermissions";
import Http from "@/lib/Http";

export default {
  name: "Edit",
  components: {
    HeadPanel, Alert, MarketPermission, ReviewPermission, ContentPermission
  },
  created() {
    this.fetch();
  },
  data() {
    return {
      manager: null,
      scopes: [],
    }
  },
  methods: {
    fetch: function () {
      const id = this.$route.params.id;
      Http
          .get(process.env.VUE_APP_CRM_SERVICE + "/manager/" + id)
          .then(res => {
            this.manager = res.content;
          });
    },
    submit: function () {
      this.$refs.reviewPermission.submit();
      this.$refs.contentPermission.submit();
      this.$refs.marketPermission.submit();
      this.$refs.alert.success('Успішно оновлено');
    }
  }
};
</script>

<style scoped>
.preview {
  float: left;
  margin-right: 10px;
  position: relative;
}

.preview svg {
  color: red;
  cursor: pointer;
  margin-left: 20px;
}
</style>