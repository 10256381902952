import Sidebar from '../components/Sidebar.vue'
import MenuList from "@/views/menu/Index.vue";
import MenuEdit from "@/views/menu/Edit.vue";

export default [
    {
        path: "/menu",
        name: "Menu",
        components: {
            default: MenuList,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/menu/create",
        name: "MenuCreate",
        components: {
            default: MenuEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/menu/create/:parent",
        name: "MenuCreateParent",
        components: {
            default: MenuEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/menu/:id",
        name: "MenuEdit",
        components: {
            default: MenuEdit,
            LeftSidebar: Sidebar
        }
    }
];