<template>

  <table class="table table-borderless" v-if="form">
    <tbody>
    <tr v-for="delivery in form" :key="delivery">
      <td>{{ delivery.label }}</td>
      <td style="width: 1%">
        <select class="form-select mapping-config" v-model="delivery.condition" style="width: 200px;">
          <option :value="condition.value" v-for="condition in conditions" :key="condition">{{
              condition.label
            }}
          </option>
        </select>
      </td>
      <td style="width: 150px">

        <input v-if="delivery.condition === 'FROM' || delivery.condition === 'FIXED'" type="text" class="form-control"
               v-model="delivery.cost" :class="{ 'is-invalid' : false}" style="width: 100px;">
        <div class="invalid-feedback"></div>

      </td>

    </tr>
    </tbody>
  </table>


</template>

<script>
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: "DeliveryFeatures",
  components: {},
  props: {
    modelValue: Object,
  },
  watch: {
    modelValue(value){
      if (value && Array.isArray(value) && value[0].type)
        this.form = value;
    },
    form: {
      handler(val) {
        this.$emit('update:modelValue', val);
      },
      deep: true
    }
  },
  computed: {
    conditions() {
      return [
        {value: 'DEFAULT', label: 'За замовчуванням'},
        {value: 'FREE', label: 'Безкоштовно'},
        {value: 'TRANSPORTER', label: 'За тарифами перевізника'},
        {value: 'FROM', label: 'Від'},
        {value: 'FIXED', label: 'Фіксована'},
      ];
    }
  },
  data() {
    return {
      form: [
        {type: 'SELF_PICKUP', label: 'Самовивіз', condition: 'DEFAULT', cost: null},
        {type: 'NP', label: 'Нова пошта', condition: 'DEFAULT', cost: null},
        {type: 'NPС', label: 'Нова пошта кур\'єр', condition: 'DEFAULT', cost: null},
        {type: 'UP', label: 'Укрпошта', condition: 'DEFAULT', cost: null},
        {type: 'UPC', label: 'Укрпошта кур\'єр', condition: 'DEFAULT', cost: null},
        {type: 'MSP', label: 'MEEST пошта', condition: 'DEFAULT', cost: null},
      ]
    }
  },
  methods: {}
};
</script>

<style scoped>
table{
  background-color: aliceblue;
}
</style>