<template>
  <head-panel>
    <template v-slot:body>
      Запис словника <span v-if="item"> {{ item.uuid }}</span>

      <router-link :to="'/vocabulary/' + this.$route.params.vid + '/item'">
        <button class="btn btn-secondary btn-sm btn-create-top ms-3" role="button"><i class='bx bx-arrow-back icon'></i>
          назад
        </button>
      </router-link>

    </template>
  </head-panel>

  <div class="wrapper ps-3 pe-3 edit">

    <Alert ref="alert"/>

    <form @submit.prevent="submit" id="upload">

      <div class="row mb-4">
        <div class="col-xl-3">Назва</div>
        <div class="col-lg-8">
          <input type="text" class="form-control" v-model="form.name" :class="{ 'is-invalid' : v$.form.name.$error}">
          <div class="invalid-feedback" v-for="error of v$.form.name.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-xl-3">Аліас</div>
        <div class="col-lg-8">
          <input type="text" class="form-control" v-model="form.alias" :class="{ 'is-invalid' : v$.form.alias.$error}"
                 :disabled="!!item">
          <div class="form-text">Коротка назва латинецею. Дозволені символи: a-z, 0-9</div>
          <div class="invalid-feedback" v-for="error of v$.form.alias.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-xl-3">Обмеження переходу</div>
        <div class="col-lg-8">

          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="isforwd" v-model="form.isForward">
            <label class="form-check-label" for="isforwd">Дозволяє вказати список стутусів на які дозволений
              перехід</label>
          </div>
        </div>
      </div>

      <div class="row  mb-4" v-if="form.isForward">
        <div class="col-xl-3">Дозволені переходи</div>
        <div class="col-lg-8">
          <multiselect v-model="form.forwards" placeholder="Додати статус" label="name"
                       track-by="alias" :options="forwardOptions" :multiple="true" :taggable="false" :showLabels="false"

          >
            <template #noOptions>
              Список дозволених переходів на статуси
            </template>
          </multiselect>

        </div>
      </div>

      <div class="row mb-4">
        <div class="col-xl-3">Вага</div>
        <div class="col-lg-8">
          <input class="form-control" type="text" id="weight" v-model="form.weight">
          <label class="form-check-label" for="weight"></label>
        </div>
      </div>


      <div class="row mb-4">
        <div class="col-xl-6">
          <button type="submit" class="btn btn-primary">Зберегти</button>
        </div>
      </div>

    </form>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import ConfirmDialogue from '../../components/ConfirmDialog.vue'
import Alert from "../../components/Alert";
import Multiselect from "vue-multiselect";
import {useVuelidate} from "@vuelidate/core";
import {helpers, integer, maxLength, required} from "@vuelidate/validators";

export default {
  name: "Edit",
  components: {
    HeadPanel, ConfirmDialogue, Alert, Multiselect
  },
  created() {
    this.fetchVocabulary();
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        name: {
          required,
          maxLength: maxLength(255),
        },
        alias: {
          required,
          maxLength: maxLength(255),
          pattern: helpers.withMessage('Invalid alias format', (v) => v.match("^[a-zA-Z0-9\\-.:_]+$"))
        },
        weight: {
          integer,
        }
      }
    };
  },
  data() {
    return {
      item: null,
      forwardOptions: [],
      form: {
        name: null,
        isForward: false,
        forwards: [],
        weight: null
      }
    }
  },
  methods: {
    fetchVocabulary: function () {
      Http
          .get(process.env.VUE_APP_CRM_SERVICE + '/vocabulary/' + this.$route.params.vid)
          .then((res) => {

            if (this.$route.params.alias) {
              this.item = res.content.items.find(e => e.alias === this.$route.params.alias);
              this.form = this.item;
              this.form.forwards = res.content.items.filter(e => this.item.forwards.includes(e.alias));
            }

            if (this.item != null)
              this.forwardOptions = res.content.items.filter(e => e.alias !== this.item.alias);
            else
              this.forwardOptions = res.content.items;
          });
    },
    submit: async function () {

      const result = await this.v$.$validate()

      if (!result) return

      let data = {
        name: this.form.name,
        alias: this.form.alias,
        isForward: this.form.isForward,
        forwards: this.form.forwards.map(item => item.alias),
        weight: this.form.weight,
      };

      if (!this.item)
        Http
            .post(process.env.VUE_APP_CRM_SERVICE + '/vocabulary/' + this.$route.params.vid + '/item', data)
            .then(() => {
              this.$router.replace('/vocabulary/' + this.$route.params.vid + '/item/' + data.alias);
              this.$refs.alert.success('Успішно збережено');
            });
      else
        Http
            .put(process.env.VUE_APP_CRM_SERVICE + '/vocabulary/' + this.$route.params.vid + '/item', data)
            .then(() => {
              this.$refs.alert.success('Успішно збережено');
            });
    }
  }
};
</script>

<style scoped>


</style>