import Sidebar from '../components/Sidebar.vue'
import CategoryList from "@/views/category/Index.vue";
import CategoryEdit from "@/views/category/Edit.vue";

export default [

    {
        path: "/category",
        name: "Category",
        components: {
            default: CategoryList,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/category/create",
        name: "CategoryCreate",
        components: {
            default: CategoryEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/category/:id",
        name: "CategoryEdit",
        components: {
            default: CategoryEdit,
            LeftSidebar: Sidebar
        }
    },
];