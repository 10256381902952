<template>
  <head-panel>
    <template v-slot:body>
      Промокоди клієнта <span v-if="client"> {{ client.uuid }}</span></template>
  </head-panel>

  <div class="wrapper ps-3 pe-3" v-if="client">

    <tab :client="client"/>

    <hr/>

    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-body">
            <h5 class="card-title">Промокоди користувача</h5>
            <table class="table">
              <thead>
              <tr>
                <th scope="col">Код</th>
                <th scope="col">Дисконт</th>
                <th scope="col">Термін діі</th>
                <th scope="col"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in client.promoCodes" :key="item">
                <td>{{ item.code.code }}</td>
                <td>{{ item.code.discount }}</td>
                <td>{{ $filters.date(item.code.expiredAt) }}</td>
                <td class="actions">
                  <router-link :to="'/marketing/promo-code/code/'+item.uuid + '/statistic'">Статистика</router-link>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-body">
            <h5 class="card-title">Використані</h5>
            <table class="table">
              <thead>
              <tr>
                <th scope="col">Код</th>
                <th scope="col">Дисконт</th>
                <th scope="col">Тип</th>
                <th scope="col">Дата активації</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in uses" :key="item">
                <td>{{item.code.code}}</td>
                <td>{{item.code.discount}}</td>
                <td>{{item.code.type}}</td>
                <td>{{ $filters.dateTime(item.createdAt) }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <Pagination
        :current-page="pagination.page"
        :total-pages="pagination.total"/>

  </div>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import Tab from "@/views/client/Tab.vue";
import Pagination from "@/components/Pagination.vue";

export default {
  name: "Edit",
  components: {
    HeadPanel, Tab, Pagination
  },
  created() {
    this.fetch();
    this.fetchUses();
  },
  data() {
    return {
      client: null,
      uses: [],
      pagination: {
        page: 0,
        total: 0
      },
    }
  },
  methods: {
    fetch: function () {
      Http
          .get(process.env.VUE_APP_CRM_SERVICE + "/client/" + this.$route.params.id)
          .then((res) => {
            this.client = res.content;
          });
    },
    fetchUses: function () {
      Http
          .get(process.env.VUE_APP_CRM_SERVICE + "/promo/code/usage/search?client=" + this.$route.params.id)
          .then((res) => {
            this.uses = res.content;
            this.pagination.page = res.origin.headers.get("X-Current-Page") * 1;
            this.pagination.total = res.origin.headers.get("X-Page-Count") * 1;
          });
    },

  }
};
</script>

<style scoped>


</style>