import Sidebar from '../components/Sidebar.vue'
import ClientList from "@/views/client/Index.vue";
import ClientView from "@/views/client/View.vue";
import ClientOrders from "@/views/client/Orders.vue";
import ClientPromoCodes from "@/views/client/PromoCodes.vue";

export default [

    {
        path: "/client",
        name: "Clients",
        components: {
            default: ClientList,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/client/:id",
        name: "ClientView",
        components: {
            default: ClientView,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/client/:id/order",
        name: "ClientOrders",
        components: {
            default: ClientOrders,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/client/:id/promo-code",
        name: "ClientPromoCodes",
        components: {
            default: ClientPromoCodes,
            LeftSidebar: Sidebar
        }
    }
];