<template>
  <head-panel>
    <template v-slot:body>
      Карта завантажень
      <router-link :to="config ? '/import/mapping/'+this.config.uuid: '/import/mapping'">
        <button class="btn btn-secondary btn-sm btn-create-top ms-3" role="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-arrow-left-short" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
          </svg>
          Повернутись до списку
        </button>
      </router-link>
      <a @click="removeConfig" class="btn btn-danger btn-sm btn-remove-config float-end" v-if="config">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash"
             viewBox="0 0 16 16">
          <path
              d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
          <path fill-rule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>
        Видалити налаштування
      </a>
    </template>
    <template v-slot:top>&nbsp;</template>
  </head-panel>

  <div class="wrapper">

    <Alert ref="alert"/>

    <form @submit.prevent="submit">

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Назва конфігурації</div>
        <div class="col-lg-8">
          <input type="text" class="form-control" v-model="form.name" :class="{ 'is-invalid' : v$.form.name.$error}">
          <div class="form-text">Назва конфігурації через яку буде проводитись завантаження. Наприклад: "Завантаження
            прайсу постачальника Pepsi-Co"
          </div>
          <div class="invalid-feedback" v-for="error of v$.form.name.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-6">
          <button type="submit" class="btn btn-primary">Зберегти</button>
        </div>
      </div>

    </form>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../../lib/Http";
import HeadPanel from "../../../components/HeadPanel";
import ConfirmDialogue from '../../../components/ConfirmDialog.vue'
import Alert from "../../../components/Alert";
import {useVuelidate} from "@vuelidate/core";
import {maxLength, required} from "@vuelidate/validators";

export default {
  name: "Edit",
  components: {
    HeadPanel, ConfirmDialogue, Alert
  },
  created() {
    if (this.$route.params.id)
      this.fetch(this.$route.params.id);
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        name: {
          required,
          maxLength: maxLength(255)
        }
      }
    };
  },
  data() {
    return {
      mappingError: null,
      config: null,
      form: {
        name: null,
      }
    }
  },
  methods: {
    fetch: function (uuid) {
      Http
          .get(process.env.VUE_APP_IMPORT_SERVICE + "/mapping/config/" + uuid)
          .then((res) => {
            this.config = res.content;
            this.form = res.content;
          });
    },
    submit: async function () {

      const result = await this.v$.$validate()

      if (!result) return

      let data = {
        name: this.form.name,
      };

      if (!this.config)
        Http
            .post(process.env.VUE_APP_IMPORT_SERVICE + "/mapping/config", data)
            .then((res) => {
              this.$router.replace("/import/config/" + res.content.uuid);
              this.fetch(res.content.uuid);
              this.$refs.alert.success('Успішно збережено');
            })
            .catch((e) => {
              this.$refs.alert.danger(e.content);
            });
      else
        Http
            .put(process.env.VUE_APP_IMPORT_SERVICE + "/mapping/config/" + this.config.uuid, data)
            .then((res) => {
              this.fetch(res.content.uuid);
              this.$refs.alert.success('Успішно збережено');
            })
            .catch((e) => {
              this.$refs.alert.danger(e.content);
            });
    },

    removeConfig: function () {

      this.$refs.confirmDialogue.show({
        title: 'Видалення',
        message: "Ви дійсно хочете видалити налаштування і всі дані пов'язані з ним1?",
        okButton: 'Так',
        cancelButton: 'Ні',
      }).then((ok) => {
        if (ok)
          Http
              .del(process.env.VUE_APP_IMPORT_SERVICE + "/mapping/config/" + this.config.uuid)
              .then(() => {
                this.$router.replace("/import/mapping");
              }).catch(e => {
                this.$refs.alert.danger(e.message);
              });
      });
    }
  }
};
</script>

<style scoped>

.btn-remove {
  color: red;
}

.btn-remove-config {
  margin-top: 1px;
  margin-left: 40px;
}

</style>