<template>
  <head-panel>
    <template v-slot:body>
      Джерело імпорту

      <router-link to="/import/source">
        <button class="btn btn-secondary btn-sm btn-create-top ms-3" role="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-arrow-left-short" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
          </svg>
          Повернутись до списку
        </button>
      </router-link>

      <a @click="remove" class="btn btn-danger btn-sm btn-remove float-end" v-if="source">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash"
             viewBox="0 0 16 16">
          <path
              d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
          <path fill-rule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>
        Видалити джерело
      </a>
    </template>
    <template v-slot:top>&nbsp;</template>
  </head-panel>

  <div class="wrapper">

    <Alert ref="alert"/>

    <form @submit.prevent="submit">

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Активний</div>
        <div class="col-lg-8">

          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="statid" v-model="form.status">
            <label class="form-check-label" for="statid"></label>
            <label class=" form-text" for="statid">Стан джерела </label>
          </div>
        </div>
      </div>


      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Назва</div>
        <div class="col-lg-8">
          <input type="text" class="form-control" v-model="form.name" :class="{ 'is-invalid' : v$.form.name.$error}">
          <div class="invalid-feedback" v-for="error of v$.form.name.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>


      <div class="row ms-2 mb-2">
        <div class="col-xl-3">Типи імпорту</div>
        <div class="col-lg-8">

          <div class="mb-3">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" v-model="form.type" name="type" id="type1"
                     value="MARKET_INDEX" :disabled="source">
              <label class="form-check-label" for="type1">Индекс</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" v-model="form.type" name="type" id="type2"
                     value="MARKET_INDEX_PRICE" :disabled="source">
              <label class="form-check-label" for="type2">Индекс залишки</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" v-model="form.type" name="type" id="type4" value="CSV_PRICE"
                     :disabled="source">
              <label class="form-check-label" for="type4">CSV залишки</label>
            </div>
          </div>

        </div>
      </div>


      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Імпорт з посилання</div>
        <div class="col-lg-8">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="scheduledid" v-model="form.scheduled">
            <label class=" form-text" for="scheduledid">Вказати посилання на джерело імпорту </label>
          </div>
        </div>
      </div>


      <div class="row ms-2 mb-4" v-if="form.scheduled">
        <div class="col-xl-3">Джерело імпорту</div>
        <div class="col-lg-8">
          <input type="text" class="form-control" v-model="form.url" :class="{ 'is-invalid' : v$.form.url.$error}">
          <div class="form-text">Посилання на файл джерела імпорту</div>
          <div class="invalid-feedback" v-for="error of v$.form.url.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <hr/>

      <edit-csv v-if="form.type === 'CSV_PRICE'" v-model="formCSV" ref="csv"/>
      <edit-index v-if="form.type === 'MARKET_INDEX'" v-model="formIndex" ref="index"/>
      <edit-index-price v-if="form.type === 'MARKET_INDEX_PRICE'" v-model="formIndexPrice" ref="indexPrice"/>

      <div class="row ms-2 mb-4">
        <div class="col-xl-6">
          <button type="submit" class="btn btn-primary">Зберегти</button>
        </div>
      </div>

    </form>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../../lib/Http";
import HeadPanel from "../../../components/HeadPanel";
import ConfirmDialogue from '../../../components/ConfirmDialog.vue'
import Alert from "../../../components/Alert";
import {useVuelidate} from "@vuelidate/core";
import {maxLength, required, requiredIf} from "@vuelidate/validators";
import EditCsv from "@/views/import/source/EditCsv.vue";
import EditIndex from "@/views/import/source/EditIndex.vue";
import EditIndexPrice from "@/views/import/source/EditIndexPrice.vue";

export default {
  name: "Edit",
  components: {
    HeadPanel, ConfirmDialogue, Alert, EditCsv, EditIndex, EditIndexPrice
  },
  created() {
    if (this.$route.params.id)
      this.fetch(this.$route.params.id);
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        name: {
          required,
          maxLength: maxLength(255)
        },
        url: {
          required: requiredIf(this.form.scheduled),
          maxLength: maxLength(255)
        }
      },
    };
  },
  data() {
    return {
      source: null,
      form: {
        type: 'MARKET_INDEX',
        name: null,
        status: null,
        scheduled: null,
        url: null,
      },
      formIndex: {
        types: []
      },
      formIndexPrice: {
        skuAutoImport: null,
        skuMapConfigUuid: null,
      },
      formCSV: {
        defaultCurrency: 'USD',
        priceRound: null,
        skuAutoImport: null,
        availableAutoDisable: null,
        skuMapConfigUuid: null,
        delimiter: ',',
        skipLines: 0,
        columns: ['amount'],
      }
    }
  },
  methods: {
    fetch: function (uuid) {
      Http
          .get(process.env.VUE_APP_IMPORT_SERVICE + "/source/" + uuid)
          .then((res) => {
            this.source = res.content;
            this.form.name = this.source.name;
            this.form.type = this.source.type;
            this.form.status = this.source.status === 'ACTIVE';
            this.form.scheduled = this.source.scheduled;
            this.form.url = this.source.url;

            this.formIndex.types = this.source.types ?? this.defaultTypes;

            this.formIndexPrice.skuMapConfigUuid = this.source.skuMapConfig?.uuid ?? null;
            this.formIndexPrice.skuAutoImport = this.source.skuAutoImport ?? false;
            this.formIndexPrice.availableAutoDisable = this.source.availableAutoDisable ?? false;

            this.formCSV.columns = this.source.columns ?? [];
            this.formCSV.skuMapConfigUuid = this.source.skuMapConfig?.uuid ?? null;
            this.formCSV.skuAutoImport = this.source.skuAutoImport ?? false;
            this.formCSV.availableAutoDisable = this.source.availableAutoDisable ?? false;
            this.formCSV.priceRound = this.source.priceRound ?? false;
            this.formCSV.skipLines = this.source.skipLines ?? false;
            this.formCSV.defaultCurrency = this.source.defaultCurrency ?? false;
            this.formCSV.delimiter = this.source.delimiter ?? false;

          });
    },
    submit: async function () {

      const result = await this.v$.$validate()

      if (this.form.type === 'CSV_PRICE' && !await this.$refs.csv.validate())
        return;

      if (this.form.type === 'MARKET_INDEX' && !await this.$refs.index.validate())
        return;

      if (this.form.type === 'MARKET_INDEX_PRICE' && !await this.$refs.indexPrice.validate())
        return;

      if (!result) return

      let data = {
        name: this.form.name,
        status: this.form.status ? 'ACTIVE' : 'DISABLE',
        type: this.form.type,
        scheduled: this.form.scheduled,
        url: this.form.url,
      };

      if (["MARKET_INDEX"].includes(this.form.type))
        data['index'] = this.formIndex

      if (["MARKET_INDEX_PRICE"].includes(this.form.type))
        data['indexPrice'] = this.formIndexPrice

      if (["CSV_PRICE"].includes(this.form.type))
        data['csv'] = this.formCSV

      if (!this.source)
        Http
            .post(process.env.VUE_APP_IMPORT_SERVICE + "/source", data)
            .then((res) => {
              this.$router.replace("/import/source/" + res.content.uuid);
              this.fetch(res.content.uuid);
              this.$refs.alert.success('Успішно збережено');
            })
            .catch((e) => {
              this.$refs.alert.danger(e.detail);
            });
      else
        Http
            .put(process.env.VUE_APP_IMPORT_SERVICE + "/source/" + this.source.uuid, data)
            .then((res) => {
              this.fetch(res.content.uuid);
              this.$refs.alert.success('Успішно збережено');
            })
            .catch((e) => {
              this.$refs.alert.danger(e.detail);
            });
    },

    remove: function () {
      this.$refs.confirmDialogue.show({
        title: 'Видалення',
        message: "Ви дійсно хочете видалити джерело і пов'язані з ним  дані?",
        okButton: 'Так',
        cancelButton: 'Ні',
      }).then((ok) => {
        if (ok)
          Http
              .del(process.env.VUE_APP_IMPORT_SERVICE + "/source/" + this.source.uuid)
              .then(() => {
                this.$router.replace("/import/source");
              });
      })
    }
  }
};
</script>

<style scoped>

.btn-remove {
  margin-top: 1px;
  margin-left: 40px;
}

</style>