<template>
  <head-panel>
    <template v-slot:body>
      Відгук <span v-if="review"> {{ review.uuid }}</span>


      <router-link :to=" '/review/manage'">
        <button class="btn btn-secondary btn-sm btn-create-top ms-3" role="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-arrow-left-short" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
          </svg>
          Повернутись до списку
        </button>
      </router-link>


      <a @click="remove" v-if="review"
         class="btn btn-danger btn-sm btn-remove float-end">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash"
             viewBox="0 0 16 16">
          <path
              d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
          <path fill-rule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>
        Видалити відгук
      </a>

    </template>
    <template v-slot:top>&nbsp;</template>
  </head-panel>

  <div class="wrapper edit">

    <Alert ref="alert"/>

    <form @submit.prevent="submit">

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">EID</div>
        <div class="col-lg-8">
          <input class="form-control" v-model="form.eid" :class="{ 'is-invalid' : v$.form.eid.$error}"/>
          <div class="invalid-feedback" v-for="error of v$.form.eid.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Статус модерації</div>
        <div class="col-lg-8">
          <select class="form-select" v-model="form.moderate" :class="{ 'is-invalid' : v$.form.moderate.$error}">
            <option selected value="null">-- вибрати --</option>
            <option v-for="item in moderateStatuses" :key="item" v-bind:value="item.value">{{ item.label }}</option>
          </select>
          <div class="invalid-feedback" v-for="error of v$.form.moderate.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>


      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Група</div>
        <div class="col-lg-8">
          <select class="form-select" v-model="form.subject" :class="{ 'is-invalid' : v$.form.subject.$error}">
            <option selected value="null">-- вибрати --</option>
            <option v-for="item in subjects" :key="item" v-bind:value="item.value">{{ item.label }}</option>
          </select>
          <div class="invalid-feedback" v-for="error of v$.form.subject.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Автор</div>
        <div class="col-lg-8">
          <Multiselect
              v-model="form.author"
              :options="authors"
              :class="{ 'is-invalid' : v$.form.author.uuid.$error}"
              :multiple="false"
              :searchable="true"
              @search-change="findAuthor"
              placeholder="Пошук автора за ім`ям чи eid"
              selectLabel=""
              label="label"
              track-by="aid"
          >
            <template v-slot:option="{ option }">
              <span class="option__name">{{ option.label }}</span>
              <span class="option__path"> ({{ option.aid }})</span>
            </template>
            <template #noOptions>
              Пошук авторів...
            </template>
            <template #noResult>
              Авторів не знайдено!
            </template>
          </Multiselect>
          <div class="invalid-feedback" v-for="error of v$.form.author.uuid.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Ім'я(Не обов'язково)</div>
        <div class="col-lg-8">
          <input class="form-control" v-model="form.name" :class="{ 'is-invalid' : v$.form.name.$error}"/>
          <div class="invalid-feedback" v-for="error of v$.form.name.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>


      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Рейтин</div>
        <div class="col-lg-8">
          <select class="form-select" v-model="form.rate" :class="{ 'is-invalid' : v$.form.rate.$error}">
            <option selected value="null">-- вибрати --</option>
            <option v-for="item in rates" :key="item" v-bind:value="item.value">{{ item.label }}</option>
          </select>
          <div class="invalid-feedback" v-for="error of v$.form.rate.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Відгук</div>
        <div class="col-lg-8">
          <textarea class="form-control" v-model="form.comment" :class="{ 'is-invalid' : v$.form.comment.$error}"/>
          <div class="invalid-feedback" v-for="error of v$.form.comment.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>


      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Позитив</div>
        <div class="col-lg-8">
          <textarea class="form-control" v-model="form.advantages"
                    :class="{ 'is-invalid' : v$.form.advantages.$error}"/>
          <div class="invalid-feedback" v-for="error of v$.form.advantages.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>


      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Негатив</div>
        <div class="col-lg-8">
          <textarea class="form-control" v-model="form.disadvantages"
                    :class="{ 'is-invalid' : v$.form.disadvantages.$error}"/>
          <div class="invalid-feedback" v-for="error of v$.form.disadvantages.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>


      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Дата публікації</div>
        <div class="col-lg-8">
          <VueDatePicker v-model="form.publishedAt" @update:model-value="form.publishedAt"  :enable-time-picker="false" format="dd/MM/yyyy"></VueDatePicker>
        </div>
      </div>



      <div class="row ms-2 mb-4">
        <div class="col-xl-6">
          <button type="submit" class="btn btn-primary">Зберегти</button>
        </div>
      </div>

    </form>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../../lib/Http";
import HeadPanel from "../../../components/HeadPanel.vue";
import ConfirmDialogue from '../../../components/ConfirmDialog.vue'
import Alert from "../../../components/Alert.vue";
import {useVuelidate} from "@vuelidate/core";
import { maxLength, minLength, required} from "@vuelidate/validators";
import Multiselect from "vue-multiselect";
import {debounce} from "@/lib/Util";
import VueDatePicker from "@vuepic/vue-datepicker";


export default {
  name: "Edit",
  components: {
    VueDatePicker,
    Multiselect,
    HeadPanel, ConfirmDialogue, Alert
  },
  created() {
    this.fetchSubjects().then(() => {
      if (this.$route.params.id)
        this.fetch(this.$route.params.id);
      this.findAuthor('');
    });
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        eid: {
          required,
          maxLength: maxLength(50)
        },
        author: {
          uuid: {
            required
          }
        },
        name: {
          minLength: minLength(3),
          maxLength: maxLength(255)
        },
        subject: {
          required
        },
        comment: {
          required,
          maxLength: maxLength(5000)
        },
        advantages: {
          maxLength: maxLength(5000)
        },
        disadvantages: {
          maxLength: maxLength(5000)
        },
        moderate: {
          required,
        },
        rate: {
        }
      }
    };
  },
  computed: {
    moderateStatuses: function () {
      return [
        {'label': 'APPROVE', 'value': 'APPROVE'},
        {'label': 'AUTO_APPROVE', 'value': 'AUTO_APPROVE'},
        {'label': 'NEED_MODERATE', 'value': 'NEED_MODERATE'},
      ];
    },
    rates: function () {
      return [
        {'label': '1', 'value': 1},
        {'label': '2', 'value': 2},
        {'label': '3', 'value': 3},
        {'label': '4', 'value': 4},
        {'label': '5', 'value': 5},
      ];
    }
  },
  data() {
    return {
      review: null,
      authors: [],
      subjects: [],
      form: {
        eid: null,
        author: null,
        name: null,
        subject: null,
        comment: null,
        advantages: null,
        disadvantages: null,
        rate: null,
        moderate: 'APPROVE',
        publishedAt: new Date()
      }
    }
  },
  methods: {
    fetch: function (uuid) {
      Http
          .get(process.env.VUE_APP_REVIEW_SERVICE + "/review/" + uuid)
          .then((res) => {
            this.review = res.content;
            this.form = {
              eid: res.content.eid,
              author: {
                aid: res.content.author.aid,
                label: res.content.author.name,
                uuid: res.content.author.uuid
              },
              name: res.content.name,
              subject: res.content.subject.uuid,
              comment: res.content.comment,
              advantages: res.content.advantages,
              disadvantages: res.content.disadvantages,
              rate: res.content.rate,
              moderate: res.content.moderate,
              publishedAt: res.content.publishedAt
            };
          });
    },

    fetchSubjects: function () {
      return Http
          .get(process.env.VUE_APP_REVIEW_SERVICE + "/subject")
          .then((res) => {
            this.subjects = res.content.map(e => {
              return {'label': e.name, 'value': e.uuid}
            });
            this.form.subject = this.subjects && this.subjects.length ? this.subjects[0].value : null;
          });
    },

    findAuthor(query) {
      debounce(() => {
        Http
            .get(process.env.VUE_APP_REVIEW_SERVICE + "/author?search=" + query.toLowerCase())
            .then((res) => {
              this.authors = res.content.map(e => {
                return {
                  aid: e.aid,
                  label: e.name,
                  uuid: e.uuid
                }
              });
            });
      }, 300)();
    },

    submit: async function () {

      const result = await this.v$.$validate()

      if (!result) return

      let data = {
        eid: this.form.eid,
        subject: this.form.subject,
        author: this.form.author.uuid,
        name: this.form.name,
        comment: this.form.comment,
        advantages: this.form.advantages,
        disadvantages: this.form.disadvantages,
        rate: this.form.rate,
        moderate: this.form.moderate,
        publishedAt: this.form.publishedAt,
        data: []
      };

      if (!this.review)
        Http
            .post(process.env.VUE_APP_REVIEW_SERVICE + "/review", data)
            .then((res) => {
              this.review = res.content;
              this.$refs.alert.success('Успішно збережено');
            });
      else
        Http
            .put(process.env.VUE_APP_REVIEW_SERVICE + "/review/" + this.review.uuid, data)
            .then((res) => {
              this.review = res.content;
              this.$refs.alert.success('Успішно збережено');
            });
    },

    remove: function () {
      this.$refs.confirmDialogue.show({
        title: 'Видалення',
        message: 'Ви дійсно хочете видалити відгук?',
        okButton: 'Так',
        cancelButton: 'Ні',
      }).then((ok) => {
        if (ok)
          Http
              .del(process.env.VUE_APP_REVIEW_SERVICE + "/review/" + this.review.uuid)
              .then(() => {
                this.$router.replace("/review/manage");
              });
      })
    }
  }
};
</script>

<style scoped>
.form-body {
  height: 400px;
}
</style>
