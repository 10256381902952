import Sidebar from '../components/Sidebar.vue'
import Index from "../views/content/Index"
import Edit from "../views/content/Edit"

export default [
    {
        path: "/content/:tag",
        name: "Page",
        components: {
            default: Index,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/content/:tag/create",
        name: "ContentCreate",
        components: {
            default: Edit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/content/:tag/:id",
        name: "ContentEdit",
        components: {
            default: Edit,
            LeftSidebar: Sidebar
        }
    }
];