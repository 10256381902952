<template>
  <div></div>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";

export default {
  name: "EditIndexPriceMeta",
  props: {
    modelValue: Object,
  },
  created() {
  },
  watch: {
    form: {
      handler(val) {
        this.$emit('update:modelValue', val)
      },
      deep: true
    }
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
      }
    };
  },
  data() {
    return {
      mappingError: null,
      skuMaps: [],
      form: this.modelValue
    }
  },
  methods: {
    validate: async function () {
      return await this.v$.$validate();
    }
  }
};
</script>

<style scoped>

.btn-remove {
  color: red;
}

</style>