<template>

  <form @submit.prevent="submit">

    <table class="table">
      <thead>
      <tr>
        <th>SKU</th>
        <th>Ціна</th>
        <th>Стара ціна</th>
        <th>Кешбек</th>
        <th>Кількість</th>
        <th>Валюта</th>
        <th>Координати</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>
          <input type="text" class="form-control" v-model="form.sku" :class="{ 'is-invalid' : v$.form.sku.$error}">
          <div class="invalid-feedback" v-for="error of v$.form.sku.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </td>
        <td>
          <input type="text" class="form-control" v-model="form.amount"
                 :class="{ 'is-invalid' : v$.form.amount.$error}">
          <div class="invalid-feedback" v-for="error of v$.form.amount.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </td>
        <td>
          <input type="text" class="form-control" v-model="form.oldAmount"
                 :class="{ 'is-invalid' : v$.form.oldAmount.$error}">
          <div class="invalid-feedback" v-for="error of v$.form.oldAmount.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </td>
        <td>
          <input type="text" class="form-control" v-model="form.cashbackAmount"
                 :class="{ 'is-invalid' : v$.form.cashbackAmount.$error}">
          <div class="invalid-feedback" v-for="error of v$.form.cashbackAmount.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </td>
        <td>
          <input type="text" class="form-control" v-model="form.quantity"
                 :class="{ 'is-invalid' : v$.form.quantity.$error}">
          <div class="invalid-feedback" v-for="error of v$.form.quantity.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </td>
        <td>
          <select class="form-select" v-model="form.currency" :class="{ 'is-invalid' : v$.form.currency.$error}" disabled>
            <option v-for="item in currencies" :key="item" v-bind:value="item">{{ item }}</option>
          </select>
          <div class="invalid-feedback" v-for="error of v$.form.currency.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </td>
        <td>
          <div class="d-flex">
            <input type="text" class="form-control latlng" v-model="form.lat"
                   :class="{ 'is-invalid' : v$.form.lat.$error}" disabled>
            <span class="mt-2 ps-2 pe-2"> - </span>
            <input type="text" class="form-control latlng" v-model="form.lng"
                   :class="{ 'is-invalid' : v$.form.lng.$error}" disabled>
          </div>
        </td>
        <td class="actions">
          <a @click="add" class="text-primary mt-2 d-block" title="Додати">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
              <path
                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
            </svg>
          </a>
        </td>
      </tr>
      <tr>
        <th colspan="8">
          <button type="submit" class="btn btn-sm btn-primary float-start me-3" @click.prevent="submit"
                  :disabled="!isUpdateAvailable">
            Зберегти ціни
          </button>

          <div v-for="message of updateMessages" :key="message" :class="'mt-1 '+message.type">
            {{ message.message }}
          </div>

        </th>
      </tr>
      <tr v-for="(price, index) in prices" :key="price" :class="'state-'+state(price)">
        <td>
          <input type="text" class="form-control" v-model="price.sku" disabled
                 :class="{ 'is-invalid' : v$.prices.$each.$response.$errors[index].sku.length}">
          <div class="invalid-feedback" v-for="error of v$.prices.$each.$response.$errors[index].sku" :key="error.$uid">
            {{ error.$message }}
          </div>
        </td>
        <td>
          <input type="text" class="form-control" v-model="price.amount" @change="isUpdateAvailable=true"
                 :class="{ 'is-invalid' : v$.prices.$each.$response.$errors[index].amount.length}">
          <div class="invalid-feedback" v-for="error of v$.prices.$each.$response.$errors[index].amount"
               :key="error.$uid">
            {{ error.$message }}
          </div>
        </td>
        <td>
          <input type="text" class="form-control" v-model="price.oldAmount" @change="isUpdateAvailable=true"
                 :class="{ 'is-invalid' : v$.prices.$each.$response.$errors[index].oldAmount.length}">
          <div class="invalid-feedback" v-for="error of v$.prices.$each.$response.$errors[index].oldAmount"
               :key="error.$uid">
            {{ error.$message }}
          </div>
        </td>
        <td>
          <input type="text" class="form-control" v-model="price.cashbackAmount" @change="isUpdateAvailable=true"
                 :class="{ 'is-invalid' : v$.prices.$each.$response.$errors[index].cashbackAmount.length}">
          <div class="invalid-feedback" v-for="error of v$.prices.$each.$response.$errors[index].cashbackAmount"
               :key="error.$uid">
            {{ error.$message }}
          </div>
        </td>
        <td>
          <input type="text" class="form-control" v-model="price.quantity" @change="isUpdateAvailable=true"
                 :class="{ 'is-invalid' : v$.prices.$each.$response.$errors[index].quantity.length}">
          <div class="invalid-feedback" v-for="error of v$.prices.$each.$response.$errors[index].quantity"
               :key="error.$uid">
            {{ error.$message }}
          </div>
        </td>
        <td>
          <select class="form-select" v-model="price.currency" @change="isUpdateAvailable=true" disabled
                  :class="{ 'is-invalid' : v$.prices.$each.$response.$errors[index].currency.length}">
            <option v-for="item in currencies" :key="item" v-bind:value="item">{{ item }}</option>
          </select>
          <div class="invalid-feedback" v-for="error of v$.prices.$each.$response.$errors[index].currency"
               :key="error.$uid">
            {{ error.$message }}
          </div>
        </td>
        <td>
          <div class="d-flex">
            <input type="text" class="form-control latlng" v-model="price.lat" @change="isUpdateAvailable=true"
                   :class="{ 'is-invalid' : v$.prices.$each.$response.$errors[index].lat.length}" disabled>
            <span class="mt-2 ps-2 pe-2"> - </span>
            <input type="text" class="form-control latlng" v-model="price.lng" @change="isUpdateAvailable=true"
                   :class="{ 'is-invalid' : v$.prices.$each.$response.$errors[index].lng.length}" disabled>
          </div>
        </td>
        <td class="actions">

          <a @click="remove(price)" class="text-danger mt-2 d-block" title="Видалити">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3"
                 viewBox="0 0 16 16">
              <path
                  d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
            </svg>
          </a>

        </td>
      </tr>
      </tbody>
    </table>

  </form>

</template>

<script>
import Http from "../../lib/Http";
import {useVuelidate} from '@vuelidate/core'
import {maxLength, helpers, integer, minLength, decimal, required} from '@vuelidate/validators'

const STATE_REMOVE = 'remove';
const STATE_ADD = 'add';

export default {
  name: "EditPriceTab",
  props: {
    product: Object,
  },
  created() {
    if (this.product && this.product.price)
      for (let item of this.product.price.data)
        this.prices.push({
          sku: item.sku,
          amount: item.amount,
          oldAmount: item.oldAmount,
          cashbackAmount: item.cashbackAmount,
          quantity: item.quantity,
          currency: item.currency,
          lat: item.lat,
          lng: item.lng,
        });
  },
  computed: {
    currencies() {
      return ['UAH'];
    },
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        sku: {
          required,
          maxLength: maxLength(40),
          exist: helpers.withMessage('Даннй SKU вже доданий', (v) => {
            return !this.prices.find(e => e.sku === v.trim());
          })
        },
        amount: {
          required,
          integer
        },
        oldAmount: {integer},
        cashbackAmount: {integer},
        quantity: {
          required: required,
          integer
        },
        currency: {
          minLength: minLength(3),
          maxLength: maxLength(3),
        },
        lat: {decimal},
        lng: {decimal}
      },
      prices: {
        $each: helpers.forEach({
          sku: {
            maxLength: maxLength(40),
          }
        })
      }
    };
  },
  data() {
    return {
      isUpdateAvailable: false,
      updateMessages: [],
      states: new Map(),
      form: {
        sku: null,
        amount: null,
        oldAmount: null,
        cashbackAmount: null,
        quantity: null,
        currency: 'UAH',
        lat: null,
        lng: null
      },
      prices: []
    }
  },
  methods: {
    submit: function () {
      this.isUpdateAvailable = false;

      const price = {
        sku: this.product.sku,
        data: this.prices.map(e => this.filter(e)).filter(e => {
          const action = this.states.get(e.sku);
          return action !== STATE_REMOVE;
        })
      }

      Http
          .put(process.env.VUE_APP_CATALOG_SERVICE + "/manage/product/" + this.product.sku + "/price", price)
          .then(() => {

            this.prices = price.data;
            this.states.clear();

            this.updateMessages.push({
              type: "text-success",
              message: "Успішно збережено"
            });
            setTimeout(() => this.updateMessages = [], 2500);
          })
          .catch(() => {
            this.updateMessages.push({
              type: "text-danger",
              message: "Помилка збереження"
            });

            setTimeout(() => this.updateMessages = [], 2500);

          });
    },
    add: async function () {
      const result = await this.v$.form.$validate()

      if (!result) return;

      const item = this.filter({
        sku: this.form.sku,
        amount: this.form.amount,
        oldAmount: this.form.oldAmount,
        cashbackAmount: this.form.cashbackAmount,
        quantity: this.form.quantity,
        currency: this.form.currency,
        lat: this.form.lat,
        lng: this.form.lng,
      });

      this.prices.unshift(item);

      this.form = {
        sku: null,
        amount: null,
        oldAmount: null,
        cashbackAmount: null,
        quantity: null,
        currency: 'UAH',
        lat: null,
        lng: null
      };

      this.v$.form.$reset();
      this.isUpdateAvailable = true;
      this.states.set(item.sku, STATE_ADD);
    },
    remove: function (e) {
      const item = this.filter(e);

      const state = this.states.get(item.sku);

      if (state && state === STATE_REMOVE)
        this.states.delete(item.sku);
      else if (state && state === STATE_ADD){
        this.prices = this.prices.filter(e => e.sku !== item.sku);
        this.states.delete(item.sku);
      }
      else
        this.states.set(item.sku, STATE_REMOVE);

      this.isUpdateAvailable = true;
    },
    state: function (e) {
      return this.states.get(e.sku);
    },
    filter: function (e) {
      e.sku = e.sku.trim();
      return e;
    }
  }
};
</script>

<style scoped>
.latlng {
  max-width: 200px;
}

.state-remove {
  background-color: #f5c5c6;
}

.state-add {
  background-color: #c9fcd0;
}
</style>