<template>
  <div class="add-block">
    <a @click="add('top')" class="add mb-3  d-block cursor-pointer">
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-plus-circle-fill"
           viewBox="0 0 16 16">
        <path
            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
      </svg>
      Додати параметр
    </a>
  </div>
  <div class="row mb-2" v-for="(property, index) in properties" :key="property">
    <div class="col-3">
      <input type="text" class="form-control" v-model="property.group" @change="changed" placeholder="Група параметра"
             :class="{ 'is-invalid' : v$.properties.$each.$response.$errors[index].group.length}"
      >
      <div class="invalid-feedback" v-for="error of v$.properties.$each.$response.$errors[index].group" :key="error.$uid">
        {{ error.$message }}
      </div>
    </div>
    <div class="col-3">
      <input type="text" class="form-control" v-model="property.name" @change="changed" placeholder="Назва параметра"
             :class="{ 'is-invalid' : v$.properties.$each.$response.$errors[index].name.length}"
      >
      <div class="invalid-feedback" v-for="error of v$.properties.$each.$response.$errors[index].name" :key="error.$uid">
        {{ error.$message }}
      </div>
    </div>
    <div class="col-4">
      <input type="text" class="form-control" v-model="property.value" @change="changed"
             placeholder="Значення параметра"  :class="{ 'is-invalid' : v$.properties.$each.$response.$errors[index].value.length}">
      <div class="invalid-feedback" v-for="error of v$.properties.$each.$response.$errors[index].value" :key="error.$uid">
        {{ error.$message }}
      </div>
    </div>
    <div class="col-1">
      <select class="form-select" v-model="property.unit" @change="changed"  :class="{ 'is-invalid' : v$.properties.$each.$response.$errors[index].unit.length}">
        <option value="" selected>?</option>
        <option v-for="unit in units" :key="unit" :value="unit.name">
          {{ unit.label }}
        </option>
      </select>
      <div class="invalid-feedback" v-for="error of v$.properties.$each.$response.$errors[index].unit" :key="error.$uid">
        {{ error.$message }}
      </div>
    </div>
    <div class="col-1">
      <a @click="remove(index)" class="remove mt-1 d-block cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash"
             viewBox="0 0 16 16">
          <path
              d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
          <path fill-rule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>
      </a>
    </div>
  </div>
  <div class="add-block" v-if="properties.length">
    <a @click="add('bottom')" class="add mt-2 d-block cursor-pointer">
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-plus-circle-fill"
           viewBox="0 0 16 16">
        <path
            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
      </svg>
      Додати параметр
    </a>
  </div>


</template>

<script>

import {useVuelidate} from "@vuelidate/core";
import {helpers, maxLength} from "@vuelidate/validators";

export default {
  name: "Locale",
  props: {
    modelValue: Object
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      properties: {
        $each: helpers.forEach({
          group: {
            maxLength: maxLength(255),
          },
          name: {
            maxLength: maxLength(255),
          },
          value: {
            maxLength: maxLength(255),
          },
          unit: {
            maxLength: maxLength(20),
          }
        })
      }
    };
  },
  watch: {
    modelValue(n) {
     this.properties = n;
    }
  },
  data() {
    return {
      properties: [],
      units: [
        {'name': 'cm', 'label': 'см'},
        {'name': 'quantity', 'label': 'шт'}
      ]
    }
  },
  methods: {
    changed: function () {
      this.$emit('update:modelValue', this.properties)
    },
    add: function (pos) {

      const fromProperty = pos === 'top' ? this.properties[0] : this.properties[this.properties.length-1];

      const property = {
        group: fromProperty ? fromProperty.group : '',
        name: "",
        unit: "",
        value: ""
      };

      if (pos === 'top')
        this.properties.unshift(property);
      else
        this.properties.push(property);

    },
    remove: function (index) {

      if (this.properties[index])
        this.properties.splice(index, 1);

    }
  }
}
</script>

<style scoped>

.add-block {
  display: block;
  overflow: hidden;
  text-align: center;
}

.add {
  color: #000;
  text-decoration: none;
  font-weight: bold;
  padding: 6px 10px;
  border-radius: 7px;
}

.add:hover {
  background-color: #f5f5f5;
}

.add svg {
  color: #248AFDFF;
  text-decoration: none;
  margin-right: 10px;
}

.remove {
  color: red;
}

</style>