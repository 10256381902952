<template>

  <head-panel>
    <template v-slot:body>Статистика імпорту

      <router-link :to="'/import/source'">
        <button class="btn btn-secondary btn-sm btn-create-top ms-3" role="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-arrow-left-short" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
          </svg>
          Повернутись до списку
        </button>
      </router-link>

    </template>
  </head-panel>

  <div class="wrapper">

    <tab :full="true"/>

    <hr/>

    <table class="table">
      <thead>
      <tr>
        <th>Дія</th>
        <th>Статус</th>
        <th>Запуск</th>
        <th>Час виконання</th>
        <th>Зчитано документів</th>
        <th>Помилок завантаження</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in schedules" :key="item.id">
        <td>{{ item.step }}</td>
        <td>
          <span class="status" v-bind:class="'status-'+item.status.toLowerCase()">
            {{ $filters.status(item.status) }}
          </span>
        </td>
        <td>{{ dateTime(item.runAt) }} ({{ item.runType }})</td>
        <td>{{ item.executionTime }} sec</td>
        <td>{{ item.readCount }}</td>
        <td>{{ item.errorCount }}</td>
      </tr>
      </tbody>
    </table>

  </div>

</template>

<script>

import Http from "../../../lib/Http";
import moment from 'moment';
import HeadPanel from "../../../components/HeadPanel";
import Tab from "../Tab";


export default {
  name: "ImportLog",
  components: {
    HeadPanel, Tab
  },
  created() {
    this.autoUpdate();
    this.fetch();
  },
  beforeUnmount() {
    clearInterval(this.autoUpdateTimer);
  },
  data() {
    return {
      autoUpdateTimer: null,
      schedules: [],
      autoUpdateSec: 30
    }
  },
  methods: {
    fetch: function () {
      Http.get(process.env.VUE_APP_IMPORT_SERVICE + '/source/schedule/log/' + this.$route.params.id)
          .then(res => this.schedules = this._mapResult(res.content));
    },
    autoUpdate: function () {
      clearInterval(this.autoUpdateTimer);
      this.autoUpdateTimer = setInterval(() => this.fetch(), this.autoUpdateSec * 1000);
    },
    dateTime(value) {
      return moment(value).format('YYYY-MM-DD HH:mm');
    },
    _mapResult: function (data) {
      return data
          .map((item) => {
            let startTime = new Date(item.createdAt);
            let endTime = new Date(item.updatedAt);
            let difference = endTime.getTime() - startTime.getTime();
            item.step = this._mapStep(item);
            item.executionTime = Math.round(difference / 1000);
            item.errorCount = item.readCount - item.writeCount;
            item.readCount = item.readCount ?? 0;
            return item;
          });
    },
    _mapStep: function (item) {
      const steps = {
        'categoryLoadStep' : 'Завантаження категорій',
        'brandLoadStep' : 'Завантаження брендів',
        'tagLoadStep' : 'Завантаження тегів',
        'menuLoadStep' : 'Завантаження меню',
        'productLoadStep' : 'Завантаження продуктів',
      }
      return steps[item.step]??'';
    }
  },
}
</script>

<style scoped>

</style>