<template>


  <div class="row ms-2 mb-4">
    <div class="col-xl-2" v-if="withLabel">Title</div>
    <div :class="withLabel ? 'col-lg-8': 'col-lg-12'">
      <div class="row mb-2" v-for="(item, index) in title" :key="item">
        <div class="col-12">
          <input type="text" class="form-control" v-model="item.value" @change="changed" :placeholder="withPlaceholder ? 'Title...' : ''"
                 :class="{ 'is-invalid' : v$.title.$each.$response.$errors[index].value.length}">
          <div class="invalid-feedback" v-for="error of v$.title.$each.$response.$errors[index].value"
               :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="row ms-2 mb-4">
    <div class="col-xl-2" v-if="withLabel">Keywords</div>
    <div :class="withLabel ? 'col-lg-8': 'col-lg-12'">
      <div class="row mb-2" v-for="(item, index) in keywords" :key="item">
        <div class="col-12">
          <input type="text" class="form-control" v-model="item.value" @change="changed" :placeholder="withPlaceholder ? 'Keywords...' : ''"
                 :class="{ 'is-invalid' : v$.keywords.$each.$response.$errors[index].value.length}">
          <div class="invalid-feedback" v-for="error of v$.keywords.$each.$response.$errors[index].value"
               :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="row ms-2 mb-4">
    <div class="col-xl-2" v-if="withLabel">Description</div>
    <div :class="withLabel ? 'col-lg-8': 'col-lg-12'">
      <div class="row mb-2" v-for="(item, index) in description" :key="item">
        <div class="col-12">
          <input type="text" class="form-control" v-model="item.value" @change="changed" :placeholder="withPlaceholder ? 'Description...' : ''"
                 :class="{ 'is-invalid' : v$.description.$each.$response.$errors[index].value.length}">
          <div class="invalid-feedback" v-for="error of v$.description.$each.$response.$errors[index].value"
               :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>

import {useVuelidate} from "@vuelidate/core";
import {maxLength, helpers} from "@vuelidate/validators";

export default {
  name: "Locale",
  props: {
    modelValue: Object,
    lang: Array,
    withLabel: Boolean,
    withPlaceholder: Boolean
  },
  mounted() {
    this.title = this.createLocales("title");
    this.keywords = this.createLocales("keywords");
    this.description = this.createLocales("description");
  },
  watch: {
    modelValue() {
      this.title = this.createLocales("title");
      this.keywords = this.createLocales("keywords");
      this.description = this.createLocales("description");
    },
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      title: {
        $each: helpers.forEach({
          value: {
            maxLength: maxLength(255),
          }
        })
      },
      keywords: {
        $each: helpers.forEach({
          value: {
            maxLength: maxLength(255),
          }
        })
      },
      description: {
        $each: helpers.forEach({
          value: {
            maxLength: maxLength(255),
          }
        })
      }
    };
  },
  computed: {
    languages: function () {
      if (this.lang && this.lang.length)

        return this.lang.map(lng => {
          return {'name': lng, 'label': lng.toUpperCase()};
        })

      return [
        {'name': 'default', 'label': 'Default'}
      ];
    },
  },
  data() {
    return {
      title: [],
      keywords: [],
      description: []
    }
  },
  methods: {
    changed: async function () {

      let meta = {
        title: {},
        keywords: {},
        description: {},
      };

      for (let locale of this.title)
        if (locale.value)
          meta.title[locale.lang] = locale.value;

      for (let locale of this.keywords)
        if (locale.value)
          meta.keywords[locale.lang] = locale.value;

      for (let locale of this.description)
        if (locale.value)
          meta.description[locale.lang] = locale.value;

      this.$emit('update:modelValue', meta);

    },
    createLocales: function (type) {

      let locales = [];
      for (let language of this.languages) {
        if (this.modelValue && this.modelValue[type] && this.modelValue[type][language.name])
          locales[locales.length] = {lang: language.name, value: this.modelValue[type][language.name]};
        else
          locales[locales.length] = {lang: language.name, value: ''};
      }
      return locales;
    }
  }
}
</script>

<style scoped>

</style>