import {createApp} from 'vue'
import App from './App.vue'
import Router from './router/Index'
import VueCookies from 'vue-cookies'
import configFormatter from './lib/Formatter'
import configCharts from './config/Charts'
import configGlobalMethods from './config/GlobalMethods'
import Http from "./lib/Http";
import OAuth from './lib/OAuth2';


const oauth = new OAuth(process.env.VUE_APP_OAUTH_URL, process.env.VUE_APP_OAUTH_CLIENT_ID, process.env.VUE_APP_OAUTH_REDIRECT_URL);

if (location.pathname === "/" && Http.getQueryParameter('code')) {
    oauth.requestToken();
    location.href = "/";
} else {

    const uuid = Http.getQueryParameter('muid');

    if (uuid)
        window.history.replaceState({}, "", '/');

    Http.session.market = {
        uuid: "30f83d3c-592b-4c1f-a605-33246e2e56ac"
    }

    Http
        .get(process.env.VUE_APP_CRM_SERVICE + "/session" + (uuid ? '?market=' + uuid : ''))
        .then(res => {
            Http.session.market = res.content.market;
            Http.session.available = res.content.available;

            if (!Http.session.market && Http.session.available.length === 1)
                Http.session.market = Http.session.available[0];

            return res.content;
        })
        .then(async () => {

            if (!Http.session.market)
                return;

            await integrateMarket(Http.session);
            await integrateHelpdesk(Http.session);
            await integrateReview(Http.session);
            await integrateContent(Http.session);
            await integrateImport(Http.session);
        })
        .then(() => createAppMethod());
}

async function integrateMarket(session) {

    if (session.market.owner)
        return;

    if (oauth.getToken().getPermissions().length)
        return;

    oauth.RPT(oauth.getToken().accessToken, session.market.uuid, false);
}

async function integrateHelpdesk(session) {
    return Http
        .get(process.env.VUE_APP_HELP_SERVICE + "/integration/market:" + session.market.uuid + ":project")
        .then(res => {
            Http.session.integrations.helpdesk = {
                status: res.content.status,
                uuid: res.content.uuid,
            }
        }).catch(() => {
        });
}

async function integrateImport(session) {
    return Http
        .get(process.env.VUE_APP_IMPORT_SERVICE + '/integration/' + session.market.uuid + "/status")
        .then(res => {
            Http.session.integrations.import = {
                status: res.content
            }
        })
        .catch(() => {
        });
}

async function integrateContent(session) {
    return Http
        .get(process.env.VUE_APP_CONTENT_SERVICE + "/integration/market:" + session.market.uuid + ":project")
        .then(res => {
            Http.session.integrations.content = {
                status: res.content.status,
                uuid: res.content.uuid,
            }
        })
        .catch(() => {
        });
}

async function integrateReview(session) {
    return Http
        .get(process.env.VUE_APP_REVIEW_SERVICE + "/integration/market:" + session.market.uuid + ":project")
        .then(res => {
            Http.session.integrations.review = {
                status: res.content.status,
                uuid: res.content.uuid,
            }
        })
        .catch(() => {
        });
}

function createAppMethod() {

    const app = createApp(App);

    app.config.globalProperties.$session = Http.session;
    app.config.globalProperties.$session.can = function (...needed) {
        if (this.market && this.market.owner)
            return true;
        return oauth.getToken().can(...needed);
    }

    app.config.globalProperties.$session.has = function (name) {
        return this.integrations[name] && this.integrations[name].status === 'ACTIVE';
    }

    app.use(VueCookies, {expire: '1d'})
    app.use(Router).mount('#app')
    configFormatter(app);
    configCharts(app);
    configGlobalMethods(app);
}
