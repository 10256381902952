<template>
  <head-panel>
    <template v-slot:body>Словники</template>
  </head-panel>

  <div class="wrapper">

    <div class="filter">
      <div class="item ">
        <SearchFilter
            label="Швидкий пошук"
            name="search"
            path="vocabulary"
        />
      </div>
    </div>

    <table class="table">
      <thead>
      <tr>
        <th scope="col">Назва</th>
        <th scope="col">Створений</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in items" :key="item">
        <td>{{ item.name }}</td>
        <td>{{ $filters.dateTime(item.createdAt) }}</td>
        <td class="actions">
          <router-link :to="'/vocabulary/'+item.uuid+'/item'">Список</router-link>
          <router-link :to="'/vocabulary/'+item.uuid" v-if="item.isEditable">Ред.</router-link>
          <a @click="remove(item)" v-if="item.isDeletable">Видалити</a>
        </td>
      </tr>
      </tbody>
    </table>

    <Pagination
        :current-page="pagination.page"
        :total-pages="pagination.total"/>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import Pagination from "../../components/Pagination";
import SearchFilter from "../../components/SearchFilter";
import ConfirmDialogue from "../../components/ConfirmDialog";

export default {
  name: "Index",
  components: {
    HeadPanel, Pagination, SearchFilter, ConfirmDialogue
  },
  created() {
    this.fetch();
  },
  watch: {
    $route(n, o) {
      if (n.path === o.path)
        this.fetch();
    }
  },
  data() {
    return {
      items: [],
      pagination: {
        page: 0,
        total: 0
      },
    }
  },
  methods: {
    fetch: function () {
      Http
          .get(process.env.VUE_APP_CRM_SERVICE + this.$route.fullPath)
          .then((res) => {
            this.items = res.content;
            this.pagination.page = res.origin.headers.get("X-Current-Page") * 1;
            this.pagination.total = res.origin.headers.get("X-Page-Count") * 1;
          });
    },
    remove: function (item) {
      this.$refs.confirmDialogue.show({
        title: 'Видалення',
        message: 'Ви дійсно хочете видалити словник і всі його дані?',
        okButton: 'Так',
        cancelButton: 'Ні',
      }).then((ok) => {
        if (ok)
          Http
              .del(process.env.VUE_APP_CRM_SERVICE + "/vocabulary/" + item.uuid)
              .then(() => this.fetch());
      })
    }
  }
};
</script>

<style scoped>

</style>