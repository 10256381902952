<template>
  <ul class="nav nav-pills">
    <li class="nav-item" >
      <router-link class="nav-link" :to="'/import/mapping'" v-bind:class="$route.path.startsWith('/import/mapping')?'active':''">Карта</router-link>
    </li>
    <li class="nav-item" v-if="full">
      <router-link class="nav-link" :to="'/import/upload'" v-bind:class="$route.path.startsWith('/import/upload')?'active':''">Завантаження</router-link>
    </li>
    <li class="nav-item" v-if="full">
      <router-link class="nav-link" :to="'/import/source'" v-bind:class="$route.path.startsWith('/import/source')?'active':''">Джерела</router-link>
    </li>
    <li class="nav-item" >
      <router-link class="nav-link" :to="'/import/currency'" v-bind:class="$route.path.startsWith('/import/currency')?'active':''">Курси валют</router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Tab",
  props: {
    full: Boolean
  },
}
</script>

<style scoped>

</style>