<template>
  <head-panel>
    <template v-slot:body>Структура каталогу

      <router-link to="/catalog">
        <button class="btn btn-secondary btn-sm btn-create-top ms-3" role="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-arrow-left-short" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
          </svg>
          Каталоги
        </button>
      </router-link>

      <router-link v-if="catalog" :to="'/catalog/'+catalog.uuid+'/create'"
                   class="btn btn-primary btn-sm btn-create ms-2">Створити групу
      </router-link>
    </template>
  </head-panel>

  <div class="wrapper">

    <table class="table">
      <thead>
      <tr>
        <th scope="col">Назва</th>
        <th scope="col">Шлях</th>
        <th scope="col"></th>
        <th scope="col">Вага</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <template v-for="item in items" :key="item">
        <tr>
          <td class="fw-bold"> {{ $filters.locale(item.locales) }}</td>
          <td> {{ item.url }}</td>
          <td></td>
          <td> {{ item.weight }}</td>

          <td class="actions">
            <router-link :to="'/catalog/'+catalog.uuid+'/group/'+item.uuid">Ред.</router-link>
            <router-link :to="'/catalog/'+catalog.uuid+'/group/'+item.uuid+'/create'">Додати ярлик</router-link>
          </td>
        </tr>

        <tr v-for="shortcut in item.shortcuts" :key="shortcut" class="shortcut">
          <td> {{ $filters.locale(shortcut.locales) }}</td>
          <td> {{ shortcut.url }}</td>
          <td>
            <img v-if="shortcut.preview" v-bind:src="$filters.image(shortcut.preview, '180x180')" width="40"
                 class="img-thumbnail">
            <img v-else src="/no-image-icon.png" width="40" class="img-thumbnail">
          </td>
          <td> {{ shortcut.weight }}</td>
          <td class="actions">
            <router-link :to="'/catalog/'+catalog.uuid+'/group/'+item.uuid+'/shortcut/'+shortcut.uuid">Ред.
            </router-link>
          </td>
        </tr>

      </template>

      </tbody>
    </table>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import ConfirmDialogue from "../../components/ConfirmDialog";

export default {
  name: "CatalogGroup",
  components: {
    HeadPanel, ConfirmDialogue
  },
  created() {
    this.fetch();
  },
  data() {
    return {
      catalog: null,
      items: []
    }
  },
  methods: {
    fetch: function () {
      Http
          .get(process.env.VUE_APP_CATALOG_SERVICE + "/manage/catalog/" + this.$route.params.catalog)
          .then((res) => {
            this.catalog = res.content;
            this.items = res.content.groups ? res.content.groups : [];
          });
    },
  }
};
</script>

<style scoped>

.shortcut > td:first-child {
  padding-left: 40px;
}

.shortcut > td {
  vertical-align: baseline;
}

</style>