<template>

  <div class="row ms-2 mb-4">
    <div class="col-xl-3">Налаштування індексу</div>
    <div class="col-lg-8">

      <div v-for="(type, index) in types" :key="type" class="mb-3">
        <div class="mb-1 fw-bold">{{ type.label }}</div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" v-model="form.types[type.value]" :name="type.value"
                 :id="'it'+type.value+index" :value="'disabled'">
          <label class="form-check-label" v-bind:for="'it'+type.value+index">Неактивно</label>
        </div>
        <div class="form-check form-check-inline" v-for="(action, index2) in type.actions" :key="action">
          <input class="form-check-input" type="radio" v-model="form.types[type.value]" :name="type.value"
                 :id="'it'+action.action+type.value+index2" :value="action.action">
          <label class="form-check-label"
                 v-bind:for="'it'+action.action+type.value+index2">{{ action.label }}</label>
        </div>
      </div>


      <div class="form-text">Використовуйте лише ті види які реально хочете імпортувати. Імпорт має приорітет над
        ручним редагуванням. Всі ручні зміни будуть перезатерті імпортом.
      </div>

      <div class="invalid-feedback" v-for="error of v$.form.types.$errors" :key="error.$uid">
        {{ error.$message }}
      </div>

    </div>
  </div>


</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import Http from "@/lib/Http";

export default {
  name: "EditIndex",
  props: {
    modelValue: Object,
  },
  created() {
    this.fetchMaps();
    this.form.types = !this.modelValue.types || !this.modelValue.types.length ? this.defaultTypes : this.modelValue.types.length;
  },
  watch: {
    form: {
      handler(val) {
        this.$emit('update:modelValue', val)
      },
      deep: true
    }
  },
  computed: {
    types() {
      return [
        {
          value: "menu",
          label: "Меню",
          actions: [
            {action: 'replace', label: 'Повна заміна'}
          ]
        },
        {
          value: "category",
          label: "Категорії",
          actions: [
            {action: 'insert', label: 'Вставка'},
            {action: 'upsert', label: 'Вставка і оновленя'}
          ]
        },
        {
          value: "brand",
          label: "Бренди",
          actions: [
            {action: 'insert', label: 'Вставка'},
            {action: 'upsert', label: 'Вставка і оновленя'}
          ]
        },
        {
          value: "tag",
          label: "Теги",
          actions: [
            {action: 'insert', label: 'Вставка'},
            {action: 'upsert', label: 'Вставка і оновленя'}
          ]
        },
        {
          value: "product",
          label: "Продукти",
          actions: [
            {action: 'insert', label: 'Вставка'},
            {action: 'upsert', label: 'Вставка і оновленя'}
          ]
        },
        {
          value: "image",
          label: "Зоображення",
          actions: [
            {action: 'insert', label: 'Додання нових'}
          ]
        },
      ];
    },
    defaultTypes() {
      return  this.types.reduce(function (result, key) {
        result[key.value] = 'disabled';
        return result
      }, {})
    }
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        types: {},
      }
    };
  },
  data() {
    return {
      mappingError: null,
      skuMaps: [],
      form: this.modelValue
    }
  },
  methods: {
    fetchMaps: function () {
      Http
          .get(process.env.VUE_APP_IMPORT_SERVICE + "/mapping/config/list")
          .then((res) => {
            this.skuMaps = res.content
          });
    },
    validate: async function () {
      return await this.v$.$validate();
    }
  }
};
</script>

<style scoped>

.btn-remove {
  color: red;
}

</style>