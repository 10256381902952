import {createRouter, createWebHashHistory} from "vue-router";
import Sidebar from '../components/Sidebar.vue'
import Index from "../views/main/Index"
import SettingCommonEdit from "../views/setting/Common"

import client from "./Client"
import marketing from "./Marketing"
import helpdesk from "./Helpdesk"
import market from "./Market"
import product from "./Product"
import manager from "./Manager"
import content from "./Content"
import review from "./Review"
import wallet from "./Wallet"
import menu from "./Menu"
import order from "./Order"
import banner from "./Banner"
import category from "./Category"
import brand from "./Brand"
import tag from "./Tag"
import catalog from "./Catalog"
import vocabulary from "./Vocabulary"
import importRoute from "./Import"
import integrations from "./Integration"
import seo from "./Seo"

const routes = [
    {
        path: "/",
        name: "Index",
        components: {
            default: Index,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/setting/common",
        name: "SettingCommonEdit",
        components: {
            default: SettingCommonEdit,
            LeftSidebar: Sidebar
        }
    },
    ...client,
    ...marketing,
    ...helpdesk,
    ...product,
    ...market,
    ...manager,
    ...order,
    ...menu,
    ...category,
    ...brand,
    ...tag,
    ...catalog,
    ...importRoute,
    ...content,
    ...review,
    ...wallet,
    ...banner,
    ...vocabulary,
    ...integrations,
    ...seo
];


const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
