import Sidebar from '../components/Sidebar.vue'
import CallbackList from "@/views/helpdesk/Index.vue";
import CallbackEdit from "@/views/helpdesk/Edit.vue";

export default [

    {
        path: "/helpdesk",
        name: "Helpdesk",
        components: {
            default: CallbackList,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/helpdesk/:id",
        name: "HelpdeskEdit",
        components: {
            default: CallbackEdit,
            LeftSidebar: Sidebar
        }
    },
];