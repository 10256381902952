<template>
  <router-view name="LeftSidebar" v-if="isSidebar"/>
  <section class="home">
    <router-view v-if="!isWelcome"/>
    <div v-else class="">
      <welcome/>
    </div>
  </section>
</template>

<script>

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import Welcome from "@/views/market/Welcome.vue";

export default {
  name: 'App',
  components: {Welcome},
  computed: {
    isSidebar() {
      return !!this.$session.market
    },
    isWelcome() {
      return !this.$session.market && this.$route.path !== '/market/add';
    }
  },
}
</script>

<style>
@import './assets/css/theme.css';
@import './assets/css/activity.css';
@import './assets/css/ql-editor.css';
</style>
