import Sidebar from '../components/Sidebar.vue'
import Index from "../views/vocabulary/Vocabularies"
import Edit from "../views/vocabulary/VocabularyEdit"
import Items from "../views/vocabulary/Items"
import ItemEdit from "../views/vocabulary/ItemEdit"

export default [
    {
        path: "/vocabulary",
        name: "VocabularyIndex",
        components: {
            default: Index,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/vocabulary/:id",
        name: "VocabularyEdit",
        components: {
            default: Edit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/vocabulary/:vid/create",
        name: "ItemCreate",
        components: {
            default: ItemEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/vocabulary/:vid/item/:alias",
        name: "VocabularyItemEdit",
        components: {
            default: ItemEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/vocabulary/:id/item",
        name: "VocabularyItems",
        components: {
            default: Items,
            LeftSidebar: Sidebar
        }
    }
];