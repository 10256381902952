<template>
  <div>
    <modal class="short-modal" @close="close">

      <template v-slot:header>
        Ручне зарахування коштів
      </template>
      <template v-slot:body>

        <form @submit.prevent="submit">

          <div>
            <label>Сума платежу</label>
            <input type="text" class="form-control" v-model="form.amount" placeholder="Сума"
                   :class="{ 'is-invalid' : v$.form.amount.$error}">
            <div class="invalid-feedback" v-for="error of v$.form.amount.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>

          <div class="mt-2">
            <label>Опис</label>
            <input type="text" class="form-control" v-model="form.description" placeholder="Коментар до переказу"
                   :class="{ 'is-invalid' : v$.form.description.$error}">
            <div class="invalid-feedback" v-for="error of v$.form.description.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>

          <button type="submit" class="btn  btn-primary mt-3 mb-4">Зарахувати</button>

        </form>

      </template>
    </modal>

    <confirm-dialogue ref="confirmDialogue"/>
  </div>
</template>

<script>
import Http from "../../lib/Http";
import ConfirmDialogue from '../../components/ConfirmDialog.vue'
import Modal from "../../components/Modal.vue";
import {useVuelidate} from "@vuelidate/core";
import {maxLength, required} from "@vuelidate/validators";

export default {
  name: "ManagerAddModal",
  components: {
    Modal, ConfirmDialogue
  },
  props: {
    order: Object,
    invoice: Object,
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        amount: {
          required
        },
        description: {
          required,
          maxLength: maxLength(255)
        }
      },
    };
  },
  data() {
    return {
      form: {
        amount: this.invoice.remainingAmount / 100,
        description: null
      },
    }
  },
  methods: {
    submit: async function () {

      const result = await this.v$.form.$validate()

      if (!result) return

      this.$refs.confirmDialogue.show({
        title: 'Заразування коштів',
        message: "Ви дійсно хочете зарахувати: <br/><strong>"
            + this.form.amount + " "
            + this.invoice.balance.currency
            + "</strong> <br/> на рахунок інвойсу ?",
        okButton: 'Так',
        cancelButton: 'Ні',
      }).then((ok) => {
        if (ok)
          Http
              .post(process.env.VUE_APP_WALLET_SERVICE + "/payment/invoice/" + this.order.invoice.uuid + "/manual", {
                amount: this.form.amount * 100,
                description: this.form.description
              })
              .then(() => {
                this.close();
              });
      })
    },
    close: function () {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>