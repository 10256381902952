import Sidebar from '../components/Sidebar.vue'
import Index from "../views/manager/Index"
import Permissions from "../views/manager/Permissions.vue"

export default [
    {
        path: "/manager",
        name: "Manager",
        components: {
            default: Index,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/manager/:id/permission",
        name: "ManagerPermissions",
        components: {
            default: Permissions,
            LeftSidebar: Sidebar
        }
    }
];