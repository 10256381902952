<template>
  <head-panel>
    <template v-slot:body>Конфігурації
      <router-link :to="'/wallet/processing/create'" class="btn btn-primary btn-sm btn-create ms-2">Створити
      </router-link>
    </template>
  </head-panel>

  <div class="wrapper">

    <tab/>

    <hr/>

    <table class="table">
      <thead>
      <tr>
        <th scope="col">ID</th>
        <th scope="col">Назва</th>
        <th scope="col">Статус</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <template v-for="content in contents" :key="content">
        <tr class="menu">
          <td>{{ content.uuid }}</td>
          <td>{{ content.name }}</td>
          <td>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                     :checked="content.status==='ACTIVE'" @change="changeStatus(content)">
            </div>
          </td>
          <td class="actions">
            <router-link :to="'/wallet/processing/'+content.uuid">Ред.</router-link>
          </td>
        </tr>
      </template>

      </tbody>
    </table>


  </div>

</template>

<script>
import Http from "../../../lib/Http";
import HeadPanel from "../../../components/HeadPanel.vue";
import Tab from "../Tab.vue";

export default {
  name: "Menu",
  components: {
    HeadPanel, Tab
  },
  created() {
    this.fetch();
  },
  watch: {
    $route() {
      this.fetch();
    },
  },
  data() {
    return {
      search: null,
      contents: []
    }
  },
  methods: {
    fetch: function () {
      Http
          .get(process.env.VUE_APP_WALLET_SERVICE + '/processing')
          .then((res) => {
            this.contents = res.content;
          });
    },
    changeStatus: function (item) {

      const data = item;

      data.status = item.status === 'ACTIVE' ? 'DISABLED' : 'ACTIVE';

      Http
          .put(process.env.VUE_APP_WALLET_SERVICE + '/processing/' + item.uuid, data)
          .then(() => {
            this.fetch();
          });
    }
  }
};
</script>

<style scoped>

</style>