<template>

  <div class="card">
    <div class="card-body">
      <h5 class="card-title">{{ item.name }}</h5>
      <p class="card-text">{{ item.description }}</p>
      <div class="form-check form-switch">
        <input class="form-check-input" :checked="item.status === 'ACTIVE'" type="checkbox" id="importStatus"
               @change="statusSwitch">
        <label class="form-check-label" for="importStatus">Активність</label>
      </div>
    </div>
  </div>


</template>

<script>
import Http from "../../lib/Http";

export default {
  name: "Integrations",
  components: {},
  created() {
    this.fetch();
  },
  computed: {
    item() {
      const market = Http.session.market;
      return {
        uuid: market.uuid,
        status: this.status,
        name: 'Імпорт',
        description: 'Дозволяє імпортувати продукти, ціни, залишки і т.д',
      };
    },
  },
  data() {
    return {
      status: 'DISABLED',
    }
  },
  methods: {
    fetch: function () {
      const market = Http.session.market;
      Http
          .get(process.env.VUE_APP_IMPORT_SERVICE + '/integration/' + market.uuid + "/status")
          .then(res => this.status = res.content === 'ACTIVE' ? 'ACTIVE' : 'DISABLED');
    },

    statusSwitch: async function () {
      const market = Http.session.market;
      Http
          .get(process.env.VUE_APP_IMPORT_SERVICE + '/integration/' + market.uuid)
          .then(res => {
            const action = res.content.status === 'ACTIVE' ? 'disable' : 'enable';
            return Http
                .put(process.env.VUE_APP_IMPORT_SERVICE + '/integration/' + market.uuid + '/' + action)
                .then(res => this.status = res.content.status);
          })
          .catch(() => {
            Http
                .post(process.env.VUE_APP_IMPORT_SERVICE + '/integration', {
                  "uuid": market.uuid,
                  "name": market.name
                })
                .then(res => this.status = res.content.status);
          });
    }

  }
};
</script>

<style scoped>

</style>