import Sidebar from '../components/Sidebar.vue'
import GroupsList from "@/views/marketing/promo/GroupSearch.vue";
import GroupEdit from "@/views/marketing/promo/GroupEdit.vue";

import CodeList from "@/views/marketing/promo/CodeSearch.vue";
import CodeUsesList from "@/views/marketing/promo/CodeUses.vue";
import CodeEdit from "@/views/marketing/promo/CodeEdit.vue";
import ActionSearch from "@/views/marketing/promo/ActionSearch.vue";
import ActionProducts from "@/views/marketing/promo/ActionProducts.vue";
import ActionEdit from "@/views/marketing/promo/ActionEdit.vue";
import LoyaltyEdit from "@/views/marketing/loyalty/Edit.vue";

export default [

    {
        path: "/marketing/promo/group",
        name: "Groups",
        components: {
            default: GroupsList,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/marketing/promo/group/create",
        name: "GroupCreate",
        components: {
            default: GroupEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/marketing/promo/group/:id",
        name: "GroupEdit",
        components: {
            default: GroupEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/marketing/promo/code",
        name: "CodeList",
        components: {
            default: CodeList,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/marketing/promo/code/uses",
        name: "CodeUsesList",
        components: {
            default: CodeUsesList,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/marketing/promo/code/generate",
        name: "CodeCreate",
        components: {
            default: CodeEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/marketing/promo/code/:id",
        name: "CodeEdit",
        components: {
            default: CodeEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/marketing/promo/action",
        name: "ActionSearch",
        components: {
            default: ActionSearch,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/marketing/promo/action/:id/products",
        name: "ActionProducts",
        components: {
            default: ActionProducts,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/marketing/promo/action/create",
        name: "ActionCreate",
        components: {
            default: ActionEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/marketing/promo/action/:id",
        name: "ActionEdit",
        components: {
            default: ActionEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/marketing/loyalty",
        name: "LoyaltyEdit",
        components: {
            default: LoyaltyEdit,
            LeftSidebar: Sidebar
        }
    },
];