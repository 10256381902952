<template>

  <div class="card">
    <div class="card-body">
      <h5 class="card-title">{{ item.name }}</h5>
      <p class="card-text">{{ item.description }}</p>

      <div class="form-check form-switch">
        <input class="form-check-input" :checked="item.status === 'ACTIVE'" type="checkbox" id="importStatus"
               @change="statusSwitch">
        <label class="form-check-label" for="importStatus">Активність</label>
      </div>

      <div class="mb-2" v-if="project && project.mosquito">
        <label class="mt-2">Project</label>
        <input type="text" disabled class="form-control form-control-sm" :value="project.uuid">
        <label class="mt-2">Mosquito</label>
        <input type="text" disabled class="form-control form-control-sm" :value="project.mosquito.project">
        <label class="mt-2">Channel</label>
        <input type="text" disabled class="form-control form-control-sm" :value="project.mosquito.channel">
        <label class="mt-2">Ticket NEW</label>
        <input type="text" disabled class="form-control form-control-sm" :value="project.mosquito.ticketNewMessage">
        <label class="mt-2">Ticket WAIT</label>
        <input type="text" disabled class="form-control form-control-sm" :value="project.mosquito.ticketNewMessage">
      </div>

    </div>
  </div>


</template>

<script>
import Http from "../../lib/Http";

export default {
  name: "Integrations",
  components: {},
  created() {
    this.fetch();
  },
  computed: {
    item() {
      const market = Http.session.market;
      return {
        uuid: market.uuid,
        status: this.status,
        name: 'Helpdesk',
        description: 'Дозволяє отвирумати запити від клієнтів(зворотній дзвінок, форма контактів і т.д)',
      };
    },
  },
  data() {
    return {
      status: 'DISABLED',
      project: null,
    }
  },
  methods: {
    fetch: function () {
      const market = Http.session.market;
      const alias = "market:" + market.uuid + ":project";
      Http
          .get(process.env.VUE_APP_HELP_SERVICE + "/integration/" + alias + "")
          .then(res => {
            this.project = res.content;
            this.status = res.content.status === 'ACTIVE' ? 'ACTIVE' : 'DISABLED';
          });
    },

    statusSwitch: async function () {
      const market = Http.session.market;
      const alias = "market:" + market.uuid + ":project";
      Http
          .get(process.env.VUE_APP_HELP_SERVICE + "/integration/" + alias)
          .then(res => {
            const action = res.content.status === 'ACTIVE' ? 'disable' : 'enable';
            return Http
                .put(process.env.VUE_APP_HELP_SERVICE + '/integration/' + res.content.uuid + '/' + action)
                .then(e => {
                  if (e.content.status === 'ACTIVE')
                    this.updateMosquito(e.content);
                  return e;
                })
                .then(res => this.status = res.content.status);
          })
          .catch(() => {
            Http
                .post(process.env.VUE_APP_HELP_SERVICE + '/project', {
                  "name": "Marketplace " + market.name,
                  "alias": alias,
                })
                .then(e => this.updateMosquito(e.content))
                .then(res => this.status = res.content.status);
          });
    },

    updateMosquito: async function (helpdesk) {
      const market = Http.session.market;
      const alias = "market:" + market.uuid + ":project";

      const mosquito = await this._project();

      return Http
          .put(process.env.VUE_APP_HELP_SERVICE + '/project/' + helpdesk.uuid, {
            "name": "Marketplace " + market.name,
            "alias": alias,
            "mosquito": {
              "project": mosquito.uuid,
              "channel": await this._channel(helpdesk, mosquito),
              "ticketNewMessage": await this._ticketNewMessage(helpdesk, mosquito),
              "ticketWaitMessage": await this._ticketWaitMessage(helpdesk, mosquito)
            }
          });
    },

    _project: function () {
      const market = Http.session.market;
      const alias = "market:" + market.uuid + ":project";

      return Http
          .get(process.env.VUE_APP_MOSQUITO_SERVICE + "/integration/" + alias)
          .then(res => res.content)
          .catch(() => {
            return Http
                .post(process.env.VUE_APP_MOSQUITO_SERVICE + '/project', {
                  "name": "Marketplace " + market.name,
                  "alias": alias,
                })
                .then(res => res.content);
          });
    },

    _channel: function (helpdesk, mosquito) {
      const market = Http.session.market;
      const alias = "market:" + market.uuid + ":helpdesk:channel";

      return Http
          .get(process.env.VUE_APP_MOSQUITO_SERVICE + "/integration/channel/" + alias)
          .then(res => res.content.uuid)
          .catch(() => {
            return Http
                .post(process.env.VUE_APP_MOSQUITO_SERVICE + '/integration/channel/'+mosquito.uuid, {
                  "name": "Marketplace " + market.name + " tickets",
                  "alias": alias,
                })
                .then(res => res.content.uuid);
          });
    },

    _ticketNewMessage: function (helpdesk, mosquito) {
      const market = Http.session.market;
      const alias = "market:" + market.uuid + ":helpdesk:ticket:new:message";

      return Http
          .get(process.env.VUE_APP_MOSQUITO_SERVICE + "/integration/message/" + alias)
          .then(res => res.content.uuid)
          .catch(() => {
            return Http
                .post(process.env.VUE_APP_MOSQUITO_SERVICE + '/integration/message/'+mosquito.uuid, {
                  "name": "Marketplace " + market.name + " new ticket",
                  "type": "TELEGRAM",
                  "alias": alias,
                  "text": "Отримано нових запитів від клієнтів: {{count}}\nhttps://marketplace.creelayer.com/#/helpdesk"
                })
                .then(res => res.content.uuid);
          });
    },

    _ticketWaitMessage: function (helpdesk, mosquito) {
      const market = Http.session.market;
      const alias = "market:" + market.uuid + ":helpdesk:ticket:wait:message";

      return Http
          .get(process.env.VUE_APP_MOSQUITO_SERVICE + "/integration/message/" + alias)
          .then(res => res.content.uuid)
          .catch(() => {
            return Http
                .post(process.env.VUE_APP_MOSQUITO_SERVICE + '/integration/message/'+mosquito.uuid, {
                  "name": "Marketplace " + market.name + " wait ticket",
                  "type": "TELEGRAM",
                  "alias": alias,
                  "text": "Неопрацьовані запити від клієтів: {{count}}\nhttps://marketplace.creelayer.com/#/hepldesk"
                })
                .then(res => res.content.uuid);
          });
    },

  }
};
</script>

<style scoped>

</style>