<template>
  <head-panel>
    <template v-slot:body>Баннери
      <router-link to="/banner/create" class="btn btn-primary btn-sm btn-create ms-2">Створити</router-link>
    </template>
  </head-panel>

  <div class="wrapper">

    <div class="filter">
      <div class="item">
        <SearchFilter
            label="Швидкий пошук"
            name="search"
            path="banner"
            v-model="search"
        />
      </div>
    </div>


    <table class="table">
      <thead>
      <tr>
        <th scope="col">ID</th>
        <th scope="col">Назва</th>
        <th scope="col">Статус</th>
        <th scope="col">Url</th>
        <th scope="col">weight</th>
        <th scope="col">Tag</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <template v-for="banner in banners" :key="banner">
        <tr class="menu">
          <td>{{ banner.uuid }}</td>
          <td>{{ banner.name }}</td>
          <td>{{ banner.status }}</td>
          <td>{{ banner.url }}</td>
          <td>{{ banner.weight }}</td>
          <td>{{ banner.tag ? banner.tag.join(", ") : '' }}</td>
          <td class="actions">
            <router-link :to="'/banner/'+banner.uuid">Ред.</router-link>
            <a @click="remove(banner)">Видалити</a>
          </td>
        </tr>
      </template>

      </tbody>
    </table>

    <Pagination
        :current-page="pagination.page"
        :total-pages="pagination.total"/>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import SearchFilter from "../../components/SearchFilter";
import ConfirmDialogue from "../../components/ConfirmDialog";
import Pagination from "../../components/Pagination";

export default {
  name: "Menu",
  components: {
    HeadPanel, SearchFilter, ConfirmDialogue, Pagination
  },
  created() {
    this.fetch();
  },
  watch: {
    $route(n, o) {
      if (n.path === o.path)
        this.fetch();
    },
  },
  data() {
    return {
      search: null,
      banners: [],
      pagination: {
        page: 0,
        total: 0
      }
    }
  },
  methods: {
    fetch: function () {

      const query = {
        project: this.$session.market.uuid
      };

      if (this.$route.query.search)
        query.search = this.$route.query.search;

      Http
          .get(process.env.VUE_APP_BANNER_SERVICE + "/banner?" + new URLSearchParams(query).toString())
          .then((res) => {
            this.banners = res.content;
            this.pagination.page = res.origin.headers.get("X-Current-Page") * 1;
            this.pagination.total = res.origin.headers.get("X-Page-Count") * 1;
          });
    },

    remove: function (item) {
      this.$refs.confirmDialogue.show({
        title: 'Видалення',
        message: 'Ви дійсно хочете видалити баннер?',
        okButton: 'Так',
        cancelButton: 'Ні',
      }).then((ok) => {
        if (ok)
          Http
              .del(process.env.VUE_APP_BANNER_SERVICE + "/banner/" + item.uuid)
              .then(() => this.fetch());
      })
    }
  }
};
</script>

<style scoped>

</style>