<template>
  <div class="row mb-2" v-for="(locale, index) in locales" :key="locale">
    <div class="col-2">
      <select class="form-select" v-model="locale.lang" @change="changed">
        <option v-for="language in languages" :key="language" :value="language.name">
          {{ language.label }}
        </option>
      </select>
    </div>
    <div class="col-10">
      <input type="text" class="form-control" v-model="locales[index].value" @change="changed"
             :class="{ 'is-invalid' : v$.locales.$each.$response.$errors[index].value.length}">
      <div class="invalid-feedback" v-for="error of v$.locales.$each.$response.$errors[index].value" :key="error.$uid">
        {{ error.$message }}
      </div>
    </div>
  </div>
</template>

<script>

import {useVuelidate} from "@vuelidate/core";
import {helpers, maxLength, required} from "@vuelidate/validators";

export default {
  name: "Locale",
  props: {
    modelValue: Object,
    lang: Array,
    disabled: Boolean
  },
  created() {
    this.locales = this.createLocales();
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      locales: {
          $each: helpers.forEach({
          value: {
            required,
            maxLength: maxLength(255),
          }
        })
      }
    };
  },
  watch: {
    modelValue() {
      this.locales = this.createLocales();
    },
  },
  computed: {
    languages: function () {
      if (this.lang && this.lang.length)

        return this.lang.map(lng => {
          return {'name': lng, 'label': lng.toUpperCase()};
        })

      return [
        {'name': 'default', 'label': 'Default'}
      ];
    },
  },
  data() {
    return {
      locales: []
    }
  },
  methods: {
    changed: async function () {

      if (!await this.v$.$validate()) {
        this.$emit('update:modelValue', null);
        return;
      }

      let locales = {};
      for (let locale of this.locales)
        if (locale.value)
          locales[locale.lang] = locale.value;

      this.$emit('update:modelValue', locales)
    },
    createLocales: function () {
      let locales = [];
      for (let language of this.languages) {
        if (this.modelValue && this.modelValue[language.name])
          locales[locales.length] = {lang: language.name, value: this.modelValue[language.name]};
        else
          locales[locales.length] = {lang: language.name, value: null};
      }
      return locales;
    }
  }
}
</script>

<style scoped>

</style>