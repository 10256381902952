<template>
  <head-panel>
    <template v-slot:body>Запити клієнтів</template>
  </head-panel>

  <div class="wrapper">

    <div class="filter">
      <div class="item ">
        <SearchFilter
            label="Швидкий пошук"
            name="search"
            path="helpdesk"
        />
      </div>
      <div class="item">
        <DropdownFilter
            label="Тип"
            name="type"
            path="helpdesk"
            :items="types"
        />
      </div>
      <div class="item">
        <DropdownFilter
            label="Статус"
            name="status"
            path="helpdesk"
            :items="statuses"
        />
      </div>

      <div class="item">
        <DropdownFilter
            label="Категорія"
            name="category"
            path="helpdesk"
            :items="categories"
        />
      </div>
    </div>

    <table class="table">
      <thead>
      <tr>
        <th scope="col">Тема</th>
        <th scope="col">Тип</th>
        <th scope="col">Статус</th>
        <th scope="col">Категорія</th>
        <th scope="col">Коментар</th>
        <th scope="col">Створений</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in items" :key="item">
        <td>{{ item.subject }}</td>
        <td>{{ item.type }}</td>
        <td>{{ item.status }}</td>
        <td>{{ item.category }}</td>
        <td v-if="!item.comment || item.comment.length < 100">{{ item.comment }}</td>
        <td v-else>{{ item.comment.substring(0, 100) }}...</td>
        <td>{{ $filters.dateTime(item.createdAt) }}</td>
        <td class="actions">
          <router-link :to="'/helpdesk/'+item.uuid">Деталі</router-link>
        </td>
      </tr>
      </tbody>
    </table>

    <Pagination
        :current-page="pagination.page"
        :total-pages="pagination.total"/>

  </div>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import Pagination from "../../components/Pagination";
import SearchFilter from "../../components/SearchFilter";
import DropdownFilter from "../../components/DropdownFilter";

export default {
  name: "Index",
  components: {
    HeadPanel, Pagination, SearchFilter, DropdownFilter
  },
  created() {
      this.fetch();
      this.fetchTypes();
      this.fetchStatuses();
      this.fetchCategories();
  },
  watch: {
    $route(n, o) {
      if (n.path === o.path)
        this.fetch();
    }
  },
  computed: {},
  data() {
    return {
      items: [],
      types: [],
      statuses: [],
      categories: [],
      pagination: {
        page: 0,
        total: 0
      },
    }
  },
  methods: {
    fetch: function () {
      const integration = Http.session.integrations.helpdesk;
      Http
          .get(process.env.VUE_APP_HELP_SERVICE + "/ticket/project/" + integration.uuid + "?" + new URLSearchParams(this.$route.query).toString())
          .then((res) => {
            this.items = res.content;
            this.pagination.page = res.origin.headers.get("X-Current-Page") * 1;
            this.pagination.total = res.origin.headers.get("X-Page-Count") * 1;
          });
    },

    fetchTypes: function () {
      Http
          .get(process.env.VUE_APP_CRM_SERVICE + '/vocabulary/alias:ticket:type')
          .then(res => {
            this.types = res.content.items.map(item => {
              return {'value': item.alias, 'label': item.name};
            });
          })
    },
    fetchStatuses: function () {
      Http
          .get(process.env.VUE_APP_CRM_SERVICE + '/vocabulary/alias:ticket:status')
          .then(res => {
            this.statuses = res.content.items.map(item => {
              return {'value': item.alias, 'label': item.name};
            });
          })
    },
    fetchCategories: function () {
      Http
          .get(process.env.VUE_APP_CRM_SERVICE + '/vocabulary/alias:ticket:category')
          .then(res => {
            this.categories = res.content.items.map(item => {
              return {'value': item.alias, 'label': item.name};
            });
          })
    },
  }
};
</script>

<style scoped>

</style>