<template>
  <head-panel>
    <template v-slot:body>
      Інформація по замовленням <span v-if="client"> {{ client.uuid }}</span></template>
  </head-panel>

  <div class="wrapper ps-3 pe-3" v-if="client">

    <tab :client="client"/>

    <hr/>

    <table class="table">
      <thead>
      <tr>
        <th scope="col">№</th>
        <th scope="col">Статус</th>
        <th scope="col">Створений</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="order in orders" :key="order">
        <td>{{ order.code }}</td>
        <td><span v-bind:class="order.status ? 'status' : ''">{{ order.status ? order.status : '' }}</span></td>
        <td>{{ $filters.dateTime(order.createdAt) }}</td>
        <td class="actions">
          <router-link :to="'/order/'+order.uuid">Перегляд</router-link>
        </td>
      </tr>
      </tbody>
    </table>

    <Pagination
        :current-page="pagination.page"
        :total-pages="pagination.total"/>

  </div>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import Tab from "@/views/client/Tab.vue";
import Pagination from "@/components/Pagination.vue";

export default {
  name: "Edit",
  components: {
    HeadPanel, Tab, Pagination
  },
  created() {
    this.fetch();
  },
  data() {
    return {
      client: null,
      orders: [],
      pagination: {
        page: 0,
        total: 0
      },
    }
  },
  methods: {
    fetch: function () {
      Http
          .get(process.env.VUE_APP_CRM_SERVICE + "/client/" + this.$route.params.id)
          .then((res) => {
            this.client = res.content;
            this.fetchOrders(this.client);

          });
    },
    fetchOrders(client) {
      Http
          .get(process.env.VUE_APP_CRM_SERVICE + "/order?customer=" + client.uuid)
          .then((res) => {
            this.orders = res.content;
            this.pagination.page = res.origin.headers.get("X-Current-Page") * 1;
            this.pagination.total = res.origin.headers.get("X-Page-Count") * 1;
          });
    }
  }
};
</script>

<style scoped>


</style>