<template>
  <head-panel>
    <template v-slot:body>Курси валют
    </template>
  </head-panel>

  <div class="wrapper">

    <tab :full="true"/>

    <hr/>

    <Alert ref="alert"/>

    <form @submit.prevent="submit">


      <div class="row ms-2 mb-4">
        <div class="col-lg-3">
          Валюта
        </div>
        <div class="col-lg-3">
          Курс
        </div>
      </div>

      <div class="row ms-2 mb-4" v-for="(item, index) in form" :key="item">
        <div class="col-lg-3">
          <input type="text" class="form-control" v-model="item.currency"
                 :class="{ 'is-invalid' : v$.form.$each.$response.$errors[index].currency.length}" disabled>
          <div class="invalid-feedback" v-for="error of v$.form.$each.$response.$errors[index].currency"
               :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
        <div class="col-lg-3">
          <input type="text" class="form-control" v-model="item.rate"
                 :class="{ 'is-invalid' : v$.form.$each.$response.$errors[index].rate.length}">
          <div class="invalid-feedback" v-for="error of v$.form.$each.$response.$errors[index].rate"
               :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>
      <div class="row ms-2 mb-4">
        <div class="col-xl-6">
          <button type="submit" class="btn btn-primary">Зберегти</button>
        </div>
      </div>
    </form>

  </div>
</template>

<script>
import HeadPanel from "../../components/HeadPanel.vue";
import Tab from "./Tab.vue";
import Http from "../../lib/Http";
import {useVuelidate} from "@vuelidate/core";
import {decimal, helpers, maxLength, minLength} from "@vuelidate/validators";
import Alert from "../../components/Alert.vue";

export default {
  name: "Currency",
  components: {
    HeadPanel, Tab, Alert
  },
  created() {
    this.fetch();
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        $each: helpers.forEach({
          currency: {
            minLength: minLength(3),
            maxLength: maxLength(3),
          },
          rate: {
            decimal,
          },
        })
      }
    };
  },
  data() {
    return {
      currencies: [],
      form: [],
    }
  },
  methods: {
    fetch: function () {
      Http
          .get(process.env.VUE_APP_IMPORT_SERVICE + "/currency/rate")
          .then((res) => {
            this.currencies = res.content;
            this.form = this.currencies;
            // this.form.push({currency: null, rate: null})
          });
    },
    submit: async function () {

      const result = await this.v$.$validate()

      if (!result) return


      const data = this.form.filter(item => item.currency && item.rate)

      Http
          .put(process.env.VUE_APP_IMPORT_SERVICE + "/currency/rate", data)
          .then(() => {
            this.fetch();
            this.$refs.alert.success('Успішно збережено');
          })
          .catch((e) => {
            this.$refs.alert.danger(e.detail);
          });
    },
  }
}
</script>

<style scoped>

</style>