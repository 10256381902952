<template>

  <head-panel>
    <template v-slot:body>
      Каталог <span v-if="shortcut"> {{ shortcut.name }}</span>

      <router-link v-if="this.group" :to="'/catalog/'+catalog.uuid+'/list'">
        <button class="btn btn-secondary btn-sm btn-create-top ms-3" role="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-arrow-left-short" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
          </svg>
          Повернутись до списку
        </button>
      </router-link>

      <a @click="remove" class="btn btn-danger btn-sm btn-remove float-end"
         v-if="shortcut && this.$session.can('owner', 'catalog_manage')">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash"
             viewBox="0 0 16 16">
          <path
              d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
          <path fill-rule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>
        Видалити ярлик
      </a>

    </template>
    <template v-slot:top>&nbsp;</template>
  </head-panel>

  <div class="wrapper">

    <form @submit.prevent="submit">

      <Alert ref="alert"/>

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Url</div>
        <div class="col-lg-8">
          <input type="text" class="form-control" v-model="form.url"
                 :class="{ 'is-invalid' : v$.form.url.$error}">
          <div class="invalid-feedback" v-for="error of v$.form.url.$errors"
               :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Превью</div>
        <div class="col-lg-8">

          <div v-if="!form.preview">
            <input class="form-control" type="file" accept="image/png, image/jpeg" id="preview" ref="preview"
                   v-on:change="e => uploadPreview(e)">
            <label for="preview" class="form-label mt-1">Формат jpg, png</label>
          </div>

          <span class="preview" v-else>
                  <img v-bind:src="$filters.image(form.preview, '180x180')" width="70" height="70"
                       class="img-thumbnail">
                   <svg @click="removePreview()" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                      <path
                          d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                    </svg>
              </span>

        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Вага</div>
        <div class="col-lg-8">
          <input type="text" class="form-control" v-model="form.weight"
                 :class="{ 'is-invalid' : v$.form.weight.$error}">
          <div class="invalid-feedback" v-for="error of v$.form.weight.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Переклади</div>
        <div class="col-lg-8">
          <locale-form v-model="form.locales"/>
        </div>
      </div>


      <div class="row ms-2 mb-4">
        <div class="col-xl-6">
          <button type="submit" class="btn btn-primary">Зберегти</button>
        </div>
      </div>

    </form>
  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {integer, required} from "@vuelidate/validators";
import Http from "../../lib/Http";
import LocaleForm from "../../components/LocaleForm.vue";
import HeadPanel from "@/components/HeadPanel.vue";
import Alert from "@/components/Alert.vue";
import ConfirmDialogue from "@/components/ConfirmDialog.vue";

export default {
  name: "EditShortCut",
  components: {
    LocaleForm, HeadPanel, Alert, ConfirmDialogue
  },
  created() {

    if (this.$route.params.catalog)
      this.fetch(this.$route.params.catalog);

  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        url: {required},
        locales: {required},
        weight: {integer}
      }
    };
  },
  data() {
    return {
      catalog: null,
      group: null,
      shortcut: null,
      form: {
        url: null,
        preview: null,
        locales: null
      }
    }
  },
  methods: {

    fetch: function (catalog) {
      Http
          .get(process.env.VUE_APP_CATALOG_SERVICE + "/manage/catalog/" + catalog)
          .then((res) => {

            this.catalog = res.content;

            if (this.$route.params.group)
              this.group = res.content.groups.find(e => e.uuid === this.$route.params.group);

            if (this.$route.params.shortcut)
              this.shortcut = this.group.shortcuts.find(e => e.uuid === this.$route.params.shortcut);

            if (this.shortcut)
              this.form = this.shortcut;

          });
    },


    submit: async function () {

      const result = await this.v$.$validate()

      if (!result) return

      this.group.shortcuts.upsert({
        uuid: this.shortcut ? this.shortcut.uuid : crypto.randomUUID(),
        locales: this.form.locales,
        url: this.form.url,
        weight: this.form.weight,
        preview: this.form.preview
      }, 'uuid', this.shortcut ? this.shortcut.uuid: null);


      Http
          .put(process.env.VUE_APP_CATALOG_SERVICE + "/manage/catalog/" + this.catalog.uuid, this.catalog)
          .then(() => {
            this.$refs.alert.success('Успішно збережено');
          })
          .catch((e) => {
            this.$refs.alert.danger(e.message);
          });

    },

    uploadPreview: function (e) {
      const file = e.target.files[0];
      const fd = new FormData();
      fd.append('file', file, file.name);

      Http.post(process.env.VUE_APP_PIO_SERVICE + "/v1/upload/" + process.env.VUE_APP_PIO_BUCKET + "/multipart", fd)
          .then(res => {
            this.form.preview = res.content;
          });
    },

    removePreview: function () {
      this.form.preview = null;
    },

    remove: function () {
      this.$refs.confirmDialogue.show({
        title: 'Видалення',
        message: "Ви дійсно хочете видалити ярлик і пов'язані з ним  дані?",
        okButton: 'Так',
        cancelButton: 'Ні',
      }).then((ok) => {
        if (ok) {
          this.group.shortcuts = this.group.shortcuts.filter(e => e.uuid !== this.shortcut.uuid);
          Http
              .put(process.env.VUE_APP_CATALOG_SERVICE + "/manage/catalog/" + this.catalog.uuid, this.catalog)
              .then(() => {
                this.$router.replace("/catalog/" + this.catalog.uuid + "/list");
              });
        }
      })
    },
  }
}
</script>

<style scoped>
.preview {
  float: left;
  margin-right: 10px;
  position: relative;
}

.preview svg {
  color: red;
  cursor: pointer;
  margin-left: 20px;
}
</style>