<template>
  <head-panel>
    <template v-slot:body>Інтеграції

    </template>
  </head-panel>

  <div class="wrapper">

    <div class="row ms-2 me-2">
      <div class="col-4 mb-2" >
        <import-module/>
      </div>
      <div class="col-4 mb-2" >
        <helpdesk-module/>
      </div>
      <div class="col-4 mb-2" >
        <review-module/>
      </div>
      <div class="col-4 mb-2" >
        <content-module/>
      </div>
    </div>

  </div>

</template>

<script>
import HeadPanel from "../../components/HeadPanel.vue";
import ImportModule from "./_import.vue";
import HelpdeskModule from "./_helpdesk.vue";
import ReviewModule from "./_review.vue";
import ContentModule from "./_content.vue";

export default {
  name: "Integrations",
  components: {
    HeadPanel, ImportModule, HelpdeskModule, ReviewModule, ContentModule
  },
  data() {
    return {
    }
  },
  methods: {
  }
};
</script>

<style scoped>

</style>