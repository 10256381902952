<template>
  <modal class="cart-modal">

    <template v-slot:header>
      <button class="btn btn-primary btn-sm mt-0" @click="itemAction('add')">Додати</button>
    </template>
    <template v-slot:body>
      <div class="mb-3 ms-1 me-1">

        <table class="table table-item">
          <tbody>

          <tr v-if="action.type==='add'" class="add-new">
            <td>
              <input type="text" class="form-control form-control-sm" v-model="form.name"
                     :class="{ 'is-invalid' : v$.form.name.$error}" placeholder="Name">
              <div class="invalid-feedback" v-for="error of v$.form.name.$errors" :key="error.$uid">
                {{ error.$message }}
              </div>
            </td>
            <td>
              <input type="text" class="form-control form-control-sm" v-model="form.alias"
                     :class="{ 'is-invalid' : v$.form.alias.$error}" placeholder="Alias">
              <div class="invalid-feedback" v-for="error of v$.form.alias.$errors" :key="error.$uid">
                {{ error.$message }}
              </div>
            </td>
            <td class="actions">
              <button class="btn btn-primary btn-sm mt-0" @click="save">Ok</button>
              <button class="btn btn-secondary btn-sm mt-0" @click="itemAction('cancel')">Cancel</button>
            </td>
          </tr>


          <tr v-for="item in items" :key="item">
            <td>
              <span v-if="action.type === 'remove' || form.alias !== item.alias">{{ item.name }}</span>
              <input v-else type="text" class="form-control form-control-sm" v-model="form.name">
            </td>
            <td class="actions">


              <span v-if="!action.type || form.alias !== item.alias">
                 <a @click.prevent="itemAction('edit', item)" v-if="form.alias !== item.alias && item.isEditable">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-pencil"
                       viewBox="0 0 16 16">
                    <path
                        d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                  </svg>
                </a>

                <a @click="itemAction('remove', item)" class="remove" v-if="item.isDeletable">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash"
                       viewBox="0 0 16 16">
                    <path
                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                    <path fill-rule="evenodd"
                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                  </svg>
                </a>

              </span>


              <span v-if="action.type === 'edit' && form.alias === item.alias">
                <button class="btn btn-primary btn-sm mt-0" @click.prevent="save">Ok</button>
                <button class="btn btn-secondary btn-sm mt-0" @click="itemAction('cancel')">Cancel</button>
              </span>


              <span v-if="action.type === 'remove' && form.alias === item.alias">
                <button class="btn btn-danger btn-sm mt-0" @click.prevent="remove(item)">Ok</button>
                <button class="btn btn-secondary btn-sm mt-0" @click="itemAction('cancel')">Cancel</button>
              </span>


            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>

  </modal>
</template>

<script>
import Http from "../lib/Http";
import {useVuelidate} from "@vuelidate/core";
import {helpers, maxLength, required} from "@vuelidate/validators";
import Modal from "./Modal";

export default {
  name: "VocabularyModal",
  props: {
    uuid: String,
    alias: String
  },
  components: {
    Modal
  },
  created() {
    this.fetchVocabulary();
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        name: {
          required,
          maxLength: maxLength(255)
        },
        alias: {
          required,
          maxLength: maxLength(100),
          pattern: helpers.withMessage('Invalid alias format', (v) => v.match("^[a-zA-Z0-9\\-]+$")),
        }
      }
    };
  },
  data() {
    return {
      vocabulary: null,
      item: null,
      items: [],
      action: {
        type: null,
        uuid: null,
      },
      form: {
        uuid: null,
        name: null,
        type: this.type
      }
    }
  },
  methods: {
    fetchVocabulary: function () {

      let url;

      if (this.uuid)
        url = process.env.VUE_APP_CRM_SERVICE + '/vocabulary/' + this.uuid;
      else
        url = process.env.VUE_APP_CRM_SERVICE + '/vocabulary/alias:' + this.alias;

      return Http
          .get(url)
          .then(res => {
            this.vocabulary = res.content;
            this.items = res.content.items.sort((a, b) => a.weight > b.weight ? 1 : -1);
          });
    },
    itemAction: function (type, item) {
      this.action.type = type === 'cancel' ? null : type;
      if (item) {
        this.form.name = item.name;
        this.form.alias = item.alias;
      } else {
        this.form.name = null;
        this.form.alias = null;
      }
    },
    save: async function () {

      const result = await this.v$.form.$validate()

      if (!result) return

      if (this.form.alias)
        Http
            .put(process.env.VUE_APP_CRM_SERVICE + '/vocabulary/' + this.vocabulary.uuid + '/item', this.form)
            .then(() => {
              this.fetchVocabulary();
              this.action.type = false;
              this.form.name = null;
              this.form.alias = null;
            });
      else
        Http
            .post(process.env.VUE_APP_CRM_SERVICE + '/vocabulary/' + this.vocabulary.uuid + '/item', this.form)
            .then(() => {
              this.fetchVocabulary();
              this.action.type = false;
              this.form.name = null;
              this.form.alias = null;
            });
    },
    remove: function (item) {
      Http
          .del(process.env.VUE_APP_CRM_SERVICE + '/vocabulary/' + this.vocabulary.uuid + '/item/' + item.alias)
          .then(() => this.fetchVocabulary());
    }
  }
}
</script>

<style scoped>


.table-item tr:last-child td {
  border: none;
}

.table-item .actions {
  text-align: right;
  width: 1%;
  min-width: 140px;
}

.table-item .actions button:last-child {
  margin-left: 5px;
}

table.table-item td {
  padding: 8px 6px;
}

.add-new td{
  width: 49%;
}

.table-item .actions .remove {
  color: red;
}

</style>