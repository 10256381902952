<template>
  <head-panel>
    <template v-slot:body>Завантаження</template>
  </head-panel>


  <div class="wrapper">

    <tab :full="true"/>

    <hr/>

    <div class="ps-4  pe-4" v-if="!sources.length">
      Для ручного завантаження прайсу створіть відповідне джерело з csv підтримкою.
      <router-link to="/import/source/create" class="ms-2">Створити конфігурацію</router-link>
    </div>

    <div class="filter" v-if="currentSourceUuid">
      <div class="item">
        <label>Карта</label>
        <select class="form-select mapping-config" v-model="currentSourceUuid" @change="changeMap">
          <option :value="source.uuid" v-for="source in sources" :key="source">{{ source.name }}</option>
        </select>
      </div>
    </div>


    <div class="ps-4  pe-4" v-if="sources.length">

      <form id="upload">

        <div class="mt-4 mb-4">
          Максимальний розмір файлу <span class="badge bg-secondary">10 Мб</span>.
          Файл має бути формату <span class="badge bg-secondary">csv</span>,
          в кодуванні <span class="badge bg-secondary">UTF-8</span>,
          розділяти колонки символом <span class="badge bg-secondary">;</span> і містити заголовок.
          Завантажити приклад <a href="/" target="_blank">файлу</a><br/>

        </div>

        <div class="file-input">
          <input
              id="file"
              class="file"
              ref="file"
              name="file"
              type="file"
              v-on:change="fileUpload"
          />
          <label for="file">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 class="bi bi-cloud-upload" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/>
              <path fill-rule="evenodd"
                    d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z"/>
            </svg>
            Виберіть файл</label>
        </div>


      </form>

    </div>

  </div>
  <div class="wrapper " v-if="sources.length">

    <h2 class="ps-4">Останні завантаження

      <span class="refresh" title="Оновити" @click="refresh">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise"
             viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
          <path
              d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
        </svg>
    </span>

    </h2>

    <table class="table">
      <thead>
      <tr>
        <th scope="col">Дата</th>
        <th scope="col">Статус</th>
        <th scope="col">Прочитано</th>
        <th scope="col">Завантажено</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <template v-for="item in items" :key="item">
        <tr>
          <td>{{ $filters.dateTime(item.createdAt) }}</td>
          <td>
            <span class="status" v-bind:class="'status-'+item.status.toLowerCase()">
              {{ $filters.status(item.status) }}
            </span>
          </td>
          <td>{{ item.lineRead }}</td>
          <td>{{ item.itemUpdated }}</td>
          <td class="actions">
            <a @click="show(item)">Деталі</a>
          </td>
        </tr>
        <tr v-if="detail && detail.item.uuid === item.uuid">
          <td colspan="6" class="detail">

            <table class="table" v-if="detail.log && detail.log.length">

              <thead>
              <tr>
                <th scope="col">Помилки</th>
                <th scope="col">Деталі</th>
              </tr>
              </thead>

              <tbody>
              <tr v-for="log in detail.log" :key="log">
                <td>{{ log.message }}</td>
                <td>{{ log.input }}</td>
              </tr>
              </tbody>

            </table>

            <div v-else>
              Відсутні деталі
            </div>

          </td>
        </tr>
      </template>

      </tbody>
    </table>
  </div>


</template>

<script>
import Http from "../../../lib/Http";
import HeadPanel from "../../../components/HeadPanel";
import Tab from "../Tab";

export default {
  name: "PriceUpload",
  components: {
    HeadPanel, Tab
  },
  created() {
    this.fetchSources();
  },
  watch: {
  },
  data() {
    return {
      currentSourceUuid: null,
      sources: [],
      detail: null,
      items: [],
      pagination: {
        page: 0,
        total: 0
      },
    }
  },
  methods: {
    changeMap: function (e) {
      this.$router.replace("/import/upload/" + e.target.value)
          .then(() => this.fetch(e.target.value));
    },
    refresh: function (e) {
      e.target.classList.toggle('rotate');
      setTimeout(() => e.target.classList.remove('rotate'), 1000);
      this.fetch(this.currentSourceUuid);
    },
    fetch: function (id) {
      Http
          .get(process.env.VUE_APP_IMPORT_SERVICE + "/upload/" + id)
          .then((res) => {
            this.items = res.content;
            this.pagination.page = res.origin.headers.get("X-Current-Page") * 1;
            this.pagination.total = res.origin.headers.get("X-Page-Count") * 1;
          });
    },
    fetchSources: function () {
      Http
          .get(process.env.VUE_APP_IMPORT_SERVICE + "/upload/sources/CSV_PRICE")
          .then((res) => {
            this.sources = res.content;

            if (!this.sources.length)
              return;

            this.currentSourceUuid = this.$route.params.id ?? this.sources[0].uuid;

            if (this.currentSourceUuid)
              return this.fetch(this.currentSourceUuid);

          });
    },
    fileUpload: function (e) {
      const file = e.target.files[0];
      const fd = new FormData();
      fd.append('file', file, file.name);

      Http.post(process.env.VUE_APP_IMPORT_SERVICE + "/upload/price/" + this.currentSourceUuid, fd).then(() => {
        document.getElementById("upload").reset();
        this.fetch(this.currentSourceUuid);
      });
    },
    show: function (item) {
      this.detail = {
        item: item,
        log: []
      };
      Http
          .get(process.env.VUE_APP_IMPORT_SERVICE + "/upload/" + item.uuid + "/log")
          .then((res) => {
            this.detail.log = res.content;
          });
    }
  }
};
</script>

<style scoped>

.mapping-config {
  width: 300px;
  margin-top: 10px;
}

.refresh {
  display: contents;
  color: #000000;
  cursor: pointer;

}

.rotate {
  transform: rotate(360deg);
  transition-duration: 1s;
}

.file {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.file-input label {
  position: relative;
  width: 140px;
  height: 30px;
  border-radius: 6px;
  background: linear-gradient(40deg, #519df8, #248afd);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  transition: transform .2s ease-out;
}

.file-input label svg {
  margin-right: 10px;
}
</style>