import Sidebar from '../components/Sidebar.vue'
import Index from "../views/integration/Index.vue"

export default [
    {
        path: "/integration",
        name: "Integration",
        components: {
            default: Index,
            LeftSidebar: Sidebar
        }
    },
];