<template>
  <ul class="nav nav-pills">
    <li class="nav-item">
      <a class="nav-link" v-bind:class="tab==='main'?'active':''" aria-current="page" @click="tab='main'">Основне</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" v-bind:class="tab==='properties'?'active':''" aria-current="page" @click="tab='properties'"
         v-if="product">Параметри</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" v-bind:class="tab==='meta'?'active':''" @click="tab='meta'" v-if="product">Мета</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" v-bind:class="tab==='photo'?'active':''" @click="tab='photo'" v-if="product">Фото</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" v-bind:class="tab==='price'?'active':''" @click="tab='price'" v-if="product">Ціни</a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "ProductTabs",
  props: {
    product: Object,
    modelValue: String,
  },
  watch: {
    tab(val) {
      this.$emit('update:modelValue', val);
    }
  },
  data() {
    return {
      tab: 'main'
    }
  },
};
</script>