<template>
  <QuillEditor ref="quillEdit"
               theme="snow"
               :modules="modules"
               :toolbar="toolbar"
               v-model:content="description.default"
               contentType="html"/>
</template>

<script>
import '@vuepic/vue-datepicker/dist/main.css';
import {QuillEditor} from '@vueup/vue-quill'
import BlotFormatter from 'quill-blot-formatter'

export default {
  name: "ActionEdit",
  components: {
    QuillEditor
  },
  props: {
    modelValue: Object,
  },
  emits: [ 'update:modelValue'],
  setup: () => {
    const modules = [
      {
        name: 'blotFormatter',
        module: BlotFormatter,
        options: {/* options */}
      },
    ];

    return {modules}
  },
  watch: {
    modelValue(){
      this.description = this.modelValue;
    },
    description: {
      handler() {
        this.$emit('update:modelValue', this.description);
      },
      deep: true
    }
  },
  data() {
    return {
      description: {
        default: null
      },
      toolbar: {


        //   'syntax': true,
        //   'toolbar': [
        //     [{ 'font': [] }, { 'size': [] }],
        //     [ 'bold', 'italic', 'underline', 'strike' ],
        //     [{ 'color': [] }, { 'background': [] }],
        //     [{ 'script': 'super' }, { 'script': 'sub' }],
        //     [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block' ],
        //     [{ 'list': 'ordered' }, { 'list': 'bullet'}, { 'indent': '-1' }, { 'indent': '+1' }],
        //     [ 'direction', { 'align': [] }],
        //     [ 'link', 'image', 'video', 'formula' ],
        //     [ 'clean' ]
        //   ]


        container: [
          [{'header': []}, 'blockquote', 'code-block'],
          [ 'bold', 'italic', 'underline', 'strike' ],
          [{'color': []}, {'background': []}],
          [{ 'list': 'ordered' }, { 'list': 'bullet'}, { 'indent': '-1' }, { 'indent': '+1' }],
          ['link', 'image'],
          [{'align': []}], ['clean']
        ],
        handlers: {
          image: () => {


            const quill = this.$refs.quillEdit.getQuill();

            const tooltip = quill.theme.tooltip;
            const originalSave = tooltip.save;
            const originalHide = tooltip.hide;

            tooltip.save = function () {
              const range = quill.getSelection(true);
              const value = this.textbox.value;
              if (value) {
                quill.insertEmbed(range.index, 'image', value, 'user');
              }
            };
            // Called on hide and save.
            tooltip.hide = function () {
              tooltip.save = originalSave;
              tooltip.hide = originalHide;
              tooltip.hide();
            };
            tooltip.edit('image');
            tooltip.textbox.placeholder = 'Embed URL';


          }
        }
      }
    }
  },
};
</script>

<style scoped>

.group-title {
  margin-right: 20px;
}

.bi-pencil {
  cursor: pointer;
}

.bi-pencil:hover {
  opacity: 0.5;
}

.dropdown-menu a {
  cursor: pointer;
  margin-bottom: 4px;
}
</style>