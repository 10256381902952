<template>
  <head-panel>
    <template v-slot:body>
      Сторінка <span v-if="content"> {{ content.uuid }}</span>


      <router-link :to=" '/content/'+$route.params.tag">
        <button class="btn btn-secondary btn-sm btn-create-top ms-3" role="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-arrow-left-short" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
          </svg>
          Повернутись до списку
        </button>
      </router-link>


      <a @click="remove(content)" v-if="content && !content.system && content.deletable"
         class="btn btn-danger btn-sm btn-remove float-end">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash"
             viewBox="0 0 16 16">
          <path
              d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
          <path fill-rule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>
        Видалити акцію
      </a>

    </template>
    <template v-slot:top>&nbsp;</template>
  </head-panel>

  <div class="wrapper edit">

    <ul class="nav nav-pills">
      <li class="nav-item">
        <a class="nav-link" v-bind:class="tab==='main'?'active':''" @click="tab='main'">Основне</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" v-bind:class="tab==='meta'?'active':''" @click="tab='meta'" v-if="content">Мета</a>
      </li>
    </ul>

    <hr/>


    <Alert ref="alert"/>

    <form @submit.prevent="submit">


      <div class="tab" v-show="tab==='main'">


        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Назва</div>
          <div class="col-lg-8">
            <input type="text" class="form-control" v-model="form.name" :class="{ 'is-invalid' : v$.form.name.$error}">
            <div class="invalid-feedback" v-for="error of v$.form.name.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
        </div>

        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Статус</div>
          <div class="col-lg-8">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                     v-model="form.status">
              <label class="form-check-label" for="flexSwitchCheckChecked">

              </label>
            </div>
          </div>
        </div>


        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Тип</div>
          <div class="col-lg-8">

            <select class="form-select mapping-config" v-model="form.type">
              <option :value="type.value" v-for="type in types" :key="type">{{ type.label }}</option>
            </select>

            <div class="invalid-feedback" v-for="error of v$.form.type.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
        </div>


        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Теги</div>
          <div class="col-lg-8">
            <multiselect v-model="form.tag" tag-placeholder="Додати тег" placeholder="Додати тег" label="code"
                         track-by="code" :options="[]" :multiple="true" :taggable="true" @tag="addTag"
                         :class="{ 'is-invalid' : v$.form.tag.$error}"
            >
              <template #noOptions>
                Теги використовуються для пошуку банера(ів). Доволено використовувати символи для імені a-z, 0-9
              </template>
            </multiselect>
            <div class="invalid-feedback" v-for="error of v$.form.tag.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
        </div>


        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Path</div>
          <div class="col-lg-8">
            <input type="text" class="form-control" v-model="form.path" :class="{ 'is-invalid' : v$.form.path.$error}">
            <div class="invalid-feedback" v-for="error of v$.form.path.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
        </div>

        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Превью</div>
          <div class="col-lg-8">
            <div v-if="!form.preview">
              <input class="form-control" type="file" accept="image/png, image/jpeg" id="preview" ref="preview"
                     v-on:change="e => uploadPreview(e)">
            </div>

            <span class="preview" v-else>
                  <img v-bind:src="serviceName+'/uploads/'+form.preview.path" width="70" height="70"
                       class="img-thumbnail">
                   <svg @click="removePreview()" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        fill="currentColor" class="bi bi-trash3 delete" viewBox="0 0 16 16">
                      <path
                          d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                    </svg>
              </span>
          </div>
        </div>


        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Attachment</div>
          <div class="col-lg-8">
            <div class="file-input-wrapper">
              <input class="form-control" type="file" accept="image/png, image/jpeg" id="attachment" ref="attachment"
                     v-on:change="e => uploadAttachment(e)">
            </div>

            <div class="attachment mt-2" v-for="attachment in form.attachments" :key="attachment">
              <img v-bind:src="serviceName+'/uploads/'+attachment.path" width="70" height="70"
                   class="img-thumbnail">
              <span class="ms-2 me-2">uploads/{{ attachment.path }}</span>
              <svg @click="removeAttachment(attachment)" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                   fill="currentColor" class="bi bi-trash3 delete" viewBox="0 0 16 16">
                <path
                    d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
              </svg>
            </div>
          </div>
        </div>


        <div class="row ms-2 mb-4">
          <div class="col-xl-3">Опис</div>
          <div class="col-lg-8">
            <editor v-model="form.description"/>
          </div>
        </div>


        <div class="row ms-2 mb-4">
          <div class="col-xl-6">
            <button type="submit" class="btn btn-primary">Зберегти</button>
          </div>
        </div>

      </div>


      <div class="tab" v-show="tab==='meta'">

        <meta-form v-model="form.meta" :with-label="true"/>
        <div class="row ms-2 mb-4">
          <div class="col-xl-6">
            <button type="submit" class="btn btn-primary">Зберегти</button>
          </div>
        </div>

      </div>


    </form>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import ConfirmDialogue from '../../components/ConfirmDialog.vue'
import Alert from "../../components/Alert";
import Multiselect from "vue-multiselect";
import {useVuelidate} from "@vuelidate/core";
import {helpers, maxLength, required} from "@vuelidate/validators";
import MetaForm from "../../components/MetaForm";
import Editor from "@/components/Editor.vue";

export default {
  name: "Edit",
  components: {
    HeadPanel, ConfirmDialogue, Alert, MetaForm, Multiselect, Editor
  },
  created() {
    if (this.$route.params.id)
      this.fetch(this.$route.params.id);
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        name: {
          required,
          maxLength: maxLength(255)
        },
        status: {
          required
        },
        type: {
          required
        },
        description: {},
        path: {
          required,
          maxLength: maxLength(255),
          pattern: helpers.withMessage('Invalid path format', (v) => v.match("^[a-z0-9\\-.:_/]+$"))
        },
        tag: {
          $each: helpers.forEach({
            code: {
              maxLength: maxLength(50),
              pattern: helpers.withMessage('Invalid tag format', (v) => v.match("^[a-z0-9\\-]+$"))
            }
          })
        }
      }
    };
  },
  computed: {
    statuses() {
      return [
        {value: 'ACTIVE', label: 'ACTIVE'},
        {value: 'DISABLED', label: 'DISABLED'},
      ];
    },
    types() {
      return [
        {value: 'PAGE', label: 'PAGE'},
        {value: 'EMBEDDED', label: 'EMBEDDED'},
      ];
    }
  },
  data() {
    return {
      serviceName: process.env.VUE_APP_MEDIA_SERVICE,
      tab: 'main',
      content: null,
      form: {
        name: null,
        status: true,
        type: true,
        preview: null,
        attachments: [],
        body: {default: null},
        tag: [{code: this.$route.params.tag}],
        path: null,
        meta: null
      }
    }
  },
  methods: {
    fetch: function (uuid) {
      Http
          .get(process.env.VUE_APP_CONTENT_SERVICE + "/content/" + uuid)
          .then((res) => {
            this.content = res.content;
            this.form.name = this.content.name;
            this.form.status = this.content.status === 'ACTIVE';
            this.form.type = this.content.type;
            this.form.path = this.content.path;
            this.form.description = {default: this.content.description};


            this.form.preview = res.content.preview ? {
              uuid: res.content.preview.uuid,
              path: res.content.preview.path + "/" + res.content.preview.name
            } : null;

            this.form.attachments = this.content.attachments.map(e => {
              return {
                uuid: e.uuid,
                path: e.path + "/" + e.name
              }
            });

            this.form.meta = this.content.meta ? {
              title: {default: this.content.meta.title},
              keywords: {default: this.content.meta.keywords},
              description: {default: this.content.meta.description},
            } : null;

            this.form.tag = this.content.tag ? this.content.tag.map(t => {
              return {code: t};
            }) : [];
          });
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag
      }
      this.form.tag.push(tag)
    },
    submit: async function () {

      const integration = Http.session.integrations.content;

      const result = await this.v$.$validate()

      if (!result) return

      let data = {
        name: this.form.name,
        status: this.form.status ? 'ACTIVE' : 'DISABLED',
        type: this.form.type,
        preview: this.form.preview ? this.form.preview.uuid : null,
        attachments: this.form.attachments.map(e => e.uuid),
        description: this._description(this.form.description),
        tag: this.form.tag.map(t => t.code),
        path: this.form.path,
        meta: {
          title: this.form.meta?.title.default ?? null,
          keywords: this.form.meta?.keywords.default ?? null,
          description: this.form.meta?.description.default ?? null,
        }
      };

      if (!this.content)
        Http
            .post(process.env.VUE_APP_CONTENT_SERVICE + "/content/" + integration.uuid, data)
            .then((res) => {
              this.$router.replace("/content/" + this.$route.params.tag + "/" + res.content.uuid);
              this.fetch(res.content.uuid);
              this.$refs.alert.success('Успішно збережено');
            });
      else
        Http
            .put(process.env.VUE_APP_CONTENT_SERVICE + "/content/" + this.$route.params.id, data)
            .then((res) => {
              this.fetch(res.content.uuid);
              this.$refs.alert.success('Успішно збережено');
            });
    },
    remove: function (item) {
      this.$refs.confirmDialogue.show({
        title: 'Видалення',
        message: 'Ви дійсно хочете видалити сторінку?',
        okButton: 'Так',
        cancelButton: 'Ні',
      }).then((ok) => {
        if (ok)
          Http
              .del(process.env.VUE_APP_CONTENT_SERVICE + "/content/" + item.uuid)
              .then(() => {
                this.$router.replace("/content/" + this.$route.params.tag);
              });
      })
    },
    uploadPreview: function (e) {

      const integration = Http.session.integrations.content;

      const file = e.target.files[0];
      const fd = new FormData();
      fd.append('file', file, file.name);

      Http.post(process.env.VUE_APP_CONTENT_SERVICE + "/file/" + integration.uuid + "/upload", fd)
          .then(res => {
            this.form.preview = {
              uuid: res.content.uuid,
              path: res.content.path + "/" + res.content.name
            };
          });
    },
    uploadAttachment: function (e) {

      const integration = Http.session.integrations.content;

      const file = e.target.files[0];
      const fd = new FormData();
      fd.append('file', file, file.name);

      Http.post(process.env.VUE_APP_CONTENT_SERVICE + "/file/" + integration.uuid + "/upload", fd)
          .then(res => {
            this.form.attachments.push({
              uuid: res.content.uuid,
              path: res.content.path + "/" + res.content.name
            });
            e.target.reset();
          });
    },
    removePreview: function () {
      this.form.preview = null;
    },
    removeAttachment: function (attachment) {
      this.form.attachments = this.form.attachments.filter(e => e.uuid !== attachment.uuid);
    },
    _description: function (description) {
      return description && description.default && description.default.replace(/<\/?[^>]+(>|$)/g, "") ? description.default : null;
    }
  }
};
</script>

<style scoped>


.preview {
  float: left;

  position: relative;
}

svg.delete {
  color: red;
  cursor: pointer;
  margin-left: 10px;
}

</style>