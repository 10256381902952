import Sidebar from '../components/Sidebar.vue'
import BrandList from "@/views/brand/Index.vue";
import BrandEdit from "@/views/brand/Edit.vue";

export default [
    {
        path: "/brand",
        name: "Brand",
        components: {
            default: BrandList,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/brand/create",
        name: "BrandCreate",
        components: {
            default: BrandEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/brand/:id",
        name: "BrandEdit",
        components: {
            default: BrandEdit,
            LeftSidebar: Sidebar
        }
    }
];