import Sidebar from '../components/Sidebar.vue'
import Index from "../views/review/manage/Index.vue"
import Edit from "../views/review/manage/Edit.vue"

export default [
    {
        path: "/review/manage",
        name: "Reviews",
        components: {
            default: Index,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/review/manage/create",
        name: "ReviewCreate",
        components: {
            default: Edit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/review/manage/:id",
        name: "ReviewEdit",
        components: {
            default: Edit,
            LeftSidebar: Sidebar
        }
    }
];