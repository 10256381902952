<template>

  <div class="vocabulary-select">

    <multiselect v-model="model" tag-placeholder="Додати статус" placeholder="Додати статус"
                 label="name"
                 track-by="alias"
                 :showLabels="false"
                 :options="forwards"
                 :multiple="false" :taggable="false" :searchable="false" :hideSelected="true"
                 @input="change" @select="change" @remove="change"
    >
      <template #noOptions>
        Список дозволених переходів на статуси
      </template>
    </multiselect>

    <a @click.prevent="this.isEditModalVisible = true" class="edit-vocabulary">Редагувати список</a>

    <vocabulary-modal  :alias="alias" v-if="isEditModalVisible" :is-visible="isEditModalVisible"
                      @close="closeEditModal"/>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Multiselect from "vue-multiselect";
import VocabularyModal from "../components/VocabularyModal";
import Http from "../lib/Http";
import ConfirmDialogue from "./ConfirmDialog.vue";

export default {
  name: "VocabularySelect",
  emits: ['change', 'update:modelValue'],
  props: {
    modelValue: String,
    alias: String,
    confirmation: Boolean
  },
  components: {
    Multiselect, VocabularyModal, ConfirmDialogue
  },
  created() {
    this.fetch();
  },
  watch: {
    modelValue(alias) {
      this.model = this.options.find(e => e.alias === alias);
      this.forwards = this.model.isForward ? this.options.filter(e => this.model.forwards.includes(e.alias)) : this.options;
    },
  },
  data() {
    return {
      isEditModalVisible: false,
      options: [],
      forwards: [],
      previousStatusValue: null,
      model: null,
    }
  },
  methods: {
    confirm: function () {
      this.$refs.confirmDialogue.show({
        title: 'Зміна',
        message: 'Ви дійсно хочете змінити стан?',
        okButton: 'Так',
        cancelButton: 'Ні',
      }).then((ok) => {

        if (ok) {
          this.$emit('update:modelValue', this.model.alias); // model update as String alias
          this.$emit('change', this.model); // on change send full object
          this.previousStatusValue = this.model.alias;
        } else {
          this.discard();
        }
      })
    },

    discard: function (){
      this.model = this.options.find(e => e.alias === this.previousStatusValue);
      this.forwards = this.model.isForward ? this.options.filter(e => this.model.forwards.includes(e.alias)) : this.options;
    },

    change: function () {

      if (this.confirmation) {
        this.confirm(this.model);
        return;
      }

      setTimeout(() => {
        this.$emit('update:modelValue', this.model.alias); // model update as String alias
        this.$emit('change', this.model); // on change send full object
      }, 10);
    },
    fetch: async function () {
     return  Http
          .get(process.env.VUE_APP_CRM_SERVICE + '/vocabulary/alias:' + this.alias)
          .then((res) => {
            this.options = res.content.items;
            this.model = this.options.find(e => e.alias === this.modelValue);
            this.previousStatusValue = this.model.alias;
            this.forwards = this.model.isForward ? this.options.filter(e => this.model.forwards.includes(e.alias)) : this.options;
          });
    },
    closeEditModal: function () {
      this.isEditModalVisible = false;
    },
  }
}
</script>

<style>

.vocabulary-select .multiselect {
  max-width: 500px;
}

.vocabulary-select .multiselect__select {
  background-size: 16px 12px;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.vocabulary-select .multiselect__select:before {
  display: none;
}

.vocabulary-select .multiselect .multiselect__single {
  font-family: 'Roboto', 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.edit-vocabulary {
  display: block;
  margin-top: 4px;
  text-decoration: none;
  border-bottom: dashed;
  float: left;
  cursor: pointer;
  font-size: 12px;
}
</style>