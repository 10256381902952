<template>
  <head-panel>
    <template v-slot:body>
      Словник <span v-if="vocabulary"> {{ vocabulary.uuid }}</span>

      <router-link :to="'/vocabulary'">
        <button class="btn btn-secondary btn-sm btn-create-top ms-3" role="button"><i class='bx bx-arrow-back icon'></i>
          назад
        </button>
      </router-link>

    </template>
  </head-panel>

  <div class="wrapper ps-3 pe-3 edit">

    <Alert ref="alert"/>

    <form @submit.prevent="submit" id="upload">

      <div class="row mb-4">
        <div class="col-xl-3">Назва</div>
        <div class="col-lg-8">
          <input type="text" class="form-control" v-model="form.name" :class="{ 'is-invalid' : v$.form.name.$error}">
          <div class="invalid-feedback" v-for="error of v$.form.name.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-xl-6">
          <button type="submit" class="btn btn-primary">Зберегти</button>
        </div>
      </div>

    </form>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import ConfirmDialogue from '../../components/ConfirmDialog.vue'
import Alert from "../../components/Alert";
import {useVuelidate} from "@vuelidate/core";
import {maxLength, required} from "@vuelidate/validators";

export default {
  name: "Edit",
  components: {
    HeadPanel, ConfirmDialogue, Alert
  },
  created() {
    if (this.$route.params.id)
      this.fetch(this.$route.params.id);
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        name: {
          required,
          maxLength: maxLength(255),
        }
      }
    };
  },
  data() {
    return {
      vocabulary: null,
      form: {
        name: null
      }
    }
  },
  methods: {
    fetch: function (uuid) {
      Http
          .get(process.env.VUE_APP_CRM_SERVICE + "/vocabulary/" + uuid)
          .then((res) => {
            this.vocabulary = res.content;
            this.form = this.vocabulary;
          });
    },
    submit: async function () {

      const result = await this.v$.$validate()

      if (!result) return

      let data = {
        name: this.form.name,
      };

      if (!this.vocabulary)
        Http
            .post(process.env.VUE_APP_CRM_SERVICE + "/vocabulary", data)
            .then((res) => {
              this.$router.replace("/vocabulary/" + res.content.uuid);
              this.fetch(res.content.uuid);
              this.$refs.alert.success('Успішно збережено');
            });
      else
        Http
            .put(process.env.VUE_APP_CRM_SERVICE + "/vocabulary/" + this.$route.params.id, data)
            .then((res) => {
              this.fetch(res.content.uuid);
              this.$refs.alert.success('Успішно збережено');
            });
    }
  }
};
</script>

<style scoped>


</style>