import Sidebar from '../components/Sidebar.vue'
import SeoIndex from "@/views/seo/Index.vue";

export default [
    {
        path: "/seo/meta",
        name: "SeoMeta",
        components: {
            default: SeoIndex,
            LeftSidebar: Sidebar
        }
    },
];
