import Sidebar from '../components/Sidebar.vue'
import SourceLog from "../views/import/source/Log.vue";
import SourceList from "../views/import/source/Index.vue";
import SourceEdit from "../views/import/source/Edit.vue";
import MappingConfig from "../views/import/mapping/Config.vue";
import Mapping from "../views/import/mapping/Mapping.vue";
import PriceUpload from "../views/import/upload/UploadPrice.vue";
import Currency from "../views/import/Currency.vue";

export default [
    {
        path: "/import/source/log/:id",
        name: "SourceLog",
        components: {
            default: SourceLog,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/import/source",
        name: "Source",
        components: {
            default: SourceList,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/import/source/create",
        name: "SourceCreate",
        components: {
            default: SourceEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/import/source/:id",
        name: "SourceEdit",
        components: {
            default: SourceEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/import/config",
        name: "MappingConfig",
        components: {
            default: MappingConfig,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/import/config/create",
        name: "MappingConfigCreate",
        components: {
            default: MappingConfig,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/import/config/:id",
        name: "MappingConfigEdit",
        components: {
            default: MappingConfig,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/import/mapping",
        name: "Mapping",
        components: {
            default: Mapping,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/import/mapping/:id",
        name: "MappingId",
        components: {
            default: Mapping,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/import/upload",
        name: "PriceUpload",
        components: {
            default: PriceUpload,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/import/upload/:id",
        name: "PriceUploadId",
        components: {
            default: PriceUpload,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/import/currency",
        name: "Currency",
        components: {
            default: Currency,
            LeftSidebar: Sidebar
        }
    }
];