<template>
  <head-panel>
    <template v-slot:body>Відгуки
      <router-link to="/review/manage/create" class="btn btn-primary btn-sm btn-create ms-2">Створити</router-link>
    </template>
  </head-panel>

  <div class="wrapper">

    <tab/>

    <hr/>

    <div class="filter">
      <div class="item">
        <SearchFilter
            label="Пошук eid"
            name="search"
            path="/review/manage"
            v-model="search"
        />
      </div>
      <div class="item">
        <DropdownFilter
            label="Статус"
            name="moderate"
            path="/review/manage"
            :items="statuses"
        />
      </div>
    </div>
    <hr/>

    <table class="table">
      <thead>
      <tr>
        <th scope="col">EID</th>
        <th scope="col">Автор</th>
        <th scope="col">Група</th>
        <th scope="col">Статус модерації</th>
        <th scope="col">Відгук</th>
        <th scope="col"></th>
        <th scope="col">Створений</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <template v-for="item in reviews" :key="item">
        <tr class="menu">
          <td>{{ item.eid }}</td>
          <td>{{ item.author.name }} {{ item.name ? '(' + item.name + ')' : '' }}</td>
          <td>{{ item.subject.name }}</td>
          <td>{{ item.moderate }}</td>
          <td>{{ item.comment ? item.comment.substring(0, 30) + '...' : '' }}</td>
          <td>{{ item.path }}</td>
          <td>{{ $filters.dateTime(item.createdAt) }}</td>
          <td class="actions">
            <router-link :to="'/review/manage/'+item.uuid">Ред.</router-link>
          </td>
        </tr>
      </template>

      </tbody>
    </table>


  </div>

</template>

<script>
import Http from "../../../lib/Http";
import HeadPanel from "../../../components/HeadPanel.vue";
import Tab from "../Tab.vue";
import DropdownFilter from "@/components/DropdownFilter.vue";
import SearchFilter from "../../../components/SearchFilter";

export default {
  name: "Menu",
  components: {
    DropdownFilter,
    HeadPanel, Tab, SearchFilter
  },
  created() {
    this.fetch();
  },
  watch: {
    $route() {
      this.fetch();
    },
  },
  computed: {
    statuses() {
      return [
        {value: 'APPROVE', label: 'APPROVE'},
        {value: 'AUTO_APPROVE', label: 'AUTO_APPROVE'},
        {value: 'NEED_MODERATE', label: 'NEED_MODERATE'},
      ];
    },
  },
  data() {
    return {
      search: null,
      reviews: []
    }
  },
  methods: {
    fetch: function () {
      const integration = Http.session.integrations.review;

      const query = {
        realm: integration.uuid
      };

      if (this.$route.query.search)
        query.eid = this.$route.query.search;

      if (this.$route.query.moderate)
        query.moderate = this.$route.query.moderate;

      Http
          .get(process.env.VUE_APP_REVIEW_SERVICE + '/review/search?' + new URLSearchParams(query).toString())
          .then((res) => {
            this.reviews = res.content;
          });
    },
  }
};
</script>

<style scoped>

</style>
