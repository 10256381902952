<template>
  <head-panel>
    <template v-slot:body>
      Програми лояльності
    </template>
  </head-panel>

  <div class="wrapper ps-3 pe-3">

    <tab :full="true"/>

    <hr/>

    <Alert ref="alert"/>


    <form @submit.prevent="submit">

      <h2>Промокод дня нових клієнтів</h2>

      <p class="fw-lighter">
        Надає клієнту індивідуальний промо код при першій авторизації(реєстрація). Код може бути застосований до будь
        якого неакційного продукту.
        Для цього вкажіть розмір у відсотках чи залиште пустим що б не застосовувати код.
      </p>

      <div class="row  mb-4">
        <div class="col-xl-3">Дисконт</div>
        <div class="col-lg-2">
          <div class="input-group" :class="{ 'is-invalid' : v$.form.registrationPromo.discount.$error}">
            <input type="text" class="form-control" v-model="form.registrationPromo.discount"
                   :class="{ 'is-invalid' : v$.form.registrationPromo.discount.$error}">

            <span class="input-group-text">%</span>
          </div>
          <div class="invalid-feedback" v-for="error of v$.form.registrationPromo.discount.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>


      <div class="row  mb-4">
        <div class="col-xl-3">Термін дії</div>
        <div class="col-lg-2">

          <div class="input-group">

            <input type="text" class="form-control" v-model="form.registrationPromo.validity"
                   :class="{ 'is-invalid' : v$.form.registrationPromo.validity.$error}">

            <span class="input-group-text">міс.</span>

            <div class="invalid-feedback" v-for="error of v$.form.registrationPromo.validity.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>

          </div>
        </div>
      </div>


      <div class="row  mb-4">
        <div class="col-xl-6">
          <button type="submit" class="btn btn-primary">Зберегти</button>
        </div>
      </div>

    </form>

  </div>

  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import '@vuepic/vue-datepicker/dist/main.css';
import Http from "../../../lib/Http";
import HeadPanel from "../../../components/HeadPanel.vue";
import ConfirmDialogue from '../../../components/ConfirmDialog.vue'
import Alert from "../../../components/Alert.vue";
import {useVuelidate} from "@vuelidate/core";
import {maxValue, minValue, numeric} from "@vuelidate/validators";
import Tab from "@/views/marketing/Tab.vue";

export default {
  name: "LoyaltyEdit",
  components: {
    HeadPanel, ConfirmDialogue, Alert, Tab
  },
  created() {
    this.fetch();
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        registrationPromo: {
          discount: {
            numeric,
            minValue: minValue(1),
            maxValue: maxValue(100)
          },
          validity: {},
        }
      }
    };
  },
  data() {
    return {
      form: {
        registrationPromo: {
          discount: null,
          validity: null,
        }
      }
    }
  },
  methods: {

    fetch: function () {
      Http
          .get(process.env.VUE_APP_CRM_SERVICE + "/loyalty/setting")
          .then((res) => {

            for (let setting of res.content) {

              if (setting.type === 'CASHBACK')
                this.form.cashback = setting.data;

              if (setting.type === 'REGISTRATION_PROMO_CODE')
                this.form.registrationPromo = setting.data;

            }

          });
    },

    submit: async function () {

      const result = await this.v$.$validate()

      if (!result) return

      let data = {
        discount: this.form.registrationPromo.discount,
        validity: this.form.registrationPromo.validity
      }

      Http
          .post(process.env.VUE_APP_CRM_SERVICE + "/loyalty/setting/registration", data)
          .then(() => {
            this.$refs.alert.success('Успішно збережено');
          })
          .catch((e) => {
            this.$refs.alert.danger(e.message);
          });
    }
  }
};
</script>

<style scoped>
.discount-condition, .uses-condition {
  max-width: 200px;
}
</style>