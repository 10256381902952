<template>
  <head-panel>
    <template v-slot:body>
      Група каталогу <span v-if="group"> {{ group.uuid }}</span>

      <router-link v-if="this.catalog" :to="'/catalog/'+this.catalog.uuid+'/list'">
        <button class="btn btn-secondary btn-sm btn-create-top ms-3" role="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-arrow-left-short" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
          </svg>
          Повернутись до списку
        </button>
      </router-link>

      <a @click="remove" class="btn btn-danger btn-sm btn-remove float-end"
         v-if="group && this.$session.can('owner', 'catalog_manage')">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash"
             viewBox="0 0 16 16">
          <path
              d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
          <path fill-rule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>
        Видалити групу
      </a>

    </template>
    <template v-slot:top>&nbsp;</template>


  </head-panel>

  <div class="wrapper">


    <form @submit.prevent="submit">


      <Alert ref="alert"/>


      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Шлях</div>
        <div class="col-lg-8">
          <input type="text" class="form-control" v-model="form.url" :disabled="group"
                 :class="{ 'is-invalid' : v$.form.url.$error}">
          <div class="invalid-feedback" v-for="error of v$.form.url.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>


      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Вага</div>
        <div class="col-lg-8">
          <input type="text" class="form-control" v-model="form.weight"
                 :class="{ 'is-invalid' : v$.form.weight.$error}">
          <div class="invalid-feedback" v-for="error of v$.form.weight.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>


      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Переклади</div>
        <div class="col-lg-8">
          <locale-form v-model="form.locales"/>
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-6">
          <button type="submit" class="btn btn-primary">Зберегти</button>
        </div>
      </div>

    </form>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import ConfirmDialogue from '../../components/ConfirmDialog.vue'
import LocaleForm from "../../components/LocaleForm";
import {useVuelidate} from "@vuelidate/core";
import {integer, required} from "@vuelidate/validators";
import Alert from "../../components/Alert.vue";


export default {
  name: "Edit",
  components: {
    HeadPanel, ConfirmDialogue, LocaleForm, Alert
  },
  created() {
    if (this.$route.params.catalog)
      this.fetch(this.$route.params.catalog);
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        url: {required},
        locales: {required},
        weight: {integer}
      }
    };
  },
  data() {
    return {
      catalog: null,
      group: null,
      form: {
        locales: null,
        url: null,
        weight: null,
      },
    }
  },
  methods: {
    fetch: function (catalog) {
      Http
          .get(process.env.VUE_APP_CATALOG_SERVICE + "/manage/catalog/" + catalog)
          .then((res) => {
            this.catalog = res.content;

            if (this.$route.params.group) {
              this.group = res.content.groups.find(e => e.uuid === this.$route.params.group);
              this.form = this.group;
            }
          });
    },

    submit: async function () {

      const result = await this.v$.$validate()

      if (!result) return

      this.catalog.groups.upsert({
        uuid: this.group ? this.group.uuid : crypto.randomUUID(),
        locales: this.form.locales,
        url: this.form.url,
        weight: this.form.weight,
        shortcuts: this.group ? this.group.shortcuts : []
      }, "uuid", this.group ? this.group.uuid : null);

      Http
          .put(process.env.VUE_APP_CATALOG_SERVICE + "/manage/catalog/" + this.catalog.uuid, this.catalog)
          .then(() => {
            this.$refs.alert.success('Успішно збережено');
          })
          .catch((e) => {
            this.$refs.alert.danger(e.message);
          });
    },
    remove: function () {
      this.$refs.confirmDialogue.show({
        title: 'Видалення',
        message: "Ви дійсно хочете видалити групу і пов'язані з ним  дані?",
        okButton: 'Так',
        cancelButton: 'Ні',
      }).then((ok) => {
        if (ok) {
          this.catalog.groups = this.catalog.groups.filter(e => e.uuid !== this.group.uuid);
          Http
              .put(process.env.VUE_APP_CATALOG_SERVICE + "/manage/catalog/" + this.catalog.uuid, this.catalog)
              .then(() => {
                this.$router.replace("/catalog/" + this.catalog.uuid + "/list");
              });
        }

      })
    },
  }
};
</script>

<style scoped>

</style>