<template>

  <div class="row ms-2 mb-4">
    <div class="col-xl-3">Client</div>
    <div class="col-lg-8">
      <input type="text" class="form-control" v-model="form.clientId" :class="{ 'is-invalid' : v$.form.clientId.$error}">
      <div class="invalid-feedback" v-for="error of v$.form.clientId.$errors" :key="error.$uid">
        {{ error.$message }}
      </div>
    </div>
  </div>

  <div class="row ms-2 mb-4">
    <div class="col-xl-3">Secret</div>
    <div class="col-lg-8">
      <input type="text" class="form-control" v-model="form.secret" :class="{ 'is-invalid' : v$.form.secret.$error}" :placeholder="processing?'***************': ''">
      <div class="invalid-feedback" v-for="error of v$.form.secret.$errors" :key="error.$uid">
        {{ error.$message }}
      </div>
    </div>
  </div>

</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {maxLength, required, requiredIf} from "@vuelidate/validators";

export default {
  name: "liqpay",
  props: {
    modelValue: Object,
    processing: Object,
  },
  created() {
    this.form = this.modelValue;
  },
  watch: {
    form: {
      handler(val) {
        this.$emit('update:modelValue', val)
      },
      deep: true
    }
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        clientId: {
          required,
          maxLength: maxLength(255)
        },
        secret: {
          required:requiredIf(!this.processing),
          maxLength: maxLength(255)
        }
      }
    };
  },
  data() {
    return {
      form: {
        clientId: null,
        secret: null
      }
    }
  },
  methods: {
  }
};
</script>

<style scoped>

</style>