import Sidebar from '../components/Sidebar.vue'
import OrderList from "@/views/order/Index.vue";
import OrderEdit from "@/views/order/Edit.vue";

export default [
    {
        path: "/order",
        name: "Orders",
        components: {
            default: OrderList,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/order/:id",
        name: "OrderEdit",
        components: {
            default: OrderEdit,
            LeftSidebar: Sidebar
        }
    }
];