<template>
  <head-panel>
    <template v-slot:body>Записи словника
      <router-link :to="'/vocabulary/'+this.$route.params.id+'/create'" class="btn btn-primary btn-sm btn-create ms-2">
        Створити
      </router-link>
    </template>
  </head-panel>

  <div class="wrapper">

    <div class="filter">
      <div class="item ">
        <SearchFilter
            label="Швидкий пошук"
            name="search"
            :path="vocabulary ? '/vocabulary/'+vocabulary.uuid+'/item' : ''"
        />
      </div>
    </div>

    <table class="table">
      <thead>
      <tr>
        <th scope="col">Назва</th>
        <th scope="col">Створений</th>
        <th scope="col">Вага</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in items" :key="item">
        <td>{{ item.name }}</td>
        <td>{{ $filters.dateTime(item.createdAt) }}</td>
        <td>{{ item.weight }}</td>

        <td class="actions">
          <router-link :to="'/vocabulary/'+this.$route.params.id+'/item/'+item.alias" v-if="item.isEditable">Ред.
          </router-link>
          <a @click="remove(item)" v-if="item.isDeletable">Видалити</a>
        </td>
      </tr>
      </tbody>
    </table>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import SearchFilter from "../../components/SearchFilter";
import ConfirmDialogue from "../../components/ConfirmDialog";

export default {
  name: "Index",
  components: {
    HeadPanel, SearchFilter, ConfirmDialogue
  },
  created() {
    this.fetch();
  },
  watch: {
    $route(n, o) {
      if (n.path === o.path)
        this.items.filter(e => e.name.includes(this.$route.query.search))
    }
  },
  data() {
    return {
      vocabulary: null,
      origins: [],
      items: [],
    }
  },
  methods: {
    fetch: function () {
      Http
          .get(process.env.VUE_APP_CRM_SERVICE + "/vocabulary/" + this.$route.params.id)
          .then((res) => {
            this.vocabulary = res.content;
            this.origins = res.content.items.sort((a, b) => a.weight > b.weight ? 1 : -1);
            this.items = this.origins;
          });
    },
    remove: function (item) {
      this.$refs.confirmDialogue.show({
        title: 'Видалення',
        message: 'Ви дійсно хочете видалити запис?',
        okButton: 'Так',
        cancelButton: 'Ні',
      }).then((ok) => {
        if (ok)
          Http
              .del(process.env.VUE_APP_CRM_SERVICE + "/vocabulary/" + this.$route.params.id + '/item/' + item.alias)
              .then(() => this.fetch());
      })
    }
  }
};
</script>

<style scoped>

</style>