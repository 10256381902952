import Sidebar from '../components/Sidebar.vue'
import TagList from "@/views/tag/Index.vue";
import TagEdit from "@/views/tag/Edit.vue";

export default [
    {
        path: "/tag",
        name: "Tag",
        components: {
            default: TagList,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/tag/create",
        name: "TagCreate",
        components: {
            default: TagEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/tag/:id",
        name: "TagEdit",
        components: {
            default: TagEdit,
            LeftSidebar: Sidebar
        }
    }
];