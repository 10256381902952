<template>
  <Alert ref="alert"/>
  <form @submit.prevent="submit">
    <div class="row ms-2 mb-4">
      <div class="col-xl-3">Мета теги головної</div>
      <div class="col-lg-8">
        <meta-form v-model="form.main" :with-placeholder="true"/>
      </div>
    </div>

    <hr>

    <div class="row ms-2 mb-4">
      <div class="col-xl-3">
        Мета теги категорії
        <div class="help" v-pre>Патерни:<br/>{{site.name}} - Назвва сайту<br/>{{category.localName}} - Назва категорії<br/>{{brands}} - Назви брендів</div>
      </div>
      <div class="col-lg-8">
        <meta-form v-model="form.category" :with-placeholder="true"/>
      </div>
    </div>

    <hr>

    <div class="row ms-2 mb-4">
      <div class="col-xl-3">
        Мета теги бренду
        <div class="help" v-pre>Патерни:<br/>{{site.name}} - Назвва сайту<br/>{{brand.localName}} - Назва бренду</div>
      </div>
      <div class="col-lg-8">
        <meta-form v-model="form.brand" :with-placeholder="true"/>

      </div>
    </div>

    <hr>

    <div class="row ms-2 mb-4">
      <div class="col-xl-3">
        Мета теги сторінки брендів
        <div class="help" v-pre>Патерни:<br/>{{site.name}} - Назвва сайту</div>
      </div>
      <div class="col-lg-8">
        <meta-form v-model="form.brands" :with-placeholder="true"/>
      </div>
    </div>

    <hr>

    <div class="row ms-2 mb-4">
      <div class="col-xl-3">
        Мета теги продукту
        <div class="help" v-pre>Патерни:<br/>{{site.name}} - Назвва сайту<br/>{{product.localName}} - Назва продукту</div>
      </div>
      <div class="col-lg-8">
        <meta-form v-model="form.product" :with-placeholder="true"/>
      </div>
    </div>

    <hr>

    <div class="row ms-2 mb-4">
      <div class="col-xl-3">
        Мета теги сторінки
        <div class="help" v-pre>Патерни:<br/>{{site.name}} - Назвва сайту<br/>{{page.title}} - Назва сторінки</div>
      </div>
      <div class="col-lg-8">
        <meta-form v-model="form.page" :with-placeholder="true"/>
      </div>
    </div>

    <div class="row ms-2 mb-4">
      <div class="col-xl-6">
        <button type="submit" class="btn btn-primary">Зберегти</button>
      </div>
    </div>
  </form>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import Http from "@/lib/Http";
import Alert from "@/components/Alert.vue";
import MetaForm from "@/components/MetaForm.vue";

export default {
  name: "_meta",
  components: {
    MetaForm, Alert
  },
  created() {
    this.fetch();
  },
  // mounted() {
  //   // for (const setting of this.settings) {
  //   //   if (setting.name === 'default:page:meta') {
  //   //     this.form.main = setting.value.main;
  //   //     this.form.category = setting.value.category;
  //   //     this.form.brand = setting.value.brand;
  //   //     this.form.brands = setting.value.brands;
  //   //     this.form.product = setting.value.product;
  //   //     this.form.page = setting.value.page;
  //   //   }
  //   // }
  // },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {};
  },
  data() {
    return {
      form: {
        main: null,
        category: null,
        brand: null,
        brands: null,
        product: null,
        page: null,
      }
    }
  },
  methods: {
    fetch: function () {
      Http
          .get(process.env.VUE_APP_CRM_SERVICE + "/setting")
          .then((res) => {
            this.settings = res.content;
              for (const setting of this.settings) {
                if (setting.name === 'default:page:meta') {
                  this.form.main = setting.value.main;
                  this.form.category = setting.value.category;
                  this.form.brand = setting.value.brand;
                  this.form.brands = setting.value.brands;
                  this.form.product = setting.value.product;
                  this.form.page = setting.value.page;
                }
              }
          });
    },
    submit: async function () {

      const result = await this.v$.$validate()

      if (!result) return;

      return Http
          .post(process.env.VUE_APP_CRM_SERVICE + "/setting", {
            "name": "default:page:meta",
            "description": "Налаштування мета тегів",
            "type": "JSON",
            "value": this.form,
          })
          .then(() => {
            this.$refs.alert.success('Успішно збережено');
          });
    }
  }
}
</script>

<style scoped>
.help {
  font-size: 13px;
  color: #6c757d;
}
</style>