<template>

  <div class="row ms-2 mb-4">
    <div class="col-xl-3">Карта</div>
    <div class="col-lg-8">
      <select class="form-select" v-model="form.skuMapConfigUuid"
              :class="{ 'is-invalid' : v$.form.skuMapConfigUuid.$error}">
        <option v-for="item in skuMaps" :key="item" v-bind:value="item.uuid">{{ item.name }}</option>
      </select>
      <div class="invalid-feedback" v-for="error of v$.form.skuMapConfigUuid.$errors" :key="error.$uid">
        {{ error.$message }}
      </div>
    </div>
  </div>

  <div class="row ms-2 mb-4">
    <div class="col-xl-3"></div>
    <div class="col-lg-8">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" id="skuAutoImport"
               v-model="form.skuAutoImport">
        <label class="form-check-label" for="skuAutoImport">Додати до карти нові sku з джерела імпорту</label>
      </div>
    </div>
  </div>


  <div class="row ms-2 mb-4">
    <div class="col-xl-3"></div>
    <div class="col-lg-8">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" id="skuAutoImport"
               v-model="form.availableAutoDisable">
        <label class="form-check-label" for="skuAutoImport">Вимкнути позиції які відсутні в джерелі імпорту</label>
      </div>
    </div>
  </div>


  <div class="row ms-2 mb-4">
    <div class="col-xl-3">Валюта за замовчуванням</div>
    <div class="col-lg-8">
      <select class="form-select form-select-sm" v-model="form.defaultCurrency" :disabled="disableCommonCurrency"
              :class="{ 'is-invalid' : v$.form.defaultCurrency.$error}">
        <option :value="currency" v-for="currency in currencies" :key="currency">{{ currency }}</option>
      </select>
      <div class="form-text">Валюта за замовчуванням буде вибрана в разі невказання її в розмітці файлу</div>
      <div class="invalid-feedback" v-for="error of v$.form.defaultCurrency.$errors" :key="error.$uid">
        {{ error.$message }}
      </div>
    </div>
  </div>

  <div class="row ms-2 mb-4" v-if="form.type !== 'MARKET_INDEX_PRICE'">
    <div class="col-xl-3">Округлення ціни</div>
    <div class="col-lg-8">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" id="priceRound"
               v-model="form.priceRound">
        <label class="form-check-label" for="priceRound">Округлення дробової частини до меншого цілого</label>
      </div>
    </div>
  </div>

  <div class="row ms-2 mb-4">
    <div class="col-xl-3">Роздільник колонок</div>
    <div class="col-lg-8">
      <div class="form-check float-start">
        <input class="form-check-input" type="radio" name="dl" value=";" id="dl2" v-model="form.delimiter">
        <label class="form-check-label" for="dl2"> ; - крапка з комою </label>
      </div>

      <div class="form-check float-start ms-4">
        <input class="form-check-input" type="radio" name="dl" value="," id="dl1" v-model="form.delimiter">
        <label class="form-check-label" for="dl1"> , - кома </label>
      </div>
    </div>
  </div>

  <div class="row ms-2 mb-4">
    <div class="col-xl-3">Пропуск</div>
    <div class="col-lg-8">

      <input type="text" class="form-control" v-model="form.skipLines"
             :class="{ 'is-invalid' : v$.form.skipLines.$error}">
      <div class="form-text">Вказує кількість рядків які варто пропустити. Наприклад заголовок.
      </div>
      <div class="invalid-feedback" v-for="error of v$.form.skipLines.$errors" :key="error.$uid">
        {{ error.$message }}
      </div>

    </div>
  </div>

  <div class="row ms-2 mb-4">
    <div class="col-12">
      <div class="mb-2">Розмікта CSV файлу</div>
      <div class="form-text mb-2">Дана розмітка дозволяє вказати положення знакових колонок в файлі.
        Обов'язкові поля: <span class="badge bg-secondary">sku</span> <span
            class="badge bg-secondary">amount</span>.
        Невказані додаткові поля будуть заповнені даними за замовчуванням.
        <span class="badge bg-secondary">?</span> - ігнорується імпортом і використовується лише для позицюювання
        колонок
      </div>

      <div class=" mb-2 text-danger" v-if="mappingError">{{ mappingError }}</div>


      <div>

        <table class="table table-bordered">
          <thead>
          <tr class="table-light">
            <th v-for="(column, index) in form.columns" :key="column">
              <select class="form-select form-select-sm" v-model="form.columns[index]">
                <option :value="variant.value" v-for="variant in columnVariants" :key="variant">
                  {{ variant.label }}
                </option>
              </select>
            </th>
            <th>

              <a @click="add" class="cursor-pointer btn-add-col">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-plus-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                  <path
                      d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg>
              </a>

            </th>
          </tr>
          </thead>
          <tbody>


          <tr>
            <td v-for="(column, index) in form.columns" :key="column">
              <a @click="remove(index)" class="cursor-pointer btn-remove">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-trash" viewBox="0 0 16 16">
                  <path
                      d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                  <path fill-rule="evenodd"
                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                </svg>
              </a>
            </td>
            <td>
            </td>
          </tr>

          <tr v-for="n in (this.form.skipLines> 7 ? 7: this.form.skipLines*1)" :key="n">
            <td v-for="column in form.columns" :key="column">-</td>
            <td></td>
          </tr>

          <tr v-for="n in 5" :key="n" v-bind:style="'opacity:'+(1/(n*2))">
            <td v-for="column in form.columns" :key="column">
              <span v-if="column==='quantity'">1</span>
              <span v-else-if="column==='currency'">UAH</span>
              <span v-else-if="column==='amount'">100</span>
              <span v-else-if="column==='sku'">p300{{ n }}</span>
              <span v-else>{{ column }}</span>
            </td>
            <td>
            </td>
          </tr>

          </tbody>
        </table>

      </div>
    </div>
  </div>


</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {integer, maxValue, minValue, required} from "@vuelidate/validators";
import Http from "@/lib/Http";

export default {
  name: "EditCsvMeta",
  props: {
    modelValue: Object,
  },
  created() {
    this.fetchMaps();
  },
  watch: {
    form: {
      handler(val) {
        this.$emit('update:modelValue', val)
      },
      deep: true
    }
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        skipLines: {
          integer,
          minValue: minValue(0),
          maxValue: maxValue(30)
        },
        defaultCurrency: {required},
        skuMapConfigUuid: {required},
        availableAutoDisable: {required},
      }
    };
  },
  computed: {
    currencies() {
      return ['USD', 'UAH', 'EUR'];
    },
    disableCommonCurrency() {
      return this.form.columns && this.form.columns.includes('currency');
    },
    columnVariants() {
      return [
        {value: '?', label: '? - інші данні', required: false},
        {value: 'sku', label: 'sku - унікільний ID продукту(Обов\'язкове)', required: true},
        {value: 'amount', label: 'amount - ціна(Обов\'язкове)', required: true},
        {value: 'old_amount', label: 'old_amount - стара ціна'},
        {value: 'action_amount', label: 'action_amount - акційна ціна'},
        {value: 'cashback_amount', label: 'cashback_amount - кешбек'},
        {value: 'currency', label: 'currency - валюта', required: false},
        {value: 'quantity', label: 'quantity - кількість', required: false},
        {value: 'name', label: 'name - Назва в джерелі', required: false},
      ];
    }
  },
  data() {
    return {
      mappingError: null,
      skuMaps: [],
      form: this.modelValue
    }
  },
  methods: {
    fetchMaps: function () {
      Http
          .get(process.env.VUE_APP_IMPORT_SERVICE + "/mapping/config/list")
          .then((res) => {
            this.skuMaps = res.content;


          });
    },
    validate: async function () {

      const result = await this.v$.$validate()
      if (!result) return false;
      if (!this.validateColumns())
        return false;

      return true;
    },

    validateColumns: function () {

      const requiredFields = this.columnVariants.filter(column => column.required).map(column => column.value);
      const mappedFields = this.form.columns.filter(item => requiredFields.includes(item));

      this.mappingError = null;
      if (mappedFields.length < requiredFields.length) {
        this.mappingError = 'Не всі обов\'язкові поля заповнені';
        return false;
      }

      return true;
    },

    add: function () {
      this.form.columns.push('?');
    },

    remove: function (index) {
      this.form.columns.splice(index, 1);
    }
  }
};
</script>

<style scoped>

.btn-remove {
  color: red;
}

</style>