<template>
  <ul class="nav nav-pills">
    <li class="nav-item">
      <router-link class="nav-link" :to="'/wallet/processing'"  v-if="this.$session.can('owner', 'wallet_manage')"
                   v-bind:class="$route.path.startsWith('/wallet/processing')?'active':''">Конфігурації
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Tab",
  props: {
    full: Boolean
  },
}
</script>

<style scoped>

</style>