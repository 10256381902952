<template>
  <head-panel>
    <template v-slot:body>Продукти акції
    </template>
  </head-panel>

  <div class="wrapper">

    <tab/>


    <table class="table">
      <thead>
      <tr>
        <th scope="col">Sku</th>
        <th scope="col">Назва</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in items" :key="item">
        <td>{{ item.sku }}</td>
        <td>{{ item.name }}</td>
        <td class="actions">
          <a :href="'https://oskit.com.ua/'+item.url" target="_black">Перейти</a>
        </td>
      </tr>
      </tbody>
    </table>

    <Pagination
        :current-page="pagination.page"
        :total-pages="pagination.total"/>

  </div>
</template>

<script>
import Http from "../../../lib/Http";
import HeadPanel from "../../../components/HeadPanel.vue";
import Pagination from "../../../components/Pagination.vue";
import Tab from "../Tab.vue";

export default {
  name: "PromoProducts",
  components: {
    HeadPanel, Pagination, Tab
  },
  created() {
    if (this.$route.params.id) {
      this.fetch(this.$route.params.id);
    }
  },
  data() {
    return {
      group: null,
      items: [],
      pagination: {
        page: 0,
        total: 0
      },
    }
  },
  methods: {
    fetch: function (actionUuid) {
      Http
          .get(process.env.VUE_APP_CATALOG_SERVICE + "/v1/promo/action/" + actionUuid + "/products")
          .then((res) => {
            this.items = res.content;
            this.pagination.page = res.origin.headers.get("X-Current-Page") * 1;
            this.pagination.total = res.origin.headers.get("X-Page-Count") * 1;
          });
    },
  }
};
</script>

<style scoped>

</style>