<template>
  <head-panel>
    <template v-slot:body>
      SEO
    </template>
  </head-panel>

  <div class="wrapper edit">

    <ul class="nav nav-pills">
      <li class="nav-item">
        <a class="nav-link" v-bind:class="tab==='meta'?'active':''" @click="tab='meta'">Мета</a>
      </li>
    </ul>

    <hr/>

    <div class="tab" v-if="tab==='meta'">
      <meta-tab />
    </div>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import HeadPanel from "../../components/HeadPanel";
import ConfirmDialogue from "../../components/ConfirmDialog.vue";
import MetaTab from "./_meta.vue";

export default {
  name: "SeoIndex",
  components: {
    HeadPanel, ConfirmDialogue, MetaTab
  },
  data() {
    return {
      tab: 'meta',
      settings: null,
    }
  },
  methods: {
  }
};
</script>
