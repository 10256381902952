import Sidebar from '../components/Sidebar.vue'
import Index from "../views/wallet/processing/Index.vue"
import Edit from "../views/wallet/processing/Edit.vue"

export default [
    {
        path: "/wallet/processing",
        name: "Wallets",
        components: {
            default: Index,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/wallet/processing/create",
        name: "WalletCreate",
        components: {
            default: Edit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/wallet/processing/:id",
        name: "WalletEdit",
        components: {
            default: Edit,
            LeftSidebar: Sidebar
        }
    }
];