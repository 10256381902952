<template>
  <head-panel>
    <template v-slot:body>Бренди
      <router-link to="/brand/create" class="btn btn-primary btn-sm btn-create ms-2">Створити</router-link>
    </template>
  </head-panel>

  <div class="wrapper">

    <div class="filter">
      <div class="item">
        <SearchFilter
            label="Швидкий пошук"
            name="search"
            path="brand"
            v-model="search"
        />
      </div>
    </div>


    <table class="table">
      <thead>
      <tr>
        <th scope="col">ID</th>
        <th scope="col">Назва</th>
        <th scope="col">Топ</th>
        <th scope="col">Зоображення</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <template v-for="brand in brands" :key="brand">
        <tr class="menu">
          <td>{{ brand.name }}</td>
          <td>{{ $filters.locale(brand.locales) }}</td>
          <td>{{ brand.is_top }}</td>
          <td>{{ brand.icon }}</td>
          <td class="actions">
            <router-link :to="'/brand/'+brand.name">Ред.</router-link>
            <a @click="remove(brand)">Видалити</a>
          </td>
        </tr>
      </template>

      </tbody>
    </table>

    <Pagination
        :current-page="pagination.page"
        :total-pages="pagination.total"/>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import SearchFilter from "../../components/SearchFilter";
import ConfirmDialogue from "../../components/ConfirmDialog";
import Pagination from "../../components/Pagination";

export default {
  name: "Menu",
  components: {
    HeadPanel, SearchFilter,  ConfirmDialogue, Pagination
  },
  created() {
    this.fetch();
  },
  watch: {
    $route(n, o) {
      if (n.path === o.path)
        this.fetch();
    },
  },
  data() {
    return {
      search: null,
      brands: [],
      vocabularies: [],
      pagination: {
        page: 0,
        total: 0
      }
    }
  },
  methods: {
    fetch: function () {
      Http
          .get(process.env.VUE_APP_CATALOG_SERVICE + "/manage"+ this.$route.fullPath)
          .then((res) => {
            this.brands = res.content;
            this.pagination.page = res.origin.headers.get("X-Current-Page") * 1;
            this.pagination.total = res.origin.headers.get("X-Page-Count") * 1;
          });
    },
    remove: function (item) {
      this.$refs.confirmDialogue.show({
        title: 'Видалення',
        message: 'Ви дійсно хочете видалити бренд?',
        okButton: 'Так',
        cancelButton: 'Ні',
      }).then((ok) => {
        if (ok)
          Http
              .del(process.env.VUE_APP_CATALOG_SERVICE + "/manage/brand/" + item.name)
              .then(() => this.fetch());
      })
    }
  }
};
</script>

<style scoped>

</style>